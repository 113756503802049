import { FaLock, FaUnlock } from "react-icons/fa";
import { PiInfoDuotone } from "react-icons/pi";
import { Button } from "react-bootstrap";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

/**
 * status: Bool (Lock, Unlock)
 */
const LockButton = ({
  bridgeStatus,
  status,
  onClick,
  loading,
  isRefreshing,
  lockError,
  currentLock,
  lockUncalibrated = false,
}) => {
  const renderTooltip = (props) => (
    <Tooltip className="status-tooltip" {...props}>
      {bridgeStatus === "offline"
        ? "Lock Offline"
        : "Updating may take up to 20 seconds"}
    </Tooltip>
  );
  return !currentLock ? (
    <div
      className="d-flex align-items-center gap-2 rounded-pill bg-delet-secondary border border-1 border-secondary-subtle py-1 px-3 justify-content-end"
      style={{ height: "60px" }}
    >
      <PiInfoDuotone color="gray" size={25} />
      <p className="d-flex flex-column fs-8 lh-sm text-secondary">
        <strong className="fs-6">Lock is not connected.</strong>
        Please, contact support.
      </p>
    </div>
  ) : (
    <OverlayTrigger
      placement="bottom"
      delay={{ hide: 450 }}
      overlay={renderTooltip}
    >
      <div style={{ display: "inline-block" }}>
        <Button
          className={`d-flex align-items-center gap-2 rounded-pill bg-delet-secondary border border-1 border-secondary-subtle p-1 justify-content-end ${
            status ? "" : "flex-row-reverse"
          }`}
          style={{ height: "60px", width: "200px" }}
          onClick={onClick}
          disabled={
            lockUncalibrated ||
            loading ||
            lockError !== "" ||
            bridgeStatus === "offline"
          }
        >
          {isRefreshing ? (
            <span className="text-secondary fw-bold fs-6 w-100 text-center">
              Loading lock...
            </span>
          ) : loading ? (
            <span className="text-secondary fw-bold fs-6 ms-2 p-2">
              {status ? "Locking..." : "Unlocking..."}
            </span>
          ) : (
            <>
              <div className="text-start fw-semibold text-white fs-8 lh-1">
                Click to {status ? "lock" : "unlock"} <br />
                <strong className="fs-6">
                  Door {status ? "unlocked" : "locked"}
                </strong>
              </div>

              <span
                className={`rounded-5 d-flex justify-content-center align-items-center ${
                  bridgeStatus === "offline"
                    ? ""
                    : status
                    ? "bg-green"
                    : "bg-red"
                }`}
                style={{
                  background: bridgeStatus === "offline" ? "#7f7f7f" : "",
                  height: "50px",
                  width: "50px",
                }}
              >
                {status ? (
                  <FaUnlock size={20} color="white" />
                ) : (
                  <FaLock size={20} color="white" />
                )}
              </span>
            </>
          )}
        </Button>
      </div>
    </OverlayTrigger>
  );
};

export default LockButton;
