import './Leads.scss';

import React, {
  useEffect,
  useState,
} from 'react';
import useViewport from '../../hooks/useViewport';

import axios from 'axios';
import copy from 'copy-to-clipboard';
import {
  Button,
  Card,
  Col,
  Form,
  Modal,
  Row,
} from 'react-bootstrap';
import {
  Link,
  useNavigate,
  useParams,
} from 'react-router-dom';
import { Auth } from 'aws-amplify';

import Sidebar from '../../components/SideBar';
import getImageUrl from '../../helper/checkImageFormat';
import { Center, Loader } from '@mantine/core';
import InfoBanner from '../../components/Banners/InfoBanner';
import { toZonedTime } from 'date-fns-tz';
import { format } from 'date-fns';
import moment from 'moment';
import OutcomeSelect from '../../components/Outcome';
import { outcomeStatus } from '../../static/Data';
import { GoDotFill } from "react-icons/go";

const defaultTimezone = 'America/Los_Angeles';

const LeadsDetails = () => {
  const {width: screenWidth} = useViewport()
  // alert modal
  const [showAlert, setShowAlert] = useState(false);
  const [alertTitle, setAlertTitle] = useState('Alert');
  const [alertContent, setAlertContent] = useState('');

  const [timezone, setTimezone] = useState(0);
  const [scheduledDate, setScheduleDate] = useState()
  const [outcome, setOutcome] = useState('interested')

  const [loading, setLoader] = useState(false);

  const [list, setList] = useState([]);
  const [leadImage, setLeadImage] = useState('');

  const navigate = useNavigate();
  const params = useParams();



  useEffect(() => {
    async function getTimezone() {
      try {
        const token = await Auth.currentSession()
        const response = await axios
          .get(`${process.env.REACT_APP_NODE_API_URL}/api/users/timezone`, { headers: { Authorization: `Bearer ${token.getIdToken().getJwtToken()}` } })
        if (response.status === 200) {
          setTimezone(response.data.timezone);
        } else {
          setShowAlert(true);
          setAlertTitle('Error');
          setAlertContent('Error getting timezone');
        }
      } catch (error) {
        console.log(error);
        setShowAlert(true);
        setAlertTitle('Error');
        setAlertContent('Error getting timezone');
      }
    }
    getTimezone();
  }, [])

  useEffect(() => {
    if (timezone) {
      getPropertyList()
    }
  }, [timezone])

  const copyToClipboard = () => {
    let userId = list ? list.user: ''
    let link = `${process.env.REACT_APP_URL}/book/list/${userId}`
    copy(link)
    setShowAlert(true);
    setAlertTitle('Copied');
    setAlertContent('Calendar link copied. You can send this link to leads, so that they can create appointments.');
  }

  const getPropertyList = () => {
    Auth.currentSession()
      .then((token) => {
        axios
          .get(
            `${process.env.REACT_APP_NODE_API_URL}/api/contact/${params.id}`,
            { headers: { Authorization: `Bearer ${token.getIdToken().getJwtToken()}` } })
          .then((response) => {
            if (response.status === 200) {
              if (response.data.contact.document) {
                setLeadImage(response.data.contact.document);
              }
              setList(response.data);
              setScheduleDate(() => toZonedTime(new Date(response.data.bookings[0].startTime * 1000), defaultTimezone))
              setOutcome(response.data.contact.status)
              setLoader(true);
            } else {
              setShowAlert(true);
              setAlertTitle('Alert');
              setAlertContent('Error getting Lead');
            }
          })
          .catch((error) => {
            setShowAlert(true);
            setAlertTitle('Alert');
            setAlertContent('Error getting Lead');
          })
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          Auth.signOut().then(() => {
            navigate('/')
          }).catch((error) => {
            console.log('Error signing out: ', error);
          })
        } else {
          setShowAlert(true);
          setAlertTitle('Alert');
          setAlertContent('Error getting Lead');
        }
      })
  }

  const updateOutcomeStatus = async (newStatus) => {
    try {
      const token = await Auth.currentSession();
      await axios.patch(`${process.env.REACT_APP_NODE_API_URL}/api/contact/${params.id}/status`, { status: newStatus }, {
        headers: { Authorization: `Bearer ${token.getIdToken().getJwtToken()}` },
      });
      setOutcome(newStatus); 
      
    } catch (error) {
      console.error("Error updating outcome status:", error);
     
    }
  };

  return (
    
    <Sidebar>
      {screenWidth < 755 ? (
        <>
        </>
      ) : (
        <div className="page-main-head align-items-center p-4 property-head">
        <Row className="align-items-center">
          <Col >
            <div className="d-flex align-items-center flex-wrap justify-content-center mt-3 justify-content-lg-start">
              <span className="position-relative d-inline-block">
                {/* <img
                  src={getImageUrl(leadImage)}
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null; // prevents looping
                    currentTarget.src = require('../../assests/images/aljnfn.png');
                  }}
                  alt="ID document"
                  width={160}
                  height={90}

                  className="border-radius-0"

                /> */}
                <h2>{'Leads >'}</h2>
              </span>
              <div className="ml-20">
                <h3>
                  {list?.contact?.firstName} {list?.contact?.lastName}
                </h3>
                {/* <div className="d-flex">
                  <Link
                    to={`/edit-leads/${params.id}`}
                    size="sm"
                    className="btn btn-primary btn-sm edit-property-btn mr-30"
                  >
                    Edit lead details
                  </Link>
                </div> */}
              </div>
            </div>
          </Col>
          {/* <Col xl={6} lg={12} className="mt-2 mb-2 m-xl-0 d-flex justify-content-center justify-content-lg-end">
            <Button
              size="sm"
              className="light-btn btn btn-wicon btn-sm ml-auto mr-10"
              onClick={copyToClipboard}
            >
              Share calendar with {list.firstName}
            </Button>
          </Col> */}
        </Row>
      </div>
      )}
      
      {loading === false ? (
        <Center h={100}>
          <Loader/>
        </Center>
      ) : (
        <>
          <Row className="mt-30 mx-2">
            <Col xl={3} lg={12}>
              <Card className='card-shadow'>
                <Card.Header className='form-header'>ID DOCUMENT</Card.Header>
                <Card.Body>
                  <div className="id-img-wrap">
                    <img
                      src={getImageUrl(leadImage)}
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null; // prevents looping
                        currentTarget.src = require('../../assests/images/aljnfn.png');
                      }}
                      alt="ID document"
                      width="100%"
                    />
                  </div>
                  {/* <p>
                    <em>
                      *Always check and confirm that the ID provided is correct and
                      matches the other given details
                    </em>
                  </p> */}
                </Card.Body>
              </Card>
            </Col>
            

            <Col xl={9} lg={12}>
              <Card className='card-shadow'>
                <>
                  <header className='form-header'>
                    <p className="ms-4 pt-0">
                        PERSONAL INFORMATION
                    </p>
                  </header>
                  
                    <table className='mx-3'>
                        <tbody>
                            <tr className='rows'>
                                <td className='left-td'> First Name</td>
                                <td className='right-td'>{list?.contact?.firstName}</td>
                            </tr>
                            <tr className='rows'>
                                <td className='left-td'>Last Name</td>
                                <td className='right-td'>{list?.contact?.lastName}</td>
                            </tr>
                            <tr className='rows'>
                                <td className='left-td'>Mobile</td>
                                <td className='right-td'>{list?.contact?.phone}</td>
                            </tr>
                            <tr className='bottom-row'>
                                <td className='left-td'>Email</td>
                                <td className='right-td'>{list?.contact?.email}</td>
                            </tr>
                        </tbody>
                    </table>     
                </>
              </Card>
            </Col>
          </Row>
          <div className='mx-3'>
            <InfoBanner>
              <span className={screenWidth < 755 ? 'banner-text-mobile' :  'banner-text'}>
                Always check and confirm that the ID provided is correct and matches the other given details
              </span>
            </InfoBanner>
          </div>
          <div>
            <header className='showings-header'>
              <p className="ms-4 pt-0">
                  {`${list?.contact?.firstName} ${list?.contact?.lastName}'s Showings`}
              </p>
            </header>
            {screenWidth < 755 ? (
              <Card className='card-shadow mx-3 p-4'>
                <div className='d-flex flex-column justify-content-start align-items-start gap-3'>
                  <div className='d-flex flex-column justify-content-center align-items-start mb-2 '>
                    <span className='titles'>TOUR DATE</span>
                    <div>
                        {scheduledDate && (
                          <div className='content'>{format(scheduledDate, 'MMMM dd, yyyy. hh:mm a')}</div>
                        )}
                      </div>
                  </div>
                </div>
                <div className='d-flex flex-column justify-content-center align-items-start mt-2'>
                <span className='titles'>ONE TIME ACCESS CODE</span>
                  <span className='content-access'>
                  {list?.bookings[0]?.passCode ? <div>{list?.bookings[0]?.passCode } <GoDotFill color='#00BA08'/> </div> : 'No Code'}
                    </span>
                  <div className='content'>{list?.bookings[0]?.passCode ? 'Front Door Code' : '-'}</div>
                </div>
                <span className='titles'>OUTCOME</span>
                <div className='d-flex flex-column justify-content-center align-items-start mt-2'>
                  <OutcomeSelect
                    status={outcome}
                    values={[]}
                    onChange={(selected) => updateOutcomeStatus(selected[0].status)}
                    placeholder={outcome}
                    options={outcomeStatus}
                    size="sm"
                    // className="status-td"
                  />
                </div>
            </Card>
            ) : (
              <Card className='card-shadow mx-3 p-4'>
              <table>
                <thead className='table-head'>
                  <th>Tour Date</th>
                  <th>Property</th>
                  <th>One Time Access Code</th>
                  <th>Outcome</th>
                </thead>
                <tbody >
                  <tr className='table-b'>
                    <td>{scheduledDate ? format(scheduledDate, 'MM/dd/yyyy') : ''}</td>
                    <td>
                      <div>
                        <p className='prop-address'>{list?.bookings[0]?.property?.address}</p>
                        {scheduledDate && (
                          <div className='prop-date'>{format(scheduledDate, 'MMMM dd, yyyy. hh:mm a')}</div>
                        )}
                      </div>
                    </td>
                    <td>
                      <div>
                        {list?.bookings[0]?.passCode ? <div>{list?.bookings[0]?.passCode } <GoDotFill color='#00BA08'/> </div> : 'No Code'}
                        <div className='prop-date'>{list?.bookings[0]?.passCode ? 'Front Door Code' : ''}</div>
                      </div>
                    </td>
                    <td>
                      <OutcomeSelect
                          status={outcome}
                          values={[]}
                          onChange={(selected) => updateOutcomeStatus(selected[0].status)}
                          placeholder={outcome}
                          options={outcomeStatus}
                          size="sm"
                        // className="status-td"
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </Card>
            )}
          </div>
        </>
      )}

      {showAlert ?
        <Modal show={showAlert}>
          <Modal.Header>
            <Modal.Title>{alertTitle}</Modal.Title>
            <button className="btn-close" aria-label="Close" onClick={() => setShowAlert(false)}></button>
          </Modal.Header>
          <Modal.Body>{alertContent}</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowAlert(false)}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        : ''
      }
    </Sidebar>
  )
}

export default LeadsDetails


