import { Card } from "../../../../../designSystem/Card";
import style from "./style.module.scss";
import { useRef, useState } from "react";
import { AppButton } from "../../../../../designSystem/AppButton";
import { Modal } from "react-bootstrap";
import DragAndDropImg from "../../DragAndDropImg";
import IdVerification from "../../IdVerification";
import { MdEmail, MdLocalPhone } from "react-icons/md";
import { IoMdClose, IoMdPerson  } from "react-icons/io";
import { RiErrorWarningFill } from "react-icons/ri";
import PropTypes from 'prop-types';

function Step3({
  currentStep,
  onChange,
  className,
  idVerification,
  document,
  documentBack,
  face,
  verified,
  errors,
  setErrors
}) {
  const [showModal, setShowModal] = useState(false);

  const modalState = {
    show: () => setShowModal(true),
    hide: () => setShowModal(false),
  };
  const handleModalCancel = () => {
    if (checkboxRef.current?.checked) {
      checkboxRef.current?.click();
    }
    modalState.hide();
  };
  const handleModalAccept = () => {
    if (!checkboxRef.current?.checked) {
      checkboxRef.current?.click();
    }
    modalState.hide();
  };
  const checkboxRef = useRef(null);

  const handleCheckboxChange = (e) => {
    e.target.value = e.target.checked;
    onChange(e);
  };

  return (
    <div
      className={`${currentStep !== 3 ? "d-none" : ""} ${className} ${
        style.container
      }`}
    >
      <Card className={style.card}>
      
          <div className={style.inputContainer}>
            
          <p className={!errors.fullName ? style.icon : style.iconError}><IoMdPerson/></p>
          <input
            className={!errors.fullName ? style.input : style.inputError}
            onChange={onChange}
            name="fullName"
            placeholder="Full Name"
            type="text"
          />
          {errors.fullName ? <div className={style.error}> <RiErrorWarningFill size={12} color='red' style={{marginRight: '5px'}}/>{errors.fullName}</div> : ''}
          </div>
    
        {/* <input
          className={style.input}
          onChange={onChange}
          name="lastName"
          placeholder="Last Name"
          type="text"
        /> */}
        <div className={style.inputContainer}>

          <p className={!errors.phone ? style.icon : style.iconError}><MdLocalPhone/></p>
          <input
            className={!errors.phone ? style.input : style.inputError}
            name="phone"
            onChange={onChange}
            placeholder="Phone Number"
            type="number"
          />
          {errors.phone ? <div className={style.error}><RiErrorWarningFill size={12} color='red' style={{marginRight: '5px'}}/>{errors.phone}</div> : ''}
        </div>
        <div className={style.inputContainer}>

          <p className={!errors.email ? style.icon : style.iconError}><MdEmail/></p>
          <input
            className={!errors.email ? style.input : style.inputError}
            name="email"
            onChange={onChange}
            placeholder="Email"
            type="email"
          />
          {errors.email ? <div className={style.error}><RiErrorWarningFill size={12} color='red' style={{marginRight: '5px'}}/>{errors.email}</div> : ''}
        </div>
        {idVerification?.active ? (
          <>

            <div className="row gy-2">
              <IdVerification
                document={document}
                documentBack={documentBack}
                requireFace={idVerification.requireFace}
                face={face}
                onChange={onChange}
                verified={verified}
                profile={"security_" + idVerification.profile}
              />
            
            </div>
           
          </>
        ) : (
          <>
            <DragAndDropImg
              onChange={onChange}
              img={document}
              name={"document"}
            />
          </>
        )}
        <div>{errors.document ? <div><p className={style.error}><RiErrorWarningFill size={12} color='red' style={{marginRight: '5px'}}/>{errors.document}</p></div> : ''}</div>
      </Card>
      <label className={style.checkboxGroup}>
        <input
          ref={checkboxRef}
          onChange={handleCheckboxChange}
          name="privacyPolicy"
          type="checkbox"
          value={true}
        />
        
        <p className={style.agreement}> 
          I confirm that I have read and agreed to the{" "}
          <AppButton
            onClick={modalState.show}
            style={{ padding: "0", fontSize: '10px', textDecoration: 'underline' }}
            as="button"
            hierarchy="link"
          >
            <u>Terms & Conditions</u>
          </AppButton>
          , and hereby give my consent to be filmed on camera in accordance with
          these conditions.
        </p>
      </label>
      {errors.privacyPolicy ? <div><p className={style.errorTerms}><RiErrorWarningFill size={12} color='red' style={{marginRight: '5px'}}/>{errors.privacyPolicy}</p></div> : ''}
      <Modal
        className={style.modal}
        centered
        show={showModal}
        onHide={modalState.hide}
      >
        <div className={style.closeContainer}>
          <button className={style.closeButton} onClick={modalState.hide}>
            <IoMdClose size={20}/>
          </button>
        </div>
        <Modal.Header>
          <h1>Terms & Conditions</h1>
        </Modal.Header>
        <Modal.Body>
          <ol>
            <li>
              <h2>Video Recording Consent</h2>
              <p>
                By selecting this box and granting my permission, I acknowledge
                and consent to Delet using video recordings capturing my
                appearance as part of their product. Through this agreement, I
                willingly agree to participate in these recordings, which are
                primarily intended for informational purposes regarding Delet's
                product offerings.
              </p>
            </li>
            <li>
              <h2>Responsibility Acknowledgment</h2>
              <p>
                Moreover, I understand and take full responsibility for any
                damage or breakages that may potentially occur during my visit
                to the property. I recognize that it is incumbent upon me to
                exercise due caution and diligence while navigating the
                premises.
              </p>
            </li>
            <li>
              <h2>Data Usage Consent</h2>
              <p>
                In addition to the above, I provide consent for the video
                footage not only to be utilized for informational purposes but
                also for the purposes of data collection and analysis pertaining
                to Delet's products and services. I am aware that these
                recordings may be used to enhance user experiences, analyze
                customer behaviors, and elevate the overall service quality
                provided by Delet.
              </p>
            </li>
          </ol>
        </Modal.Body>
        <Modal.Footer className={style.buttons}>
          <AppButton
            as="button"
            onClick={handleModalCancel}
            hierarchy="secondary"
          >
            Cancel
          </AppButton>
          <AppButton
            as="button"
            onClick={handleModalAccept}
            hierarchy="primary"
          >
            Accept
          </AppButton>
        </Modal.Footer>
      </Modal>
                  
      
    </div>
  );
}

Step3.propTypes = {
  document: PropTypes.object,
  documentBack: PropTypes.object,
  face: PropTypes.object,
  className: PropTypes.string,
  currentStep: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  idVerification: PropTypes.bool.isRequired,
  verified: PropTypes.bool,
  errors: PropTypes.shape({
    fullName: PropTypes.string,
    phone: PropTypes.string,
    email: PropTypes.string,
    id: PropTypes.string,
    privacy: PropTypes.string,
  }).isRequired,
  setErrors: PropTypes.func.isRequired,
};

export default Step3;
