import React from "react";
import { AppDropzone } from "../../../designSystem/Dropzone";
import picture from "../../../assests/images/picture.svg";
import { ReactSVG } from "react-svg";
import { AppButton } from "../../../designSystem/AppButton";
import { Card } from "../../../designSystem/Card";
import { MdOutlineFileUpload } from "react-icons/md";
import Compressor from "compressorjs";

const DragAndDropImg = ({ onChange, img, name, placeholder }) => {
  const handleDeleteFile = (e) => {
    e.stopPropagation();
    onChange({ target: { name, value: null } });
  };

  const onDrop = (files) => {
    new Compressor(files[0], {
      quality: 0.6,
      success(result) {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(result);
        fileReader.onload = () => {
          onChange({ target: { name, value: fileReader.result } });
        };
      },
      error(err) {
        console.log(err.message);
      },
    });
  };

  return (
    <AppDropzone onDrop={onDrop}>
      {img ? (
        <>
          <Card.Image style={{ maxWidth: "80%", width: '70%', height: '95%', padding: '5px', borderRadius: '20px'}} src={img} />
          <AppButton style={{padding: '0px'}} hierarchy="link" onClick={handleDeleteFile}>
            Remove
          </AppButton>
        </>
      ) : (
        <>
          {/* <ReactSVG src={picture} /> */}
          <AppButton style={{borderRadius: '50%', width: '40px', height: '40px'}} as="button"><MdOutlineFileUpload size={20}/></AppButton>
          {placeholder ? 
          <p className="text-center">{placeholder} </p> :

          <p style={{fontFamily: 'Poppins', color: '#5271ff', fontWeight: '800'}}>ID Verification</p>
          
        }
        </>
      )}
    </AppDropzone>
  );
};

export default DragAndDropImg;
