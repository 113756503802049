import { FaLock, FaUnlock } from "react-icons/fa";
import { PiInfoDuotone } from "react-icons/pi";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Button } from "react-bootstrap";

/**
 * status: Bool (Lock, Unlock)
 */
const LockButtonMobile = ({
  status,
  bridgeStatus,
  onClick,
  loading,
  isRefreshing,
  lockError,
  currentLock,
  lockUncalibrated = false,
}) => {
  const renderTooltip = (props) => (
    <Tooltip className="status-tooltip" {...props}>
      {bridgeStatus === "offline"
        ? "Lock Offline"
        : "Updating may take up to 20 seconds"}
    </Tooltip>
  );
  return !currentLock ? (
    <div
      className="d-flex align-items-center gap-2 rounded-pill bg-delet-secondary border border-1 border-secondary-subtle py-1 px-3 justify-content-end"
      style={{ height: "60px" }}
    >
      <PiInfoDuotone color="gray" size={25} />
      <p className="d-flex flex-column fs-8 lh-sm text-secondary">
        <strong className="fs-8">Lock is not connected.</strong>
        Please, contact support.
      </p>
    </div>
  ) : (
    <OverlayTrigger
      placement="bottom"
      delay={{ hide: 400 }}
      overlay={renderTooltip}
    >
      <div style={{ display: "inline-block" }}>
        <Button
          className={`d-flex align-items-center border-none px-0   justify-content-end ${
            status ? "" : "flex-row-reverse"
          } ${isRefreshing && loading ? "rotate" : ""}`}
          style={{ backgroundColor: "rgb(234, 234, 235)", border: "none" }}
          onClick={onClick}
          disabled={
            lockUncalibrated ||
            loading ||
            lockError !== "" ||
            bridgeStatus === "offline"
          }
        >
          {
            <>
              <span
                className={`rounded-5 d-flex justify-content-center align-items-center ${
                  bridgeStatus === "offline"
                    ? ""
                    : status
                    ? "bg-green"
                    : "bg-red"
                }`}
                style={{
                  background: bridgeStatus === "offline" ? "#BBBBBB" : "",
                  height: "41px",
                  width: "41px",
                }}
              >
                {status ? (
                  <FaUnlock size={15} color="white" />
                ) : (
                  <FaLock size={15} color="white" />
                )}
              </span>
            </>
          }
        </Button>
      </div>
    </OverlayTrigger>
  );
};

export default LockButtonMobile;
