import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Upload } from "react-feather";
import { AppDropzone } from "../designSystem/Dropzone";

const StyledInputImages = styled.div({
  border: "2px #919295",
  borderStyle: "dashed",
  borderRadius: "6px",
  padding: "10px",
  textAlign: "center",
});

const StyledPreview = styled.div({
  margin: "10px",
  padding: "5px",
  display: "inline-block",
  maxWidth: "140px",
  position: "relative",
});

const StyledImage = styled.img({
  maxWidth: "100%",
  height: "auto",
});

const StyledUploadButton = styled.label({
  display: "flex",
  height: "48px",
  width: "240px",
  padding: "8px 16px",
  justifyContent: "center",
  alignItems: "center",
  fontFamily: "Mulish",
  fontSize: "16px",
  fontStyle: "normal",
  fontWeight: "600",
  letterSpacing: "0.16px",
  textDecoration: "none",
  backgroundColor: "#5D5FEF",
  borderRadius: "5px",
  color: "#FFFFFF",
  cursor: "pointer",
});

const StyledButtonDelete = styled.button({
  position: "absolute",
  top: "-10px",
  right: "-10px",
  width: "20px",
  height: "20px",
  border: "1px solid #ccc",
  borderRadius: "50%",
  backgroundColor: "#fff",
  color: "#ccc",
  fontSize: "14px",
  lineHeight: "1",
  textAlign: "center",
  cursor: "pointer",
});

const StyledCentredSelector = styled.div({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  gap: "2px",
  padding: "75px 0px 75px 0px",
});


const InputImages = ({ description, files, onFilesChange }) => {
  const [images, setImages] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [inputKey, setInputKey] = useState(0);
  const [isDragOver, setIsDragOver] = useState(false);
  const [draggedIndex, setDraggedIndex] = useState(null);

  const handleImageUpload = (event) => {
    const newFiles = Array.from(event.target.files);
    const maxSize = 258 * 1024 * 1024; // 258MB in bytes
    for (let file of newFiles) {
      if (!file.type.includes('image/')) {
        setErrorMessage("Only images are allowed.");
        return;
      }

      if (file.size > maxSize) {
        setErrorMessage("File size exceeds the 258MB limit.");
        return;
      }
    }
    setErrorMessage("");
    const newImages = newFiles.map((file) => URL.createObjectURL(file));
    setImages((prevImages) => [...prevImages, ...newImages]);
    onFilesChange((prevFiles) => [...prevFiles, ...newFiles]);
    setInputKey((prevKey) => prevKey + 1);
  };

  const handleDropUpload = (event) => {
    const newFiles = Array.from(event);
    const maxSize = 258 * 1024 * 1024; // 258MB in bytes
    for (let file of newFiles) {
      if (!file.type.includes('image/')) {
        setErrorMessage("Only images are allowed.");
        return;
      }

      if (file.size > maxSize) {
        setErrorMessage("File size exceeds the 258MB limit.");
        return;
      }
    }

    setErrorMessage("");
    const newImages = newFiles.map((file) => URL.createObjectURL(file));
    setImages((prevImages) => [...prevImages, ...newImages]);
    if (onFilesChange) {
      onFilesChange([...files, ...newFiles]);
    }

    setInputKey((prevKey) => prevKey + 1)
  }
  const handleDeleteImage = (event, index) => {
    event.preventDefault();
    event.stopPropagation();

    const newImages = images.filter((_, i) => i !== index);
    setImages(newImages);
    if (onFilesChange) {
      const newFiles = files.filter((_, i) => i !== index);
      onFilesChange(newFiles);
    }
  };

  useEffect(() => {
    if (images.length === 0) {
      const initialImages = files.map(file =>
        typeof file === "string" ? file : URL.createObjectURL(file)
      );
      setImages(initialImages);
    }
  }, [files, images.length]); 


  const handleDrop = (event) => {
    event.preventDefault();
    event.preventDefault();
    setIsDragOver(false);
  
    const droppedFiles = [...event.dataTransfer.files];
  
  const newFiles = droppedFiles.filter(file => {
    return !files.some(existingFile => existingFile.name === file.name);
  });

    if (newFiles.length > 0) {
      handleDropUpload(newFiles);
    }
  };

  const handleDragOver = (event) => {
    event.preventDefault();
    setIsDragOver(true);
  };

  const handleDragLeave = () => {
    setIsDragOver(false);
  };

  const handleDragStart = (index) => {
    setDraggedIndex(index);
  };

  const handleDragEnd = () => {
    setDraggedIndex(null);
    
  };

  const handleDropImage = (index) => {
    if (draggedIndex === null) return;
    const reorderedImages = [...images];
    const [movedImage] = reorderedImages.splice(draggedIndex, 1);
    reorderedImages.splice(index, 0, movedImage);
    setImages(reorderedImages);
    if (onFilesChange) {
      const newFiles = files.slice(); 
      const [movedFile] = newFiles.splice(draggedIndex, 1);
      newFiles.splice(index, 0, movedFile);
      onFilesChange(newFiles);
    }
    setDraggedIndex(null);
  };

  return (
    <>
      <label htmlFor="InputImages">{description}</label>
    
      <StyledInputImages>
        <div
          onDrop={handleDrop}
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
        >
        <StyledCentredSelector >
          <span className="body-s">Drag and drop</span>
          <span className="body-s-bold">or</span>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <StyledUploadButton htmlFor="uploadImageButton">
              <Upload style={{ marginRight: "8px" }} />
              Upload File
            </StyledUploadButton>
            <input
              key={inputKey}
              id="uploadImageButton"
              style={{ visibility: "hidden" }}
              type="file"
              accept="image/*"
              multiple
              onChange={(e) => handleImageUpload(e)}
            />
          </div>
          <span className="body-xs" style={{ color: "#919295" }}>
            Only PNGs or JPGs Max. 258MB
          </span>
        </StyledCentredSelector>
        <div >
          {images.map((image, index) => (
            <StyledPreview 
              key={index}
              onDrop={() => handleDropImage(index)}
              onDragOver={(e) => e.preventDefault()}
            >
              <StyledButtonDelete
                onClick={(event) => handleDeleteImage(event, index)}
              >
                X
              </StyledButtonDelete>
              <StyledImage 
                draggable  
                onDragStart={() => handleDragStart(index)}
                onDragEnd={handleDragEnd}
                src={image} 
                alt={`Image ${index + 1}`} 
              />
            </StyledPreview>
          ))}
          </div>
        </div>
      </StyledInputImages>
      {errorMessage && <p className="error">{errorMessage}</p>}
    </>
  );
};

export default InputImages;
