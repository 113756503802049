import "./Leads.scss";

import React, { useEffect, useMemo, useState } from "react";
import axios from "axios";
import moment from "moment";
import { Button, Col, Form, Modal, Row, Table } from "react-bootstrap";
import Select from "react-dropdown-select";
import { Link } from "react-router-dom";
import { Auth } from "aws-amplify";
import Sidebar from "../../components/SideBar";
import PagePagination from "../../components/PagePagination";
import getImageUrl from "../../helper/checkImageFormat";
import removeUndefinedWord from "../../helper/updateString";
import _ from "lodash";
import ButtonDropdown from "../../components/Buttons/ButtonDropdown";
import ExportModal from "../../components/Modals/ExportModal";
import { DateRangeSelect } from "../../components/Selects/DateRangeSelect";
import { MultiselectSearch } from "../../components/Selects/MultiselectSearch";
import { useGeneral } from "../../context/GeneralContext";
import { FiltersModal } from "./components/FiltersModal";
import { CiExport, CiFilter } from "react-icons/ci";
import { Center, Loader } from "@mantine/core";
import { useQuery } from "@tanstack/react-query";
import { getContacts } from "../../api/contacts";
import UnstyledButton from "../../components/UnstyledButton";
import { BsSliders2 } from "react-icons/bs";
import { IoMdPerson } from "react-icons/io";
import { MdLocalPhone, MdEmail } from "react-icons/md";
import useViewport from "../../hooks/useViewport";
import { Share } from "react-feather";

const Leads = () => {
  const [showAlert, setShowAlert] = useState(false);
  const { setAlertModal } = useGeneral();

  const [pageLimit, setPageLimit] = useState(7);
  const [search, setSearch] = useState(true);
  const [searchParam, setSearchParam] = useState("");
  const [page, setPage] = useState(1);
  const [listOrder, setListOrder] = useState("desc");
  const [filters, setFilters] = useState({
    startDate: null,
    endDate: null,
    selectedProperties: null,
    search: "",
  });
  const memoizedFilters = useMemo(() => filters, [filters]);

  const {
    data = { data: [] },
    isLoading,
    isError,
  } = useQuery({
    queryKey: [
      "my-leads",
      page,
      pageLimit,
      memoizedFilters.startDate,
      memoizedFilters.endDate,
      memoizedFilters.selectedProperties,
      memoizedFilters.search,
    ],
    queryFn: () =>
      getContacts({
        page,
        limit: pageLimit,
        order: listOrder,
        ...memoizedFilters,
      }),
  });

  const options = [
    {
      value: 1,
      label: "Oldest",
    },
    {
      value: 2,
      label: "Most Recent",
    },
  ];

  const numberOfEntriesOptions = [
    {
      value: 7,
      label: "Number of Leads Per Page: 7",
    },
    {
      value: 20,
      label: "Number of Leads Per Page: 20",
    },
    {
      value: 50,
      label: "Number of Leads Per Page: 50",
    },
    {
      value: 100,
      label: "Number of Leads Per Page: 100",
    },
    {
      value: 200,
      label: "Number of Leads Per Page: 200",
    },
    {
      value: 500,
      label: "Number of Leads Per Page: 500",
    },
  ];

  // FILTERS
  const [showFiltersModal, setShowFiltersModal] = useState(false);

  // const handleShowFiltersModal = () => setShowFiltersModal(true);

  const handleCloseFiltersModal = () => setShowFiltersModal(false);

  const handleApplyFilters = ({ selectedProperties, startDate, endDate }) => {
    const newFilters = {};
    if (startDate)
      newFilters.startDate = moment(startDate).format("YYYY-MM-DD");
    if (endDate) newFilters.endDate = moment(endDate).format("YYYY-MM-DD");
    if (selectedProperties)
      newFilters.selectedProperties = selectedProperties
        ?.map((property) => property._id)
        .join();

    setFilters(newFilters);
    setShowFiltersModal(false);
    setPage(1);
  };

  const handleResetFilters = () => {
    setFilters({
      startDate: null,
      endDate: null,
      selectedProperties: null,
    });
  };

  //Export
  const [show, setShow] = useState(false);
  const [exportStartDate, setExportStartDate] = useState("");
  const [exportEndDate, setExportEndDate] = useState("");
  const [exportProperties, setExportProperties] = useState([]);
  const { width: screenWidth } = useViewport();
  const [isGeneratingCsv, setIsGeneratingCsv] = useState(false);
  const [propertiesData, setPropertiesData] = useState([]);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleExportDateRangeChange = (value) => {
    setExportStartDate(value[0]);
    setExportEndDate(value[1]);
  };

  const handleExport = (e) => {
    e.preventDefault();
    setIsGeneratingCsv(true);
    Auth.currentSession().then((token) => {
      let url = `${process.env.REACT_APP_NODE_API_URL}/api/contact/toCsv?`;

      if (exportProperties.length) {
        url = `${url}&propertyIds=${exportProperties
          .map((property) => property._id)
          .join()}`;
      }

      if (exportStartDate) {
        url = `${url}&startDate=${moment(exportStartDate).format(
          "YYYY-MM-DD"
        )}`;
      }
      if (exportEndDate) {
        url = `${url}&endDate=${moment(exportEndDate).format("YYYY-MM-DD")}`;
      }

      axios
        .get(url, {
          headers: {
            Authorization: `Bearer ${token.getIdToken().getJwtToken()}`,
          },
        })
        .then((response) => {
          if (response.status === 200) {
            var blob = new Blob([response.data], {
              type: "text/csv",
            });

            const a = document.createElement("a");
            a.download = "leads";
            a.href = window.URL.createObjectURL(blob);
            const clickEvt = new MouseEvent("click", {
              view: window,
              bubbles: true,
              cancelable: true,
            });
            a.dispatchEvent(clickEvt);
            a.remove();
          }

          setExportStartDate("");
          setExportEndDate("");
          setExportProperties([]);
          handleClose();
        })
        .catch((error) => {
          console.log(error);
          handleClose();

          setAlertModal({
            show: true,
            title: "Error",
            content: "There's no data to export",
          });
        })
        .finally(() => setIsGeneratingCsv(false));
    });
  };

  const handleSearchTerm = (e) => {
    if (e.target.value === "") setFilters({ ...filters, search: "" });
    setSearchParam(e.target.value);
  };

  const handleKeyDown = (e) => {
    const { key } = e;
    if (key === "Enter") {
      e.preventDefault();
      setFilters({ ...filters, search: searchParam });
      setPage(1);
    }
  };

  const handleReset = () => {
    setPage(1);
    setSearchParam("");
    setFilters({
      ...filters,
      search: "",
    });
  };

  const debouncedSearchOnChange = _.debounce((e) => {
    handleSearchOnchange(e);
  }, 1000);

  const handleSearch = () => {
    setPage(1);
    setFilters({ ...filters, search: searchParam });
    setSearch(!search);
  };

  const handleSearchOnchange = (e) => {
    setPage(1);
    setSearch(!search);
  };

  const handleNextPage = (nextPage) => {
    if (data.pages >= nextPage && nextPage > 0) {
      setPage(nextPage);
    }
  };

  const handleorder = (e) => {
    setListOrder(e[0].label);
    if (e[0].label === "Oldest") {
      setListOrder("asc");
    } else if (e[0].label === "Most Recent") {
      setListOrder("desc");
    }
  };

  const handlePageLimit = (e) => {
    setPageLimit(e[0].value);
  };

  return (
    <Sidebar>
      {
        // MARK: Header Mobile
      }
      {screenWidth > 755 ? (
        <div className="page-main-head align-items-center">
          <Row className="align-items-center">
            <Col xl={12} className="mb-2">
              <div className="d-flex align-items-center flex-wrap flex-md-nowrap">
                <h2 className="color-black mt-4">Leads</h2>
              </div>
            </Col>
          </Row>
        </div>
      ) : (
        <div className="page-main-head align-items-center">
          <Row className="align-items-center">
            <Col className="mb-2">
              <div className="d-flex align-items-center flex-wrap flex-md-nowrap">
                <h2 className="color-black">Leads</h2>
              </div>
            </Col>
          </Row>
          <Row className="align-items-center">
            <Col>
              <div className="d-flex justify-content-between">
                <Form
                  style={{
                    gap: "10px",
                    flexWrap: "wrap",
                    justifyContent: "space-between",
                  }}
                >
                  <Form.Group
                    className="lead_search-control-group"
                    controlId="search-control"
                  >
                    <Form.Control
                      type="text"
                      placeholder="Search"
                      value={searchParam}
                      onChange={handleSearchTerm}
                      onKeyDown={handleKeyDown}
                    />
                    <Button
                      className={
                        searchParam !== "" ? "search-btn active" : "search-btn"
                      }
                      onClick={handleSearch}
                    />
                    <Button
                      variant="primary"
                      onClick={() => setShowFiltersModal(!showFiltersModal)}
                      className="side-buttons filter-button"
                    >
                      <BsSliders2 size="18px" className="mr-4" />
                    </Button>
                  </Form.Group>
                </Form>
                <div className="d-flex justify-content-center align-items-center">
                  <Button
                    onClick={(e) => handleExport(e)}
                    className="side-buttons export-button"
                  >
                    <Share color="white" size="17px" className="mr-4" />
                  </Button>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      )}

      <div className="filter-alert ps-4 d-flex flex-row justify-content-between align-items-center">
        <ExportModal
          show={show}
          handleClose={handleClose}
          handleExport={handleExport}
          filters={[
            <Col>
              <Form.Label htmlFor="dateRangeSelect">Date</Form.Label>
              <DateRangeSelect
                startDate={exportStartDate}
                endDate={exportEndDate}
                handleDateRangeChange={handleExportDateRangeChange}
              />
            </Col>,
            <Col>
              <Form.Label htmlFor="propertySelect">Property</Form.Label>
              <MultiselectSearch
                data={propertiesData?.properties}
                selected={exportProperties}
                setSelected={(value) => setExportProperties(value)}
              />
            </Col>,
          ]}
        />
        {showFiltersModal && (
          <FiltersModal
            show={showFiltersModal}
            handleClose={handleCloseFiltersModal}
            handleApplyFilters={handleApplyFilters}
          />
        )}
      </div>

      {
        // MARK: Search, Filter
      }
      <div className="content-div my-3">
        {screenWidth > 1200 ? (
          <div className="d-flex mt-5">
            <Form
              className="w-75"
              style={{
                gap: "10px",
                flexWrap: "wrap",
                justifyContent: "space-between",
              }}
            >
              <Form.Group
                className="lead_search-control-group mb-5"
                controlId="search-control"
              >
                <Form.Control
                  type="text"
                  placeholder="Search"
                  style={{
                    height: "40px",
                    paddingLeft: "30px",
                    fontFamily: "Poppins",
                    color: "#979797",
                    fontWeight: "400",
                  }}
                  value={searchParam}
                  onChange={handleSearchTerm}
                  onKeyDown={handleKeyDown}
                />
                {searchParam !== "" ? (
                  <Button
                    className="cancel-filter-button"
                    onClick={handleReset}
                    variant="link"
                  >
                    X
                  </Button>
                ) : null}
                <Button
                  className={
                    searchParam !== "" ? "search-btn active" : "search-btn"
                  }
                  onClick={handleSearch}
                />
              </Form.Group>
            </Form>
            <div className="d-flex justify-content-end button-containers">
              <div className="">
                <UnstyledButton
                  variant="outline-dark"
                  onClick={handleResetFilters}
                  className="clear-buttons"
                >
                  Clear All
                </UnstyledButton>

                <Button
                  variant="primary"
                  onClick={() => setShowFiltersModal(!showFiltersModal)}
                  className="side-buttons"
                >
                  <BsSliders2 size="14px" className="mr-4" /> Filter By
                </Button>
              </div>

              <Button onClick={(e) => handleExport(e)} className="side-buttons">
                <CiExport size="14px" className="mr-4" /> Export
              </Button>
            </div>
          </div>
        ) : screenWidth > 755 ? (
          <div className="d-flex mt-5">
            <Form
              className="w-75"
              style={{
                gap: "10px",
                flexWrap: "wrap",
                justifyContent: "space-between",
              }}
            >
              <Form.Group
                className="lead_search-control-group mb-5"
                controlId="search-control"
              >
                <Form.Control
                  type="text"
                  placeholder="Search"
                  style={{
                    height: "40px",
                    paddingLeft: "30px",
                    fontFamily: "Poppins",
                    color: "#979797",
                    fontWeight: "400",
                  }}
                  value={searchParam}
                  onChange={handleSearchTerm}
                  onKeyDown={handleKeyDown}
                />
                {searchParam !== "" ? (
                  <Button
                    className="cancel-filter-button"
                    onClick={handleReset}
                    variant="link"
                  >
                    X
                  </Button>
                ) : null}
                <Button
                  className={
                    searchParam !== "" ? "search-btn active" : "search-btn"
                  }
                  onClick={handleSearch}
                />
              </Form.Group>
            </Form>
            <div className="d-flex justify-content-end button-containers">
              <div className="">
                <UnstyledButton
                  variant="outline-dark"
                  onClick={handleResetFilters}
                  className="clear-buttons"
                >
                  Clear All
                </UnstyledButton>

                <Button
                  style={{ width: "45px" }}
                  variant="primary"
                  onClick={() => setShowFiltersModal(!showFiltersModal)}
                  className="side-buttons"
                >
                  <BsSliders2 size="14px" className="mr-4" />
                </Button>
              </div>

              <Button
                style={{ width: "45px" }}
                onClick={(e) => handleExport(e)}
                className="side-buttons"
              >
                <CiExport size="14px" className="mr-4" />
              </Button>
            </div>
          </div>
        ) : (
          ""
        )}
        <div className="total-contacts">
          {isLoading ? (
            <div>Getting contacts...</div>
          ) : data?.total > 0 ? (
            <p>Total Contacts: {data.total}</p>
          ) : (
            <p>No Records Found</p>
          )}
        </div>
        {
          // MARK: Lead Table
        }
        {screenWidth > 755 ? (
          <Table responsive className="">
            {isLoading ? (
              <Center mt={20}>
                <Loader />
              </Center>
            ) : (
              <tbody>
                {data?.data?.map((element, index) => (
                  <tr key={element._id} className="cursor-pointer">
                    <td>
                      <div className="d-flex align-items-center">
                        <Link
                          // to="/leads-details"
                          to={`/leads-details/${element.contact._id}`} //TODO: check
                          state={{ user_id: element.contact._id }}
                          className="properties-details-link"
                        >
                          <span className="position-relative d-inline-block">
                            <img
                              className="id-image"
                              src={getImageUrl(
                                element.contact.document ??
                                  element.contact.idImage
                              )}
                              onError={({ currentTarget }) => {
                                currentTarget.onerror = null; // prevents looping
                                currentTarget.src = require("../../assests/images/aljnfn.png");
                              }}
                              alt="identification"
                            />
                          </span>
                        </Link>
                      </div>
                    </td>
                    <td>
                      <div className="ml-20">
                        <div className="d-block mt-1">
                          <Link to="#" className="contact-info">
                            <IoMdPerson className="icon" />
                            {element.contact.firstName}{" "}
                            {element.contact.lastName}
                          </Link>
                        </div>
                        <div className="d-block">
                          <Link
                            to={`tel:${element.contact.phone}`}
                            className="contact-info"
                          >
                            <MdLocalPhone className="icon" />
                            {element.contact.phone}
                          </Link>
                        </div>
                        <div className="d-block">
                          <Link
                            to={`mailto:${element.contact.email}`}
                            className="contact-info"
                          >
                            <MdEmail className="icon" />
                            {element.contact.email}
                          </Link>
                        </div>
                      </div>
                    </td>
                    <td>
                      {/* {element.bookings && element.bookings.length > 0 ? ( // TODO */}
                      <Link
                        to={`/property/${element.property?._id}`}
                        style={{ textDecoration: "none", color: "black" }}
                      >
                        <div className="d-flex align-items-center no-wrap-small-screen">
                          <div className="ml-20 my-3">
                            <div className="d-block latest-showing">
                              latest showing
                              <br />
                              <p className="date-time">
                                {moment(element.startTime * 1000).format(
                                  "MMM  DD, yyyy hh:mm a"
                                )}
                              </p>
                            </div>
                            <div className="prop-add">
                              {element.property
                                ? (() => {
                                    const fullAddress = removeUndefinedWord(
                                      element.property.address
                                    );
                                    const addressParts =
                                      fullAddress.split(", ");
                                    if (element.property.unit) {
                                      addressParts[0] += ` Unit ${element.property.unit}`;
                                    }
                                    return addressParts.join(", ");
                                  })()
                                : "PROPERTY NOT FOUND"}
                            </div>
                          </div>
                        </div>
                      </Link>
                    </td>
                    <td className="added-info">
                      added on
                      <br />
                      {moment(element.createdAt ?? element.createdDate).format(
                        "MM/DD/YYYY"
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            )}
          </Table>
        ) : (
          <div responsive className="">
            {isLoading ? (
              <Center mt={20}>
                <Loader />
              </Center>
            ) : (
              <div>
                {data?.data?.map((element, index) => (
                  <div key={element._id} className="cursor-pointer lead_data">
                    <div className="d-flex">
                      <div className="d-flex col-5 align-items-center">
                        <Link
                          // to="/leads-details"
                          to={`/leads-details/${element.contact._id}`} //TODO: check
                          state={{ user_id: element.contact._id }}
                          className="properties-details-link"
                        >
                          <span className="position-relative d-inline-block">
                            <img
                              className="id-image"
                              src={getImageUrl(
                                element.contact.document ??
                                  element.contact.idImage
                              )}
                              onError={({ currentTarget }) => {
                                currentTarget.onerror = null; // prevents looping
                                currentTarget.src = require("../../assests/images/aljnfn.png");
                              }}
                              alt="identification"
                            />
                          </span>
                        </Link>
                      </div>
                      <div className="col">
                        <div className="d-block mt-1">
                          <Link to="#" className="contact-info">
                            <IoMdPerson className="icon" />
                            {element.contact.firstName}{" "}
                            {element.contact.lastName}
                          </Link>
                        </div>
                        <div className="d-block">
                          <Link
                            to={`tel:${element.contact.phone}`}
                            className="contact-info"
                          >
                            <MdLocalPhone className="icon" />
                            {element.contact.phone}
                          </Link>
                        </div>
                        <div className="d-block">
                          <Link
                            to={`mailto:${element.contact.email}`}
                            className="contact-info"
                          >
                            <MdEmail className="icon" />
                            {element.contact.email}
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div>
                      {/* {element.bookings && element.bookings.length > 0 ? ( // TODO */}
                      <Link
                        to={`/property/${element.property?._id}`}
                        style={{ textDecoration: "none", color: "black" }}
                      >
                        <div className="d-flex align-items-center no-wrap-small-screen">
                          <div className=" mt-3">
                            <div className="d-block latest-showing">
                              latest showing
                              <br />
                              <p className="date-time">
                                {moment(element.startTime * 1000).format(
                                  "MMM  DD, yyyy hh:mm a"
                                )}
                              </p>
                            </div>
                            <div className="prop-add">
                              {element.property
                                ? (() => {
                                    const fullAddress = removeUndefinedWord(
                                      element.property.address
                                    );
                                    const addressParts =
                                      fullAddress.split(", ");
                                    if (element.property.unit) {
                                      addressParts[0] += ` Unit ${element.property.unit}`;
                                    }
                                    return addressParts.join(", ");
                                  })()
                                : "PROPERTY NOT FOUND"}
                            </div>
                          </div>
                        </div>
                      </Link>
                    </div>
                    <div className="added-info">
                      added on
                      <br />
                      {moment(element.createdAt ?? element.createdDate).format(
                        "MMM d, YYYY hh:mm a"
                      )}
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        )}
        {data?.pages > 1 ? (
          <div className="pagination_component">
            <PagePagination
              totalPages={data.pages}
              actualPage={page}
              handlePageChange={handleNextPage}
            />
          </div>
        ) : null}

        {showAlert ? (
          <Modal show={showAlert}>
            <Modal.Header>
              <Modal.Title>Alert</Modal.Title>
              <button
                className="btn-close"
                aria-label="Close"
                onClick={() => setShowAlert(false)}
              ></button>
            </Modal.Header>
            <Modal.Body>Something Went Wrong</Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => setShowAlert(false)}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        ) : (
          ""
        )}
      </div>
    </Sidebar>
  );
};

export default Leads;
