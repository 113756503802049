import React from 'react'
import './Tablet-Table.scss';
import {
  Link,
} from 'react-router-dom';
import {
  Col,
  Row,
} from 'react-bootstrap';
import StyledSelect from '../../../../components/Status';
import moment from 'moment';
import getImageUrl from '../../../../helper/checkImageFormat';
import removeUndefinedWord from '../../../../helper/updateString';

const TabletTable = ({ list, timezone, getKey, handleChangeStatus, propertyStatus }) => {

  return (
    list.map((element, index) => {
      return (
        <div className='tablet-table'
          key={element._id}
        >
          <Row>
            <Col lg={3} md={3} sm={3} xs={4} className='image-col d-flex'
            >
              <Link
                to={`/property/${element._id}`}
                className="properties-details-linkImage"
              >
                <span className="position-relative d-inline-block">
                  <img
                    src={getImageUrl(element.primaryImage)}
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null; // prevents looping
                      currentTarget.src = require('../../../../assests/images/6-thumb.png');
                    }}
                    alt="properties"
                    className='img-fluid'
                  />

                  {/* <span
                    className={`text-uppercase properties-kit-status properties-${minutesAgo(
                      element.lastPing,
                    )}`}
                  >
                    {minutesAgo(element.lastPing)}
                  </span> */}
                </span>
              </Link>

            </Col>
            <Col lg={9} md={9} sm={9} xs={8} className='content-col'>
              {/* <Link
              to={`/property/${element._id}`}
              className="properties-details-link"
            >
              <Badge className={`${element.category === 'rent' ? 'rent' : element.category === 'buy' ? 'sale' : 'rent'}`}>
                for {element.category === 'rent' ? 'rent' : element.category === 'buy' ? 'sale' : element.category}</Badge>
              <p className="addreess">{element.address}</p>
            </Link> */}
              <div className='classification'>
                <span className="apartment">
                  {element.classification}
                </span>
              </div>


              <span className="property-address">{removeUndefinedWord(element.address?.firstLine)}</span>
              <span className="property-address">{removeUndefinedWord(element.address?.secondLine)}</span>

              <div className='accessories'>
                {/* <span className="apartment">
                {element.classification}
              </span> */}

                <span className="text-nowrap bedroom">
                  {/* {element.features.rooms} BR */}
                  <span className='body-xs-b'>{element.bedrooms && `${element.bedrooms}BR`}</span>
                </span>

                <span className="bath text-nowrap">
                  {/* {element.features.bathrooms} BA */}
                  <span className='body-xs-b'>{element.bathrooms && `${element.bathrooms}BA`}</span>
                </span>


              </div>
              <div className="price-date">
                <span className="price-value">
                  ${element.price}
                </span>
                <span className="order">{element.listedTs
                  ? moment.utc(element.listedTs + (timezone * 3600000)).format('MM/DD/YYYY')
                  : 'No Listed Date'}.</span>
              </div>
              <div onMouseOver={(e) => getKey(e, element._id)} className='select-status'>

                <StyledSelect
                  status={
                    element.status
                  }
                  menuPortalTarget={document.body}
                  values={[]}
                  onChange={(e) => handleChangeStatus(e, element._id)}
                  placeholder={element.status}
                  options={propertyStatus}
                  size="sm"
                  className='status-td'
                />
                {/* <Select
                style={{
                  border: 'none',
                  borderRadius: '6px',
                  fontSize: '10px',
                  padding: '4px',
                  background: element.status === 'active' ?
                    '#52ff584d'
                    : element.status === 'inactive'
                      ? '#ff72774d'
                      : element.status === 'rented'
                        ? '#e9d62a4d'
                        : element.status === 'sold'
                          ? '#e9d62a4d'
                          : '#9293994d',
                }}
                menuPortalTarget={document.body}
                values={[]}
                onChange={(e) => handleChangeStatus(e, element._id)}
                placeholder={element.status}
                options={propertyStatus}
                size="sm"
                className={`${element.status}`}
              /> */}
              </div>
            </Col>
          </Row>
        </div>
      )
    })
  )
}

export default TabletTable 