import React from "react";
import { ButtonGroup, Dropdown, DropdownButton } from "react-bootstrap";

const ButtonDropdown = ({ title, icon, items, className = "" }) => {
  return (
    <DropdownButton
      as={ButtonGroup}
      title={<>{icon}</>}
      id="export-dropdown"
      className={className}
    >
      {items?.map((item, i) => (
        <Dropdown.Item
          key={i}
          eventKey={1}
          as="button"
          onClick={item.handleClick}
        >
          {item.name}
        </Dropdown.Item>
      ))}
    </DropdownButton>
  );
};

export default ButtonDropdown;
