import React, { useCallback, useEffect, useState } from "react";

import axios from "axios";
import { Badge, Button, Modal } from "react-bootstrap";
import { ColorRing } from "react-loader-spinner";
import { Link, useNavigate } from "react-router-dom";

import { Auth } from "aws-amplify";
import { IoMdPerson } from "react-icons/io";

const MobileAccountUsers = ({ selectValue, Value, q }) => {
  const navigate = useNavigate();
  const [list, setList] = useState([]);
  const [error, setError] = useState("");
  const [modelshow, setModelShow] = useState(false);
  const [Loader, setLoader] = useState(false);
  const [searchParam] = useState([
    "email",
    "firstName",
    "lastName",
    "role",
    "active",
  ]);

  const getUserList = useCallback(
    (token) => {
      var url =
        process.env.REACT_APP_NODE_API_URL + "/api/users/account/admin/list";
      axios
        .get(url, { headers: { Authorization: `Bearer ${token}` } })
        .then((response) => {
          if (response.status === 200) {
            setList(response.data);
            setLoader(true);
          } else {
            setError("Error Getting Users");
            setModelShow(true);
          }
        })
        .catch((error) => {
          if (error?.response?.status === 401) {
            Auth.signOut()
              .then(() => {
                navigate("/admin/login");
              })
              .catch((error) => {
                console.log("Error signing out: ", error);
              });
          } else {
            setError("Error Getting Users");
            setModelShow(true);
          }
        });
    },
    [navigate]
  );

  const search = (items) => {
    return items.filter((item) => {
      return searchParam.some((newItem) => {
        return (
          item[newItem]?.toString().toLowerCase().indexOf(q.toLowerCase()) > -1
        );
      });
    });
  };

  useEffect(() => {
    async function getTokenForUserList() {
      try {
        const token = await Auth.currentSession();
        if (!token) {
          setError(error);
          navigate("/admin/login");
        }
        getUserList(token.getIdToken().getJwtToken());
      } catch (error) {
        setError(error);
        navigate("/admin/login");
      }
    }
    getTokenForUserList();
  }, [error, getUserList, navigate]);

  return (
    <>
      {Loader === false ? (
        <div className="text-center">
          <ColorRing
            visible={true}
            height="100"
            width="100"
            ariaLabel="blocks-loading"
            wrapperStyle={{}}
            wrapperClass="blocks-wrapper"
            colors={["#1F60AE", "#5C79FF", "#455DC8", "#889DFF", "#5271FF"]}
          />
        </div>
      ) : (
        <div>
          {search(
            list.filter((list) => {
              if (Value === false) {
                return list.role === selectValue;
              } else {
                return true;
              }
            })
          ).map((element) => (
            <div
              key={element._id}
              className="d-flex flex-column p-2 border-bottom"
              onClick={() => navigate(`/account/user/details/${element._id}`)}
            >
              <div className="d-flex pb-2">
                <Link
                  to={`/account/user/details/${element._id}`}
                  className="user-details-link d-flex align-items-center justify-content-center p-2"
                >
                  <span className="position-relative d-inline-block">
                    <img
                      width={80}
                      height={50}
                      src={require("../assests/images/img-6.png")}
                      alt=""
                    />
                  </span>
                </Link>

                <div className="d-flex flex-column">
                  <div className="text-nowrap">
                    <IoMdPerson className="icon" />
                    <span className="mx-2">
                      {element.firstName} {element.lastName}
                    </span>
                  </div>

                  <div className="phonenumber-w-ico link-w-ico color-black">
                    {element.phone}
                  </div>

                  <div className="text-nowrap table-mail mail-w-ico link-w-ico">
                    {element.email}
                  </div>
                </div>
              </div>

              <div className="d-flex justify-content-evenly">
                <div className="text-nowrap">
                  {element.company ? element.company.name : ""}
                </div>

                <div>
                  <Badge className="rent">{element.role}</Badge>
                </div>

                <div>
                  {element.active === false ? (
                    <span
                      className="badge"
                      style={{ backgroundColor: "#ffcccb" }}
                    >
                      Inactive
                    </span>
                  ) : (
                    <span
                      className="badge"
                      style={{
                        backgroundColor: "rgba(0, 186, 8, 0.1)",
                        color: "rgba(0, 186, 8)",
                      }}
                    >
                      Active
                    </span>
                  )}
                </div>
              </div>
              <div className="d-flex flex-column py-2 px-5">
                <strong>Description:</strong>
                <span className=" text-nowrap px-2">{element.description ? element.description : " - "}</span>
              </div>
            </div>
          ))}
        </div>
      )}

      {modelshow && (
        <Modal show={modelshow}>
          {error !== "" && (
            <>
              <Modal.Header>
                <Modal.Title>Alert</Modal.Title>
                <button
                  className="btn-close"
                  aria-label="Close"
                  onClick={() => setModelShow(false)}
                ></button>
              </Modal.Header>
              <Modal.Body>{error}</Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={() => setModelShow(false)}>
                  Close
                </Button>
              </Modal.Footer>
            </>
          )}
        </Modal>
      )}
    </>
  );
};

export default MobileAccountUsers;
