import React, { useState, useEffect, useRef, useCallback } from "react";

const LazyImgWithPlaceholder = ({ src, alt, width, height, placeholder, border }) => {
  const [imageSrc, setImageSrc] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isInView, setIsInView] = useState(false);
  const containerRef = useRef(null);

  const handleIntersection = useCallback((entries) => {
    const [entry] = entries;
    if (entry.isIntersecting) {
      setIsInView(true);
    }
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(handleIntersection, {
      root: null,
      rootMargin: "100px",
      threshold: 0,
    });

    const currentContainer = containerRef.current;

    if (currentContainer) {
      observer.observe(currentContainer);
    }

    return () => {
      if (currentContainer) {
        observer.unobserve(currentContainer);
      }
      observer.disconnect();
    };
  }, [handleIntersection]);

  useEffect(() => {
    if (!isInView) return;

    const img = new Image();
    img.onload = () => {
      setImageSrc(src);
      setIsLoading(false);
    };
    img.onerror = () => {
      setImageSrc(placeholder);
      setIsLoading(false);
    };
    img.src = src;
  }, [src, isInView, placeholder]);

  return (
    <div
      ref={containerRef}
      className=" overflow-hidden position-relative bg-white"
      style={{
        width: `${width}px`,
        height: `${height}px`,
        borderRadius: `${border}px`
      }}
    >
      {(isLoading || !isInView) && (
        <div
          className="placeholder-glow position-absolute w-100 h-100 top-0 start-0"
        >
          <span className="placeholder col-12 h-100"></span>
        </div>
      )}
      {isInView && (
        <img
          src={imageSrc || placeholder}
          alt={alt}
          className="position-absolute w-100 h-100"
          style={{
            objectFit: "cover",
            top: 0,
            left: 0,
            opacity: isLoading ? 0 : 1,
            transition: "opacity 0.3s ease-in-out",
          }}
        />
      )}
    </div>
  );
};

export default LazyImgWithPlaceholder;
