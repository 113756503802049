import React, { useState } from "react";
import { Button, Col, Offcanvas, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import DatePicker from "react-datepicker";
import SelectProperty from "../SelectProperty";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import documentPlaceHolder from "../../../../assests/images/1-placeholder.png";
import { format } from "date-fns-tz";

const validationSchema = Yup.object().shape({
  firstName: Yup.string().required("First name is required"),
  lastName: Yup.string().required("Last name is required"),
  phone: Yup.string().required("Phone number is required"),
  email: Yup.string().email("Invalid email").required("Email is required"),
  document: Yup.mixed().required("ID is required"),
});

const NewBookingForm = ({ show, handleClose, handleSubmit, isLoading, error }) => {
  const [selectedProperty, setSelectedProperty] = useState(null);
  const [showProperties, setShowProperties] = useState(false);

  const initialValues = {
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
    document: null,
    localTimeRequested: new Date(),
  };

  const handleSelectProperty = (property) => {
    setSelectedProperty(property);
    setShowProperties(false);
  };

  const filterPassedTime = (time) => {
    const currentDate = new Date().getTime();
    const currentTime = new Date(time).getTime();
    return currentTime > currentDate;
  };

  return (
    <Offcanvas
      show={show}
      onHide={handleClose}
      placement="end"
      name="end"
      className=""
    >
      <SelectProperty
        show={showProperties}
        handleClose={() => setShowProperties(false)}
        handleSelectProperty={handleSelectProperty}
      />
      <Offcanvas.Header closeButton>
        <Offcanvas.Title>New showing</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values) =>
            handleSubmit({ ...values, propertyId: selectedProperty._id })
          }
        >
          {({ setFieldValue, values }) => (
            <Form encType="multipart/form-data">
              <Row>
                <Col xl={6} lg={12}>
                  <div className="mb-4">
                    <Field
                      type="text"
                      name="firstName"
                      placeholder="First name"
                      className="form-control"
                    />
                    <ErrorMessage
                      name="firstName"
                      component="div"
                      className="text-danger"
                    />
                  </div>
                  <div className="mb-4">
                    <Field
                      type="text"
                      name="lastName"
                      placeholder="Last name"
                      className="form-control"
                    />
                    <ErrorMessage
                      name="lastName"
                      component="div"
                      className="text-danger"
                    />
                  </div>
                  <div className="mb-4">
                    <Field
                      type="text"
                      name="phone"
                      placeholder="Phone number"
                      className="form-control"
                    />
                    <ErrorMessage
                      name="phone"
                      component="div"
                      className="text-danger"
                    />
                  </div>
                  <div className="mb-4">
                    <Field
                      type="email"
                      name="email"
                      placeholder="Email address"
                      className="form-control"
                    />
                    <ErrorMessage
                      name="email"
                      component="div"
                      className="text-danger"
                    />
                  </div>
                </Col>

                <Col xl={6} lg={12} style={{ maxHeight: "100%" }}>
                  <div className="mb-20 formFile-control">
                    <div className="upload-id-copy">
                      <label htmlFor="document">
                        <img
                          className="img-drop-area w-100 h-100"
                          src={values.document ?? documentPlaceHolder}
                          alt="lead-document"
                          style={{
                            maxWidth: "100%",
                            maxHeight: "200px",
                            objectFit: "contain",
                          }}
                        />
                        <span className="btn btn-primary d-inline-block">
                          Upload ID copy
                        </span>
                      </label>
                      <input
                        type="file"
                        name="document"
                        id="document"
                        onChange={(event) => {
                          const file = event.currentTarget.files[0];
                          if (file) {
                            const reader = new FileReader();
                            reader.onloadend = () => {
                              setFieldValue("document", reader.result);
                            };
                            reader.readAsDataURL(file);
                          }
                        }}
                        className="form-control d-none"
                      />
                    </div>
                    <ErrorMessage
                      name="document"
                      component="div"
                      className="text-danger"
                    />
                    <em className="upload-id-copy-em">
                      To add a copy of the ID, use the Upload button, or drag
                      the image into this box.
                    </em>
                  </div>
                </Col>
              </Row>

              {!selectedProperty ? (
                <Row>
                  <Col>
                    <div className="select-property-panel d-flex justify-content-center">
                      <Button
                        className="btn btn-primary btn-no-icon"
                        onClick={() => setShowProperties(true)}
                      >
                        Select property
                      </Button>
                    </div>

                    <div className="offcanvas-calander-wrapper">
                      <div className="d-flex flex-wrap flex-md-nowrap">
                        <div className="date-picker-wrapper panel-disabled">
                          <DatePicker
                            selected={values.localTimeRequested}
                            onChange={(date) => {
                              const formattedDate = format(
                                date,
                                "yyyy-MM-dd HH:mm"
                              );
                              setFieldValue("localTimeRequested", formattedDate);
                            }}
                            inline
                            showTimeSelect
                            timeFormat="p"
                            timeIntervals={15}
                            dateFormat="Pp"
                            name="selectdate"
                            minDate={new Date()}
                            filterTime={filterPassedTime}
                          />
                        </div>
                        <div
                          className="selected-day-wrapper before-selecting"
                          style={{ backgroundColor: "#ECECEC" }}
                        >
                          <div className="d-flex justify-content-center flex-column h-100 align-items-center text-center">
                            <h5 className="text-center">
                              Before selecting the date, you must select the
                              property
                            </h5>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              ) : (
                <Row>
                  <Col>
                    <div className="d-flex align-items-center bg-secondary-subtle rounded">
                      <div className="notes-img me-2 ">
                        <img
                          src={selectedProperty.images[0] ?? documentPlaceHolder}
                          alt="property"
                          className="border-r-5"
                          width={140}
                        />
                      </div>
                      <div className="notes-address">
                        <h6>{selectedProperty.address}</h6>
                        <Link
                          onClick={() => setShowProperties(true)}
                          size="sm"
                          className="btn btn-primary btn-sm edit-property-btn mr-30"
                        >
                          Change property
                        </Link>
                      </div>
                    </div>
                    <div className="pt-4 d-flex gap-4">
                      <DatePicker
                        selected={new Date(values.localTimeRequested)}
                        onChange={(date) => {
                          const formattedDate = format(
                            date,
                            "yyyy-MM-dd HH:mm"
                          );
                          setFieldValue("localTimeRequested", formattedDate);
                        }}
                        inline
                        minDate={new Date()}
                        showTimeSelect
                        timeFormat="p"
                        timeIntervals={15}
                        dateFormat="Pp"
                        filterTime={filterPassedTime}
                      />

                      <div className="d-flex flex-column justify-content-around">
                        <Button
                          className="btn light-btn btn-no-icon"
                          type="submit"
                          disabled={isLoading}
                        >
                          {isLoading ? "Creating new book" : "Create showing"}
                        </Button>

                        <Button
                          className="btn btn-outline-secondary"
                          onClick={handleClose}
                          disabled={isLoading}
                        >
                          Cancel
                        </Button>
                      </div>
                    </div>
                  </Col>
                </Row>
              )}
            </Form>
          )}
        </Formik>
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default NewBookingForm;
