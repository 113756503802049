import React, { useCallback, useEffect, useState } from "react";
import moment from "moment";
import {
  Badge,
  Button,
  Center,
  Collapse,
  Group,
  Loader,
  Stack,
  Table,
  Text,
} from "@mantine/core";
import UseHttp from "../hooks/UseHttp";
import { Auth } from "aws-amplify";
import { useGeneral } from "../context/GeneralContext";
import { RiDownload2Line } from "react-icons/ri";

const MobileAccountBilling = ({ toggleRow, openedRows }) => {
  const [data, setData] = useState([]);

  const { isLoading, error, requestData } = UseHttp();
  const { setAlertModal } = useGeneral();

  const getBillingHistory = useCallback(async () => {
    Auth.currentSession().then((token) => {
      const requestConfig = {
        url: `${process.env.REACT_APP_NODE_API_URL}/api/invoices`,
        headers: {
          Authorization: `Bearer ${token.getIdToken().getJwtToken()}`,
        },
      };
      requestData(requestConfig, (response) => {
        const sortedResponse = response.sort((a, b) => {
          return new Date(b.paymentDate) - new Date(a.paymentDate);
        });
        setData(sortedResponse);
      });
    });
  }, [requestData]);

  const handleDownload = (e, pdfPath) => {
    e.preventDefault();
    const link = document.createElement("a");
    link.href = pdfPath;
    link.download = "invoice.pdf";
    document.body.appendChild(link);

    link.click();

    document.body.removeChild(link);
  };

  useEffect(() => {
    getBillingHistory();
  }, [getBillingHistory]);

  useEffect(() => {
    if (error) {
      console.log(error);
      setAlertModal({
        show: true,
        title: error.title,
        content: error.content,
      });
    }
  }, [error, setAlertModal]);

  const rows = data.map((element, index) => {
    return (
      <Table.Tbody key={element.id}>
        <Table.Tr onClick={() => toggleRow(index)}>
          <Table.Td>
            {moment(element.paymentDate).format("MMM Do, YYYY")}
          </Table.Td>
          <Table.Td>{moment(element.period).format("MMM YYYY")}</Table.Td>

          <Table.Td>${(element.amount / 100).toFixed(2)}</Table.Td>
          <Table.Td> {element.paymentMethod}</Table.Td>

          <Table.Td>
            {element.paid ? (
              <Badge variant="light" color="green">
                Paid
              </Badge>
            ) : (
              <Badge color="red" variant="light">
                Not paid
              </Badge>
            )}
          </Table.Td>
          <Table.Td>
            <Button
              onClick={(e) => {
                console.log(element);
                handleDownload(e, element.pdf);
              }}
              size="xs"
              variant="transparent"
              p={0}
            >
              <RiDownload2Line />
            </Button>
          </Table.Td>
        </Table.Tr>
        <Table.Tr display={!openedRows[index] && "none"}>
          <Table.Td colSpan={7}>
            <Collapse in={openedRows[index]} duration={400}>
              <Stack>
                <Group pl={95}>
                  <Text size="sm">Kit 20:</Text>
                  <Text size="sm">Address, Unit, ZIPCODE</Text>
                </Group>
                <Group pl={95}>
                  <Text size="sm">Kit 20:</Text>
                  <Text size="sm">Address, Unit, ZIPCODE</Text>
                </Group>
              </Stack>
            </Collapse>
          </Table.Td>
        </Table.Tr>
      </Table.Tbody>
    );
  });

  return isLoading ? (
    <Center h={100}>
      <Loader />
    </Center>
  ) : (
    <Table.ScrollContainer h={"60vh"}>
      <Table
        striped
        highlightOnHover
        withRowBorders={false}
        verticalSpacing="lg"
      >
        {data?.length ? (
          rows
        ) : (
          <Table.Tbody>
            <Table.Tr>
              <Table.Td colSpan={7}>
                <Center m={50}>
                  <Text>There are no records to display</Text>
                </Center>
              </Table.Td>
            </Table.Tr>
          </Table.Tbody>
        )}
      </Table>
    </Table.ScrollContainer>
  );
};

export default MobileAccountBilling;
