import React from "react";
import InputSelect from "../../../../components/InputSelect";
import { useQuery } from "@tanstack/react-query";
import { useField, useFormikContext } from "formik";
import Select from "react-select";
import { getSNList } from "../../../../api/lock";

const AugustSNSelect = ({ name, label, selectedLock, ...props }) => {
  const { setFieldValue } = useFormikContext();
  const [field, meta] = useField(name);

  const {
    data: locksList,
    isLoading,
    isError,
  } = useQuery({
    queryKey: ["august-sn-list"],
    queryFn: getSNList,
  });

  const handleChange = (selectedOption) => {
    setFieldValue(name, selectedOption.serialNumber);
  };

  const getValue = () => {
    return locksList?.find((lock) => lock.serialNumber === field.value) || null;
  };

  return (
    <>
      <Select
        {...props}
        id={name}
        name={name}
        options={locksList}
        getOptionLabel={(lock) => lock.serialNumber}
        value={getValue()}
        onChange={handleChange}
        onBlur={() => field.onBlur({ target: { name } })}
        className="h-100"
        isDisabled={isLoading}
      />
      {meta.touched && meta.error ? (
        <div className="error">{meta.error}</div>
      ) : null}
    </>
  );
};

export default AugustSNSelect;
