import React from "react";
import Card from "./Card";
import removeUndefinedWord from "../../../../helper/updateString";

export default function Step1({
  list,
  selectProperty,
  propertyIndex,
  setPageEnable,
  screenWidth,
  handleStepClickNext,
}) {
  const isScreenWidth = screenWidth > 1200;
  return (
    <>
      {isScreenWidth ? (

        <div>
          <span className="step-title">{"Step 1"}</span>
          <h4>Choose a property to book</h4>
        </div>
      ) : (
        <h5 className="text-capitalize">Step 1: Enter Your Info</h5>
      )}
      <div className="steps-container pt-3" id="scroll">
        {list?.map((property, index) => {
          return (
            <div
              key={index}
              onClick={() =>
                selectProperty(
                  list[index]._id,
                  screenWidth > 1200 && setPageEnable(2)
                )

              }
              className={`card ${propertyIndex === list[index]._id &&
                "border border-2 border-black"
                }`}
            >
              <Card
                images={property.images}
                address={
                  list[index] ? removeUndefinedWord(list[index].address) : ""
                }
                keyCount={property.keyCount || ""}
                bedCount={list[index] ? list[index].bedrooms : ""}
                bathCount={list[index] ? list[index].bathrooms : ""}
                price={property.price}
              />
            </div>
          );
        })}
        {screenWidth < 1200 && (
          <button
            className="btn btn-md btn-block"
            onClick={() => {
              handleStepClickNext(1);
            }}
          >
            Next Step &gt;&gt;
          </button>
        )}
      </div>
    </>
  );
}
