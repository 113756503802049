import React, { forwardRef, useState } from "react";
import { Formik, Form, Field, useFormikContext, useField } from "formik";
import { Button, Form as BootstrapForm } from "react-bootstrap";
import DatePicker from "react-datepicker";
import * as Yup from "yup";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { createPassCode } from "../../api/keypad";
import toast from "react-hot-toast";
import { setHours, setMinutes, isSameDay, startOfDay } from "date-fns";
import { BsFillInfoCircleFill } from "react-icons/bs";

const validationSchema = (isAugust) => {
  return Yup.object().shape({
    name: Yup.string().required("Name is required"),
    password: Yup.number()
      .typeError("Code must be a number")
      .required("Code is required")
      .test("len", "Code must be between 6 and 12 characters", (val) => {
        if (isAugust) {
          return (
            val && val.toString().length >= 4 && val.toString().length <= 6
          );
        } else {
          return (
            val && val.toString().length >= 6 && val.toString().length <= 12
          );
        }
      }),
    type: Yup.string().required("Type is required"),
    startTime: Yup.number().when("type", {
      is: (type) => ["timeLimit", "disposable"].includes(type),
      then: (schema) =>
        schema
          .required("Start date is required")
          .min(
            Math.round(Date.now() / 1000 - 60),
            "Start must be a future date"
          ),
      otherwise: (schema) => schema.nullable(),
    }),
    endTime: Yup.number().when("type", {
      is: (type) => ["timeLimit", "disposable"].includes(type),
      then: (schema) =>
        schema
          .required("End time is required")
          .min(1, "End time is required")
          .test(
            "is-greater-than-start",
            "End time must be at least 1 hour later than start time",
            function (endTime) {
              const { startTime } = this.parent;
              return !startTime || !endTime || endTime > startTime + 3550;
            }
          ),
      otherwise: (schema) => schema.nullable(),
    }),
  });
};

const DatePickerField = ({ ...props }) => {
  const { setFieldValue } = useFormikContext();
  const [field] = useField(props);
  return (
    <DatePicker
      {...field}
      {...props}
      selected={(field.value && new Date(field.value * 1000)) || null}
      onChange={(val) => {
        setFieldValue(field.name, Math.round(new Date(val).getTime() / 1000));
      }}
    />
  );
};

const codeTypes = [
  { label: "permanent" },
  { label: "temporary", value: "timeLimit" },
  { label: "one-time", value: "disposable" },
];
const initialValues = {
  type: "",
  name: "",
  password: null,
  startTime: null,
  endTime: null,
};

const KeypadCodeForm = ({ handleCancel, keypadId, isAugust }) => {
  const queryClient = useQueryClient();
  const CustomDateInput = forwardRef(
    ({ value, onClick, className, placeholderText }, ref) => (
      <button
        className={`text-capitalize d-flex align-items-center gap-2  ${className} ${
          value ? "fw-bold" : "fw-light text-secondary"
        }`}
        onClick={onClick}
        ref={ref}
        type="button"
      >
        {value ? value : placeholderText}
      </button>
    )
  );

  const [showInfoPopup, setShowInfoPopup] = useState(false);

  const { mutateAsync: handleCreateCode, isPending } = useMutation({
    mutationFn: async (codeFields) =>
      await createPassCode(keypadId, codeFields),
    onSuccess: (data) => {
      setTimeout(() => {
        queryClient.invalidateQueries(["keypad", keypadId, "code-history"]);
      }, 8000);
      handleCancel();
      toast.success(
        "New code created, it should appear in the next 5 seconds, if not, click on the reload button",
        {
          duration: 5000,
        }
      );
    },
    onError: (err) => toast.error(err.message),
  });

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={() => validationSchema(isAugust)}
        onSubmit={handleCreateCode}
      >
        {({ handleSubmit, values, errors, touched }) => {
          const isTemporary = ["timeLimit", "disposable"].includes(values.type);
          return (
            <Form
              className="d-flex flex-column pb-3 gap-3"
              onSubmit={handleSubmit}
            >
              <div>
                <BootstrapForm.Label
                  htmlFor="type"
                  className="d-flex gap-2 align-items-center"
                >
                  <strong className="text-nowrap">Select type</strong>{" "}
                  <BsFillInfoCircleFill
                    className="d-flex align-items-center cursor-pointer"
                    onMouseOver={() => setShowInfoPopup(true)}
                    onMouseOut={() => setShowInfoPopup(false)}
                  />
                  {showInfoPopup ? (
                    <div className="position-relative w-100 align-self-baseline">
                      <div className="position-absolute p-3 border bg-white d-flex flex-column w-100 top-0 gap-3">
                        <p className="lh-base text-secondary">
                          <span className="fw-medium text-secondary-emphasis">
                            Permanent:{" "}
                          </span>
                          This code is valid from creation and never expires.
                        </p>

                        <p className="lh-base text-secondary">
                          <span className="fw-medium text-secondary-emphasis">
                            Temporary:{" "}
                          </span>
                          This code is valid only between two specified dates.
                        </p>

                        <p className="lh-base text-secondary">
                          <span className="fw-medium text-secondary-emphasis">
                            One Time:{" "}
                          </span>
                          This code is valid for a single use and expires
                          immediately after.
                        </p>
                      </div>
                    </div>
                  ) : null}
                </BootstrapForm.Label>
                <Field
                  as={BootstrapForm.Select}
                  name="type"
                  aria-label="Default select example"
                  className={`text-capitalize py-2 col-12 ${
                    errors.type && touched.type ? "is-invalid" : null
                  }`}
                  defaultValue=""
                >
                  <option value="" disabled>
                    {errors.type && touched.type
                      ? "Code type is required"
                      : "Open this select menu"}
                  </option>
                  {codeTypes.map((option) => (
                    <option key={option.label} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </Field>
              </div>

              <div>
                <BootstrapForm.Label htmlFor="name">
                  <strong>Code Name</strong>
                </BootstrapForm.Label>
                <Field
                  as={BootstrapForm.Control}
                  type="text"
                  id="name"
                  name="name"
                  className={`py-1 ${
                    errors.name && touched.name ? "is-invalid" : null
                  }`}
                  placeholder={
                    errors.name && touched.name
                      ? "Code name is required"
                      : "Family code"
                  }
                />
                <BootstrapForm.Text
                  id="passwordHelpBlock"
                  muted
                  className="fs-8"
                >
                  Can't be repeated from a previous code
                </BootstrapForm.Text>
              </div>

              <div>
                <BootstrapForm.Label htmlFor="code">
                  <strong>Create Code</strong>
                </BootstrapForm.Label>
                <Field
                  as={BootstrapForm.Control}
                  type="text"
                  id="password"
                  name="password"
                  className={`py-1 ${
                    errors.password && touched.password ? "is-invalid" : null
                  }`}
                  placeholder={
                    errors.password && touched.password
                      ? "Code is required"
                      : "000000"
                  }
                />
                <BootstrapForm.Text
                  id="passwordHelpBlock"
                  muted
                  className="fs-8 lh-1"
                >
                  Must be {isAugust ? "4-6" : "6-12"} characters long. Can't be
                  repeated from a previous code.
                </BootstrapForm.Text>
              </div>

              {isTemporary ? (
                <>
                  <div className="row row-cols-2 row-gap-2">
                    <span className="w-100">Set Duration</span>
                    <div className="">
                      <Field
                        as={DatePickerField}
                        name="startTime"
                        type="date"
                        id="startTime"
                        isClearable
                        minDate={new Date()}
                        className="w-100"
                        customInput={
                          <CustomDateInput
                            value={values.startTime}
                            className={`py-1 px-2 w-100 border border-secondary-subtle rounded-2 text-start ${
                              errors.startTime && touched.startTime
                                ? "is-invalid"
                                : ""
                            }`}
                            placeholderText="Start"
                          />
                        }
                      />
                    </div>
                    <div className="">
                      <Field
                        as={DatePickerField}
                        minDate={new Date(values.startTime * 1000)}
                        name="endTime"
                        type="date"
                        id="endTime"
                        isClearable
                        className="w-100"
                        customInput={
                          <CustomDateInput
                            placeholderText="End"
                            className={`py-1 px-2 w-100 border border-secondary-subtle rounded-2 text-start ${
                              errors.endTime && touched.endTime
                                ? "is-invalid"
                                : ""
                            }`}
                          />
                        }
                      />
                    </div>
                  </div>

                  <div className="row row-cols-2 row-gap-2">
                    <span className="w-100">Set Time</span>
                    <div className="position-relative">
                      <Field
                        as={DatePickerField}
                        showTimeSelect
                        showTimeSelectOnly
                        minTime={
                          isSameDay(
                            values.startTime * 1000,
                            new Date().getTime()
                          )
                            ? new Date()
                            : startOfDay(values.endTime * 1000)
                        }
                        maxTime={setHours(setMinutes(new Date(), 45), 23)}
                        dateFormat="h:mm aa"
                        name="startTime"
                        type="date"
                        id="startTime"
                        timeIntervals={15}
                        className="w-100"
                        customInput={
                          <CustomDateInput
                            value={values.startTime}
                            className={`py-1 px-2 w-100 border border-secondary-subtle rounded-2 text-start ${
                              errors.startTime && touched.startTime
                                ? "is-invalid"
                                : ""
                            }`}
                            placeholderText="Start"
                          />
                        }
                      />

                      {touched.startTime && errors.startTime && (
                        <div className="error invalid-feedback d-block">
                          {errors.startTime}
                        </div>
                      )}
                    </div>
                    <div className="position-relative">
                      <Field
                        as={DatePickerField}
                        showTimeSelect
                        showTimeSelectOnly
                        minTime={values.startTime}
                        maxTime={setHours(setMinutes(new Date(), 45), 23)}
                        dateFormat="h:mm aa"
                        timeIntervals={15}
                        name="endTime"
                        type="date"
                        id="endTime"
                        className="w-100"
                        customInput={
                          <CustomDateInput
                            placeholderText="End"
                            className={`py-1 px-2 w-100 border border-secondary-subtle rounded-2 text-start ${
                              errors.endTime && touched.endTime
                                ? "is-invalid"
                                : ""
                            }`}
                          />
                        }
                      />
                      {touched.endTime && errors.endTime && (
                        <div
                          id="endTimeFeedback"
                          className="error invalid-feedback d-block"
                        >
                          {errors.endTime}
                        </div>
                      )}
                    </div>
                  </div>
                </>
              ) : null}

              <div className="d-flex gap-3">
                <Button
                  className="w-100 py-3 border border-secondary-subtle"
                  variant="light"
                  onClick={handleCancel}
                  disabled={isPending}
                >
                  Cancel
                </Button>

                <Button
                  className="w-100 py-3 bg-delet fw-bold fs-6 border-0"
                  type="submit"
                  disabled={isPending}
                >
                  Save Changes
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default KeypadCodeForm;
