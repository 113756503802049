import styled from 'styled-components';
import Select from 'react-dropdown-select';

const OutcomeSelect = styled(Select)`
gap: 0px;
background: ${(props) => {

  if (props.status === 'interested') {
    return 'rgba(0, 186, 8, 0.10)'
  } else if (props.status === 'not interested') {
    return 'rgba(255, 0, 0, 0.10)'
  } else if (props.status === 'application sent' || props.status === 'applied') {
    return 'rgba(216, 243, 243, 0.50)'
  } else if (props.status === 'never arrived') {
    return 'rgba(191, 167, 0, 0.10)';
  } else if(props.status === 'rescheduled'){
    return 'rgba(151, 151, 151, 0.10)';
  }
  else {
    return '#9293994d'
  }
}} !important;

color: ${(props) => {
  if (props.status === 'interested') {
    return '#00BA08';
  } else if (props.status === 'not interested') {
    return '#FF0000';
  } else if (props.status === 'application sent' || props.status === 'applied') {
    return '#1A7D7D';
  } else if (props.status === 'never arrived') {
    return '#BFA700';
  } else if(props.status === 'rescheduled' || props.status === undefined){
    return '#979797';
  }
  else {
    return '#00BA08';
  }
}};

font-family: 'Poppins';
font-size: 14px;
font-weight: 500;

&.react-dropdown-select {
  display: inline-flex;
  border-radius: 4px;
  
  ${'' /* padding: 4px; */}
  justify-content: center;
  align-items: center;
  vertical-align: middle;
  height: 26px;
  width: auto;
  border: none;
  
}

.react-dropdown-select-content {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.react-dropdown-select-input {
  ${'' /* padding: 4px; */}
  display: inline-flex;
  align-items: center;
  justify-content: center;
  &::placeholder {
    color: ${(props) => {
      if (props.status === 'interested') {
        return '#00BA08';
      } else if (props.status === 'not interested') {
        return '#FF0000';
      } else if (props.status === 'application sent' || props.status === 'applied') {
        return '#1A7D7D';
      } else if (props.status === 'never arrived') {
        return '#BFA700';
      } else if(props.status === 'rescheduled' || props.status === undefined){
        return '#979797';
      }  else {
        return '#00BA08';
      }
    }}; 
    font-family: 'Poppins';
    font-size: 14px;
    
  }
}
.react-dropdown-select-dropdown {
  text-align: left;
  padding: 10px;
  width: 190px;
  left: auto;
  right: 15px;
  border-radius: 4px;
  
  .react-dropdown-select-item {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis; 
  padding: 8px;
  font-family: 'Poppins';
  font-weight: 400;
  font-size: 14px;
  light-height: 22px;
  letter-spacing: 0.16px;
  color: #000;
}


  @media (max-width: 767px) {
    left: 0;
  }

  .react-dropdown-select-item:hover,
  .react-dropdown-select-item:focus,
  .react-dropdown-select-item-selected {
    background: #ececec;
    color: #1f2327;
    font-family: 'Poppins';
  }

  span {
    ${'' /* border-radius: 3px;
    font-size: 13px;
    line-height: 13px; */}
    margin-bottom: 10px;
    display: inline;
    color: #1f2327;
    font-family: 'Poppins';

    &:last-child {
      margin-bottom: 0;
    }

    ${'' /* &[aria-label="Active"] {
      background-color: rgba(82, 255, 88, 0.302);
      border: 1px solid rgb(31, 35, 39);
    }

    &[aria-label="Inactive"] {
      background-color: rgba(255, 114, 119, 0.302);
      border: 1px solid rgba(255, 114, 119, 0.3);
    }

    &[aria-label="rescheduled"],
    &[aria-label="never arrived"] {
      background-color: rgba(146, 147, 153, 0.302);
      border: 1px solid rgba(146, 147, 153, 0.3);
    }

    &[aria-label="Rented"],
    &[aria-label="Sold"] {
      background-color: rgba(233, 214, 42, 0.302);
      border: 1px solid rgba(233, 214, 42, 0.3);
    } */}
  }
}

.react-dropdown-select-dropdown-handle {
  margin: 0px 4px 0 5px;
  width: 12px;
  height: 12px;
  display: flex;
  align-items: center;
  
}


`;

export default OutcomeSelect;