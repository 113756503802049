import { DateTime } from "luxon";

function MobileList({ kit = {}, kitHistory = [], hideFields = [] }) {
  return (
    <>
      <div className="flex-grow-1 px-4">
        {kitHistory.length === 0 ? (
          <div>
            <div className="d-flex w-100 h-100 justify-content-center align-items-center">
              <h4 className="text-secondary">No Kit History Yet</h4>
            </div>
          </div>
        ) : (
          kitHistory.map((history, idx) => {
            let actionText, actionBg;
            switch (history?.action) {
              case "UNASSIGN_PROPERTY_FROM_KIT":
                actionText = "Property Unassigned";
                actionBg = "bg-kit-grey";
                break;
              case "ASSIGN_PROPERTY_TO_KIT":
                actionText = "Property Assigned";
                actionBg = "bg-kit-green";
                break;
              case "UNASSIGN_USER_FROM_KIT":
                actionText = "User Unassigned";
                actionBg = "bg-kit-black";
                break;
              case "ASSIGN_USER_TO_KIT":
                actionText = "User Assigned";
                actionBg = "bg-kit-blue";
                break;
              default:
                actionText = "Unknown Action";
                actionBg = "bg-warning";
            }

            const duration = history?.lastPropertyAssignmentDuration;
            return (
              <div
                key={idx}
                className="d-flex flex-column mw-100 flex-grow-1 gap-3 py-2 border-bottom"
              >
                <div className="border-bottom-0 d-flex align-items-center justify-content-between">
                  <div>
                    {history.datetime
                      ? DateTime.fromMillis(history.datetime).toFormat(
                          "MMM d, yyyy h:mm a"
                        )
                      : "Date Error"}
                  </div>
                  <div
                    className={`rounded-5 d-flex justify-content-center text-nowrap py-1 px-2 ${actionBg}`}
                  >
                    {actionText}
                  </div>
                </div>

                {!hideFields.includes("user") && (
                  <div className="border-bottom-0 d-flex align-items-center">
                    {history?.actionByUser?.email}
                  </div>
                )}
                <div className="d-flex justify-content-between text-secondary">
                  <div className="border-bottom-0 d-flex">
                    {history?.message}
                  </div>
                  <div className="border-bottom-0 d-flex align-items-end">
                    <small className="text-center px-2 ">
                      {duration
                        ? `${
                            duration?.days > 0
                              ? `${Math.ceil(duration?.days)}d`
                              : ""
                          } ${
                            duration?.hours > 0
                              ? `${Math.ceil(duration?.hours)}h`
                              : ""
                          } ${
                            duration?.minutes > 0
                              ? `${Math.ceil(duration?.minutes)}min`
                              : ""
                          }`
                        : "-"}
                    </small>
                  </div>
                </div>
              </div>
            );
          })
        )}
      </div>
    </>
  );
}

export default MobileList;
