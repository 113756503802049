import { useState, useEffect } from 'react';

const useViewport = () => {
  const [viewportSize, setViewportSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    const handleResize = () => {
      setViewportSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    // Añadimos el event listener para escuchar los cambios de tamaño
    window.addEventListener('resize', handleResize);

    // Limpiamos el event listener cuando el componente se desmonta
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return viewportSize;
};

export default useViewport;
