import SidebarDisabled from "../../components/SidebarDisabled";
function Approval(){
    return(
        <SidebarDisabled>
            <div className="bg-image d-flex justify-content-center align-items-center flex-column">
                <div className="text-start">
                    <p className="mb-4 mt-4" style={{fontFamily: 'Poppins', color:'#434449', fontWeight:'600', fontSize: '38px', marginLeft:'150px'}}><strong>Welcome to Delet</strong></p>
                    <p className="mt-3" style={{fontFamily: 'Poppins', color:'#8E8E8E', fontWeight:'400', fontSize: '23px', marginLeft:'150px', width:'60%'}}>Your account is currently under review. We'll notify you once it's approved. If you have any questions, please contact info@delet.com.</p>
                </div>
            </div>
        </SidebarDisabled>
    )
}

export default Approval;