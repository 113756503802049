import "../Leads.scss";
import React, { useState } from "react";
import { Button, Col, Form, Modal, Row, Container } from "react-bootstrap";
import { CiFilter, CiCalendar } from "react-icons/ci";
import { DateRangeSelect } from "../../../components/Selects/DateRangeSelect";
import { MultiselectSearch } from "../../../components/Selects/MultiselectSearch";
import { useQuery } from "@tanstack/react-query";
import { getAllProperties } from "../../../api/properties";
import DatePicker from "react-datepicker";



export const FiltersModal = ({
  show,
  handleClose,
  handleApplyFilters,
}) => {

  const [selectedProperties, setSelectedProperties] = useState([])
  const [startDate, setStartDate] = useState()
  const [endDate, setEndDate] = useState()
  const [showDatePickerFrom, setShowDatePickerFrom] = useState(false);
  const [showDatePickerTo, setShowDatePickerTo] = useState(false);

  
  const {
    data: properties,
    isLoading,
    isError,
  } = useQuery({
    queryKey: ["all-my-properties"],
    queryFn: () => getAllProperties({ fields: "address shortAddress kit" }),
  });  

  return (
    <div className="filter-popup">
      <div className="d-flex flex-column">
        <label for="date-range" className="input-label">Date</label>
        <div className="d-flex gap-2">
          <div className="input-icon">
            <CiCalendar color="rgb(153, 153, 153)"/>
            <input 
              id="date-range" 
              className="date-inputs" 
              placeholder="From"
              onClick={() => {
                setShowDatePickerFrom(!showDatePickerFrom)
                setShowDatePickerTo(false)
              }}
              value={startDate?.toLocaleDateString()}
              ></input>
          </div>
          
          <div className="input-icon">
            <CiCalendar color="rgb(153, 153, 153)"/>
            <input 
              id="date-range" 
              className="date-inputs" 
              placeholder="To"
              onClick={() => {
                setShowDatePickerTo(!showDatePickerTo)
                setShowDatePickerFrom(false)
              }}
              value={endDate?.toLocaleDateString()}
            ></input>
          </div>
        </div>
      </div>
      <div className="d-flex flex-column">
        <label for="property" className="input-label">Property</label>
        <MultiselectSearch
            data={properties}
            selected={selectedProperties}
            handleSelectedProperties={(properties) => setSelectedProperties(properties)}
            disable={isLoading}
            onClick={() => {
              setShowDatePickerFrom(false);
              setShowDatePickerTo(false);
            }}
        />
      </div>
      <div className="d-flex flex-column justify-content-center align-items-center my-2">
        <button className="filter-button" onClick={() => handleApplyFilters({selectedProperties, startDate, endDate})}>
            Apply Filters
        </button>
        <button className="close-button" onClick={handleClose}>
          Close
        </button>
      </div>
      {showDatePickerFrom && (
            <div
              className="ms-3 datePickerWrapper"
            >
              <DatePicker
                onChange={(date) => {
                  setStartDate(date)
                  setShowDatePickerFrom(false)
                }}
                inline
                selected={startDate}
                timeFormat="p"
                timeIntervals={15}
                dateFormat="Pp"
                startDate={startDate}
                endDate={endDate}
                // filterDate={filterDates}
              />
            </div>
          )}
          {showDatePickerTo && (
            <div
              className="ms-3 datePickerWrapperTo"
            >
              <DatePicker
                onChange={(date) => {
                  setEndDate(date)
                  setShowDatePickerTo(false)
                }}
                inline
                selected={endDate}
                timeFormat="p"
                timeIntervals={15}
                dateFormat="Pp"
                startDate={startDate}
                endDate={endDate}
                minDate={startDate}
                // filterDate={filterDates}
              />
            </div>
          )}
    </div>
    
  );
};

// <Modal
    //   show={show}
    //   onHide={handleClose}
    //   size="lg"
    //   dialogClassName="modal-dialog"
    // >
    //   <Modal.Header>
    //     <Modal.Title className="d-flex align-items-center modal-title p-1 ">
    //       <CiFilter size="22px" /> <span>Filter</span>
    //     </Modal.Title>
    //   </Modal.Header>
    //   <Modal.Body>
    //     <Container>
         
    //       <Row>
    //         <Col >
    //           <Form.Label htmlFor="dateRangeSelect">Date</Form.Label>
    //           <Form.Control type="text" id="dateRangeSelect" placeholder="From"></Form.Control>
    //           <Form.Control type="text" id="dateRangeSelect" placeholder="To"></Form.Control>
              
    //           <DateRangeSelect
    //             startDate={startDate}
    //             endDate={endDate}
    //             handleDateRangeChange={handleDateRangeChange}
    //           />
    //         </Col>
    //         <Col>
    //           <Form.Label htmlFor="propertySelect">Property</Form.Label>
              
    //           <MultiselectSearch
    //             data={properties}
    //             selected={selectedProperties}
    //             handleSelectedProperties={(properties) => setSelectedProperties(properties)}
    //             disable={isLoading}
    //           />
    //         </Col>
    //       </Row>
    //     </Container>
    //   </Modal.Body>
    //   <Modal.Footer>
    //     <Button variant="secondary" onClick={handleClose}>
    //       Close
    //     </Button>
    //     <Button variant="primary" onClick={() => handleApplyFilters({selectedProperties, startDate, endDate})}>
    //       Apply
    //     </Button>
    //   </Modal.Footer>
    // </Modal>
