import React from 'react';
import { Button, Container, Modal, Row, Form } from 'react-bootstrap';
import { CiLocationOn } from "react-icons/ci";
import { IoMdClose, IoMdPerson, IoMdCheckmark  } from "react-icons/io";
import { Card } from '../../designSystem/Card';
import style from "./style.module.scss";
import { AppButton } from '../../designSystem/AppButton';
import CopyLink from '../../pages/Properties/components/CopyLink';
import { Auth } from 'aws-amplify';
import { useState } from 'react';
import copy from "copy-to-clipboard";
import { useGeneral } from '../../context/GeneralContext';
import { useProperty } from '../../context/PropertyContext';
import ReactGA from "react-ga4";
import { display } from '@mui/system';
import { MdEmail, MdLocalPhone } from "react-icons/md";
import { RiErrorWarningFill } from "react-icons/ri";
import { IoIosCheckmarkCircle } from "react-icons/io";



let LINKTOSHARE = '';
const FormModal = (props) => {

    const { show, description, onHide, isVerified, isSubmitted, setIsVerified, setIsSubmitted, formData, setFormData, emailError, setEmailError, phoneError, setPhoneError, elProperty = null } = props;
    const { property, setProperty } = useProperty();
    const { setAlertModal } = useGeneral()
    const [pType, setPType] = useState(property.classification);
    const [isCopied, setIsCopied] = useState(false)
  
    const reportShareLink = (category, label) => {
        ReactGA.event({
          action: "share_link",
          category: category,
          label: label,
        });
      };

    const removeCopyBanner = () => {
        setIsCopied(false)
    }
    const shareProperty = () => {
        if (!property._id) {
            
            Auth.currentAuthenticatedUser().then((userInfo) => {
                const customId = userInfo.attributes["custom:_id"];
                const link = `${process.env.REACT_APP_URL}/book/property/${elProperty._id}/${customId}`;
                copy(link);
                reportShareLink("property/list", elProperty.address);
                setIsCopied(true)
                setTimeout(removeCopyBanner, 1500)
        })
    } else {
        Auth.currentAuthenticatedUser().then((userInfo) => {
          const customId = userInfo.attributes["custom:_id"];
          const link = `${process.env.REACT_APP_URL}/book/property/${property._id}/${customId}`;
          copy(link);
          reportShareLink("property/list", property.address);
          setIsCopied(true)
          setTimeout(removeCopyBanner, 1500)
        
        });
    }
      };
    const handleChange = (e) =>{
        const { name, value } = e.target;
        setFormData((prevData) => ({...prevData, [name]: value }))
    }

    const handleVerify = (e) =>{
        e.preventDefault();
        let isValid = true;
        if(formData.email !== ''){
            if (!validateEmail(formData.email)) {
                setEmailError('Incomplete or Invalid email address');
                isValid = false;
            } else {
                setEmailError('');
            }
        }

        if(formData.phoneNumber !== '') {
            if (!validatePhone(formData.phoneNumber)) {
                setPhoneError('Incomplete or Invalid phone number');
                isValid = false;
            } else {
                setPhoneError('');
            }
        }
        if(isValid){
            setIsVerified(true);
        }

    }
    const handleFinalSubmit = async () => {
        try {
            const userInfo = await Auth.currentAuthenticatedUser();
            const customId = userInfo.attributes["custom:_id"];
            const LINKTOSHARE = `${process.env.REACT_APP_URL}/book/property/${property._id}/${customId}`;
            if(formData.email !== ''){
                const emailResponse = await fetch(`${process.env.REACT_APP_NODE_API_URL}/api/linkNotification/sendPropertyEmail`, {
                    // mode: 'no-cors',
                    method: 'POST',
                    body: JSON.stringify({
                        to: formData.email,
                        fullName: formData.fullName,
                        link: LINKTOSHARE,
                        propertyAddress: elProperty === null ? `${property.shortAddress}${property.unit ? ` Unit ${property.unit}` : ''}, ${property.city}, ${property.state} ${property.zipCode}` 
                        : `${elProperty.shortAddress}${elProperty.unit ? ` Unit ${elProperty.unit}` : ''}, ${elProperty.city}, ${elProperty.state} ${elProperty.zipCode}` 
                    }),
                    headers: {
                        "Content-type": "application/json"
                    },
                });
        
                if (!emailResponse.ok) {
                    throw new Error('Network response was not ok.');
                } else {
                    setIsSubmitted(true)
                }
            }

            if(formData.phoneNumber !== ''){
                const textResponse = await fetch(`${process.env.REACT_APP_NODE_API_URL}/api/linkNotification/sendPropertyText`, {
                    method: 'POST',
                    body: JSON.stringify({
                        to: formData.phoneNumber,
                        fullName: formData.fullName,
                        link: LINKTOSHARE,
                        propertyAddress: elProperty === null ? `${property.shortAddress}${property.unit ? ` Unit ${property.unit}` : ''}, ${property.city}, ${property.state} ${property.zipCode}` 
                        : `${elProperty.shortAddress}${elProperty.unit ? ` Unit ${elProperty.unit}` : ''}, ${elProperty.city}, ${elProperty.state} ${elProperty.zipCode}` 
                    }),
                    headers: {
                        "Content-type": "application/json"
                    },
                });
        
                if (!textResponse.ok) {
                    throw new Error('Network response was not ok.');
                } else {
                    setIsSubmitted(true)
                }
            } 
        } catch (error) {
            console.error('Error during final submit:', error);
        }
    }

    const handleNextDisabled = () =>{
        if ( formData.fullName === '') return true;
        if ( formData.email === '' && formData.phoneNumber === '') return true;

        return false;
    }

    const validateEmail = (email) => {
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return regex.test(email);
    };

    const validatePhone = (phone) => {
        return /^\d{10}$/.test(phone);
    };

    const onPrev = () => {
        setIsVerified(false)
    }
    return (
        
        <Modal 
            show={show}
            onHide={onHide}
            centered
            className={style.container}
        >
            {isCopied ? 
                <div className={style.copied}>
                    <p> <IoIosCheckmarkCircle style={{color: '#00BA08'}} size={14}/> Property Link Copied Successfully</p> 
                </div>
                : <></>}
            <button className='d-flex justify-content-end me-4 mt-5 border-0 bg-transparent' onClick={()=> onHide()}><IoMdClose color='#979797' size={20}/></button>
            <div className='d-flex flex-column justify-content-center align-items-center'>
                {isVerified && isSubmitted ?
                    <>
                        <h4 style={{fontFamily:'Poppins', color:'#5271ff', marginBottom:'10px', fontWeight: 'bold'}}>Property Link Sent</h4>
                        <p style={{fontFamily:'Poppins', color:'#979797', fontSize:'small'}}>We've sent the property link to your client</p>  
                    </>
                    : (isVerified ? 
                        <>
                            <h4 style={{fontFamily:'Poppins', color:'#5271ff', marginBottom:'10px', fontWeight: 'bold'}}>Share Property Link</h4>
                            <p style={{fontFamily:'Poppins', color:'#979797', fontSize:'small'}}>Please check your client's information</p> 
                        </>
                        : <>
                            <h4 style={{fontFamily:'Poppins', color:'#5271ff', marginBottom:'10px', fontWeight: 'bold'}}>Share Property Link</h4>
                            <p style={{fontFamily:'Poppins', color:'#979797', fontSize:'small', padding:"0px 20px"}}>Fill in your client's details, and we'll send them the property link</p> 
                        </>
                    )
                }
            </div>

            

        <Card className={style.card}>
            {!isVerified ? 
            <>
            <div className={style.inputContainerLoc}>
                <p className={style.iconLoc}><CiLocationOn size={18}/> Location</p>
                <input
                className={style.input}
                onChange={handleChange}
                value={elProperty === null ? `${property.shortAddress}${property.unit ? ` Unit ${property.unit}` : ''}, ${property.city}, ${property.state} ${property.zipCode}` 
                    : `${elProperty.shortAddress}${elProperty.unit ? ` Unit ${elProperty.unit}` : ''}, ${elProperty.city}, ${elProperty.state} ${elProperty.zipCode}` 
                }
                name="location"
                type="text"
                readOnly
                />
            </div>
            <div className={style.inputContainer}>
                <p className={style.icon}><IoMdPerson size={18}/> Full Name</p>
                <input
                className={style.input}
                onChange={handleChange}
                value={formData.fullName}
                name="fullName"
                type="text"
                />
            </div>
            <div className={style.inputContainer}>
                {!emailError ? 
                <>
                    <p className={style.icon}><MdEmail size={18}/> Email</p>
                    <input
                    className={style.input}
                    onChange={handleChange}
                    value={formData.email}
                    name="email"
                    type="email"
                    />
                </> :
                <>
                    <p className={style.iconError}><MdEmail size={18}/> Email</p>
                        <input
                        className={style.inputError}
                        onChange={handleChange}
                        value={formData.email}
                        name="email"
                        type="email"
                        />
                    <div className={style.error}><RiErrorWarningFill size={15} color='red' style={{marginRight: '5px'}}/> {emailError}</div>
                </>
                }
            </div>
            <div className={style.inputContainer}>
                {!phoneError ? <>
                    <p className={style.icon}><MdLocalPhone size={18}/> Phone</p>
                    <input
                    className={style.input}
                    onChange={handleChange}
                    value={formData.phoneNumber}
                    name="phoneNumber"
                    type="number"
                    pattern="\d{10}"
                    />
                </> :
                    <>
                        <p className={style.iconError}><MdLocalPhone size={18}/> Phone</p>
                        <input
                        className={style.input}
                        onChange={handleChange}
                        value={formData.phoneNumber}
                        name="phoneNumber"
                        type="number"
                        pattern="\d{10}"
                        />
                        <div className={style.error}><RiErrorWarningFill size={15} color='red' style={{marginRight: '5px'}}/> {phoneError}</div>
                    </>
                }
                    
            </div> 
            </>
            : 
            <>
            <table>
                <tbody>
                    <tr className={style.rows}>
                        <td className={style.leftTd}><CiLocationOn size={18}/> Location</td>
                        <td className={style.rightTd}>{elProperty === null ? `${property.shortAddress}${property.unit ? ` Unit ${property.unit}` : ''}, ${property.city}, ${property.state} ${property.zipCode}` 
                    : `${elProperty.shortAddress}${elProperty.unit ? ` Unit ${elProperty.unit}` : ''}, ${elProperty.city}, ${elProperty.state} ${elProperty.zipCode}` 
                }</td>
                    </tr>
                    <tr className={style.rows}>
                        <td className={style.leftTd}><IoMdPerson size={18}/> Full Name</td>
                        <td className={style.rightTd}>{formData.fullName}</td>
                    </tr>
                    <tr className={style.rows}>
                        <td className={style.leftTd}><MdEmail size={18}/> Email</td>
                        <td className={style.rightTd}>{formData.email !== '' ? formData.email : 'NA'}</td>
                    </tr>
                    <tr className={style.bottomRow}>
                        <td className={style.leftTd}><MdLocalPhone size={18}/> Phone Number</td>
                        <td className={style.rightTd}>{formData.phoneNumber !== '' ? formData.phoneNumber : 'NA' }</td>
                    </tr>
                </tbody>
            </table>
            </>
            }
        </Card>

        {isVerified && isSubmitted && formData.email !== '' && formData.phoneNumber !== ''? 
            <>
            <div className='d-flex flex-column justify-content-center align-items-center pb-5'>
                <p style={{fontFamily:'Poppins', fontSize:'small'}}><IoMdCheckmark color='#5271ff'/>  Sent to {formData.phoneNumber}</p>
                <p style={{fontFamily:'Poppins', fontSize:'small'}}><IoMdCheckmark color='#5271ff'/>  Sent to {formData.email}</p>

            </div>
            </>
            : (isVerified && isSubmitted && formData.email === '' && formData.phoneNumber !== ''? 
            <>
            <div className='d-flex flex-column justify-content-center align-items-center pb-5'>
                <p style={{fontFamily:'Poppins', fontSize:'small'}}><IoMdCheckmark color='#5271ff'/>  Sent to {formData.phoneNumber}</p>
            </div>
            </>
            : (isVerified && isSubmitted && formData.email !== '' && formData.phoneNumber === ''? 
                <>
            <div className='d-flex flex-column justify-content-center align-items-center pb-5'>
                <p style={{fontFamily:'Poppins', fontSize:'small'}}><IoMdCheckmark color='#5271ff'/>  Sent to {formData.email}</p>

            </div>
            </>
        
            : (isVerified ? <>
                                <AppButton
                                    as='button'
                                    className={style.button}
                                    style={{backgroundColor: 'black'}}
                                    onClick={handleFinalSubmit}
                                    >
                                    {'Share Property Link >'}
                                </AppButton>
                                <AppButton
                                    className={style.button}
                                    as='button'
                                    onClick={onPrev}
                                    hierarchy='secondary'
                                    type='button'
                                >
                                    {'< Go Back'}
                                </AppButton>
                                <div className='d-flex justify-content-center align-items-center'>
                                    <button className="mb-5 border-0 bg-transparent"onClick={shareProperty}>Copy Property Link</button>
                                </div>
                        </>
                            : <>
                            
                                <AppButton
                                    as='button'
                                    onClick={handleVerify}
                                    className={style.button}
                                    disabled={handleNextDisabled()}
                                >
                                    {'Continue >'}
                                </AppButton> 
                                <AppButton
                                    className={style.button}
                                    style={{border: 'none', marginBottom: '30px'}}
                                    as='button'
                                    hierarchy='secondary'
                                    type='button'
                                    disabled
                                >
                                    {'< Go Back'}
                                </AppButton>
                                <div className='d-flex justify-content-center align-items-center'>
                                    {/* <button className="mb-5 border-0 bg-transparent"onClick={shareProperty}>Copy Property Link</button> */}
                                </div>
                            </>
        )))
    }
        </Modal>
    )
}

export default FormModal;