import React, { useEffect, useMemo, useRef, useState } from "react";
import CountdownTimer from "./CountdownTimer";
import InstructionsCard from "./InstructionsCard";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { getPublicLockStatus, updatePublicLockStatus } from "../../../api/lock";
import lockIcon from "../../../assests/images/lock-ico-fill.svg";
import unlockedIcon from "../../../assests/images/unlock-ico-fill.svg";
import infoIcon from "../../../assests/images/info.svg";
import style from "./style.module.scss";
import { AppButton } from "../../../designSystem/AppButton";
import { IoIosArrowBack } from "react-icons/io";
import AccessInstructions from "./AccessInstructions";
import { Modal } from "react-bootstrap";
import { StyledTextarea } from "../../../components/InputTextAreaComponent";

function ShowInstructionsCard({ booking, token }) {
  const queryClient = useQueryClient();
  const { property, startTime, passCode } = booking;
  const [timeRemaining, setTimeRemaining] = useState("");
  const [isActive, setIsActive] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [isManualAccess, setIsManualAccess] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showExpiredModal, setShowExpiredModal] = useState(false);

  const intervalIdRef = useRef(null);

  const start = useMemo(() => new Date(startTime * 1000 - 900000), [startTime]);
  const end = useMemo(() => new Date(startTime * 1000 + 900000), [startTime]);
  const { propertyAccessCodes, doorUnlockLink, rentalApplicationForm } =
    property;

  const {
    data: locked,
    isLoading: loadingStatus,
    isError,
  } = useQuery({
    queryKey: ["public", "lock", booking._id],
    queryFn: () => getPublicLockStatus(token, property.kit),
  });

  const { mutateAsync: updateLockStatus, isPending: updatingStatus } =
    useMutation({
      mutationFn: () =>
        updatePublicLockStatus(token, property.kit, locked ? "unlock" : "lock"),
      onSuccess: (data) => {
        queryClient.setQueryData(["public", "lock", booking._id], () => data);
      },
    });

  const handleLock = async () => {
    await updateLockStatus();

    setIsButtonDisabled(true);
    setTimeout(() => {
      setIsButtonDisabled(false);
    }, 1000 * 120);
  };

  const calculateTimeRemaining = () => {
    const now = new Date();
    const diff = end - now;

    if (diff <= 0) {
      setTimeRemaining("00:00");
      setIsButtonDisabled(true);
      stopTimer();
      return;
    }

    const minutes = Math.floor(diff / 1000 / 60);
    const seconds = Math.floor((diff / 1000) % 60);
    setTimeRemaining(
      `${String(minutes).padStart(2, "0")}:${String(seconds).padStart(2, "0")}`
    );
  };

  const startTimer = () => {
    const now = new Date();
    const activePeriod = {
      start: new Date(startTime * 1000 - 7200000),
      end: new Date(startTime * 1000 + 7200000),
    };
    let intervalId;

    if (now >= activePeriod.start && now < activePeriod.end) {
      if (now >= start) {
        calculateTimeRemaining();
        intervalId = setInterval(calculateTimeRemaining, 1000);
      } else {
        setTimeRemaining(
          `The unlock button will be active at ${start.toLocaleTimeString(
            undefined,
            {
              hour: "2-digit",
              minute: "2-digit",
              hour12: true,
            }
          )}`
        );
      }
      setIsActive(true);
      return;
    }

    if (now > activePeriod.end) {
      setTimeRemaining("The link expired");
      setIsActive(false);
      return;
    }

    return () => clearInterval(intervalId);
  };
  const stopTimer = () => {
    if (intervalIdRef.current) {
      clearInterval(intervalIdRef.current);
      intervalIdRef.current = null;
    }
  };

  const handleReload = () => {
    stopTimer();
    startTimer();
  };
  const handleManualAccess = () => {
    setIsManualAccess(true);
  };
  const backToInstruct = () => {
    setIsManualAccess(false);
  };

  useEffect(() => {
    startTimer();
    return () => stopTimer();
  }, [startTime]);

  return (
    <div>
      {!isManualAccess ? (
        <div>
          <h4 className={style.headerTitle}>
            Everything is ready for your Tour
          </h4>
          <p className={style.headerSubtitle}>Here are the instructions</p>
          <CountdownTimer
            timeRemaining={timeRemaining}
            isActive={isActive}
            reload={handleReload}
            showButton={property.doorUnlockLink}
          >
            {isActive && (
              <InstructionsCard
                property={property}
                start={start}
                locked={locked}
                handleLock={handleLock}
                end={end}
                startTime={startTime}
                isButtonDisabled={isButtonDisabled}
                loading={updatingStatus || loadingStatus}
                timeRemaining={timeRemaining}
                passCode={passCode}
              />
            )}
            {passCode && (
              // <span className="text-uppercase bg-black text-white fw-bold ps-2 py-1 rounded">passCode: {passCode}</span>
              <div className="d-flex flex-column justify-content-center align-items-center">
                <table>
                  <tbody>
                    <tr className={style.rows}>
                      <td className={style.leftTdShow}>
                        <strong>YOUR PASSCODE</strong>
                      </td>
                      <td className={style.rightTdShow}>
                        Available for 30 min
                      </td>
                    </tr>
                  </tbody>
                </table>
                <p className={style.passCode}>{passCode}</p>
              </div>
            )}

            {propertyAccessCodes?.enable && (
              <div className="d-flex flex-column justify-content-center align-items-center">
                <table>
                  <tbody>
                    <tr className={style.rows}>
                      <td className={style.leftTdShow}>
                        <strong>ACCESS INSTRUCTIONS</strong>
                      </td>
                      {!propertyAccessCodes.instructions ? (
                        <td
                          className={style.rightTdShow}
                          style={{ paddingRight: "125px" }}
                        ></td>
                      ) : (
                        <div className="d-flex">
                          <td className={style.rightTdShowInst}>
                            {propertyAccessCodes.instructions}{" "}
                          </td>
                          <button
                            className={style.asText}
                            onClick={() => setShowModal(true)}
                          >
                            <u>Read More</u>
                          </button>
                        </div>
                      )}
                    </tr>
                  </tbody>
                </table>

                {propertyAccessCodes.buildingCode && (
                  <table>
                    <tbody>
                      <tr className={style.bottomRow}>
                        <td className={style.leftTdShowCode}>
                          Building Access Code
                        </td>
                        <td className={style.rightTdShowCode}>
                          {" "}
                          {propertyAccessCodes.buildingCode}{" "}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                )}
                {propertyAccessCodes.elevatorCode && (
                  <table>
                    <tbody>
                      <tr className={style.bottomRow}>
                        <td className={style.leftTdShowCode}>
                          Elevator Access Code
                        </td>
                        <td className={style.rightTdShowCode}>
                          {" "}
                          {propertyAccessCodes.buildingCode}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                )}
              </div>
            )}

            {doorUnlockLink && timeRemaining !== "00:00" ? (
              <div className={`${style.buttons} ${style.container}`}>
                <AppButton
                  className={style.button}
                  onClick={handleLock}
                  disabled={isButtonDisabled || updatingStatus || loadingStatus}
                  as="button"
                  type="button"
                >
                  {updatingStatus || loadingStatus ? (
                    <strong>Loading... </strong>
                  ) : (
                    <>
                      {`Unlock Door `}{" "}
                      <p className={style.timeShow}>{`${timeRemaining}`}</p>{" "}
                      {`>`}
                    </>
                  )}
                </AppButton>
                <AppButton
                  className={style.button}
                  style={{ backgroundColor: "#4C4D52", color: "#FFFFFF" }}
                  onClick={handleManualAccess}
                  // disabled={isButtonDisabled || updatingStatus || loadingStatus}
                  as="button"
                  type="button"
                  hierarchy="secondary"
                >
                  {"Manual Access >"}
                </AppButton>
              </div>
            ) : (
              <div className={`${style.buttons} ${style.container}`}>
                <AppButton
                  className={style.button}
                  style={{ backgroundColor: "#4C4D52", color: "#FFFFFF" }}
                  onClick={handleManualAccess}
                  // disabled={isButtonDisabled || updatingStatus || loadingStatus}
                  as="button"
                  type="button"
                  hierarchy="secondary"
                >
                  {"Manual Access >"}
                </AppButton>
              </div>
            )}

            <p className={style.helpInfo}>
              <img src={infoIcon} alt="info" />
              If you're having difficulty entering the unit, you can reach us at
              310-926-7313
            </p>
          </CountdownTimer>

          <Modal
            dialogClassName={`modal-dialog modal-dialog-centered ${style.customModal}`}
            show={showModal}
            onHide={() => setShowModal(false)}
          >
            <Modal.Header closeButton>
              <Modal.Title>Full Access Instructions</Modal.Title>
            </Modal.Header>
            <Modal.Body className="">
              <textarea
                className="border-0 bg-white w-100"
                id="instructions"
                name="instructions"
                value={propertyAccessCodes.instructions}
                disabled
                style={{ minHeight: "300px"}}
              />
            </Modal.Body>
          </Modal>
        </div>
      ) : (
        <div>
          <p className={style.backArrow}>
            <IoIosArrowBack size={20} onClick={backToInstruct} />
          </p>
          <h4 className={style.headerTitle}>Manual Access</h4>
          <p className={style.headerSubtitle}>
            Follow these steps and secure the door
          </p>
          {passCode && (
            // <span className="text-uppercase bg-black text-white fw-bold ps-2 py-1 rounded">passCode: {passCode}</span>
            <div className="d-flex flex-column justify-content-center align-items-center">
              <table>
                <tbody>
                  <tr className={style.rows}>
                    <td className={style.leftTdShow}>
                      <strong>YOUR PASSCODE</strong>
                    </td>
                    <td className={style.rightTdShow}>Available for 30 min</td>
                  </tr>
                </tbody>
              </table>
              <p className={style.passCode}>{passCode}</p>
            </div>
          )}
          <AccessInstructions />

          <p className={style.helpInfo}>
            <img src={infoIcon} alt="info" />
            If you're having difficulty entering the unit, you can reach us at
            310-926-7313
          </p>
        </div>
      )}
    </div>
  );
}

export default ShowInstructionsCard;
