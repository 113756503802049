import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { BiSolidDoorOpen } from "react-icons/bi";
import { FaQuestion } from "react-icons/fa";
import { MdSensorDoor } from "react-icons/md";
import { TbDoorOff } from "react-icons/tb";

const DoorStatus = ({ status, bridgeStatus }) => {
  return (
    <div
      className={`rounded-circle align-items-center justify-content-center p-3 ${
        status === "open"
          ? "bg-delet-secondary"
          : status === "closed"
          ? "bg-delet-secondary"
          : "bg-delet-secondary"
      }`}
      disabled={bridgeStatus === "offline"}
    >
      {bridgeStatus === "online" ? (
        status === "open" ? (
          <BiSolidDoorOpen color="#FF0000" size={25} />
        ) : status === "closed" ? (
          <MdSensorDoor color="white" size={25} />
        ) : status === "unknown" ? (
          <FaQuestion color="white" size={20} />
        ) : (
          <TbDoorOff color="white" size={25} />
        )
      ) : (
        <TbDoorOff color="white" size={25} />
      )}
    </div>
  );
};

export default DoorStatus;
