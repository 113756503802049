import React from "react";
import { CiBatteryFull, CiBatteryEmpty } from "react-icons/ci";

const MobileBatteryStatus = ({ level, bridgeStatus }) => {
  let batteryIcon;

  // Determine the battery icon based on the level
  if (level > 80) {
    batteryIcon = <CiBatteryFull size={25} color="white" />;
  } else if (level > 20) {
    batteryIcon = <CiBatteryFull size={25} color="white" />;
  } else {
    batteryIcon = <CiBatteryEmpty size={25} color="white" />;
  }

  return (
    <div
      style={{ width: "41px", height: "41px", position: "relative" }}
      className="rounded-circle align-items-center justify-content-center p-2 bg-delet-secondary"
      disabled={bridgeStatus === "offline"}
    >
      {batteryIcon}
      <span
        style={{
          width: `${
            bridgeStatus === "offline" ? "14px" : level > 80 ? "14px" : "12px"
          }`,
          height: "10px",
          position: "absolute",
          top: "55%",
          left: `${
            bridgeStatus === "offline" ? "46%" : level > 80 ? "46%" : "43%"
          }`,
          transform: "translate(-50%, -50%)",
          background: `${
            bridgeStatus === "offline"
              ? "#696666"
              : level > 20
              ? "#696666"
              : "#FF0000"
          }`,
        }}
      ></span>
      <span
        style={{
          position: "absolute",
          top: "55%",
          left: "47%",
          transform: "translate(-50%, -50%)",
          fontSize: "7px",
          color: "white", // Change this color if needed
        }}
      >
        {bridgeStatus === "offline" ? 100 : level}
      </span>
    </div>
  );
};

export default MobileBatteryStatus;
