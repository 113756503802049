import React, { useEffect, useMemo, useRef, useState } from 'react';
import style from './style.module.scss';
import unlock from '../../../assests/images/doorknob-unlock.png'
import keypad from '../../../assests/images/keypad.png'
import kpOpen from '../../../assests/images/kp-open.png'
import kpClose from '../../../assests/images/kp-lock.png'

function AccessInstructions(){
    return (
        <div className={style.stepContainer}>
            <div>
                <div className={style.circleFrame}>
                    <img src={unlock}></img>
                </div>
                <span className={`${style.circle}`}>1</span>
                <p className={style.stepGuide}>Pull the door towards you.</p>
            </div>
            <div>
                <div className={style.circleFrame}>
                <div className={style.smallCircleFrame}>
                    <img src={kpOpen}></img>
                </div>
                    <img src={keypad}></img>
                </div>
                
                <span className={`${style.circle}`}>2</span>
                <p className={style.stepGuide}>Enter the code on the keypad and press the Check Button. Wait for a beep, then open the door.</p>
            </div>
            <div>
                <div className={style.circleFrame}>
                <div className={style.smallLeftFrame}>
                    <img src={kpClose}></img>
                </div>
                    <img src={keypad}></img>
                </div>
                <span className={`${style.circle}`}>3</span>
                <p className={style.stepGuide}>When leaving, make sure to close the door securely and press the Lock Button.</p>
            </div>
        </div>
    )
}

export default AccessInstructions;