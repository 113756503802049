import propertyDummyImage from '../../../../../assests/images/6-thumb.png';
import style from './style.module.scss';
import { Card } from '../../../../../designSystem/Card';
import { HiInformationCircle } from "react-icons/hi";
import { CardTitle } from 'react-bootstrap';
import { useState } from 'react';

function PropertyCard({ property, onChange, selectedId, onSeeDetails, checked }) {
  const { address, shortAddress, images, city, state, zipCode, unit } = property;


  return (
    <div className={style.container}>

      <Card
          active={selectedId === property._id}
          interactive={true}
          className={style.card}
      >
        <table className={style.tableCard}>
          <tbody>
            
          <tr className={style.row}>
            <td className={style.tableData}>

            <input 
              type='checkbox'
              onChange={onChange}
              // onChange={handleChecked} 
              name='propertyId'
              id={property._id} 
              value={property._id}
              checked={selectedId === property._id}
              // className='d-none'
              className={style.checkInput}
              // style={{height: '18px', width: '18px'}}
              >
            </input>
              {checked && selectedId === property._id ? <label className={style.clickedLabel}><strong>Selected</strong></label> : <label className={style.normalLabel}>Select</label>}
              
            </td>

        {/* <Card.Link
            as='button '
            // style={{ marginInline: 'auto' }}
            onClick={() => onSeeDetails(property)}
          >
            <HiInformationCircle />
          </Card.Link> */}
          <td className={style.tableData}>

          {/* <button className={style.iconButton} onClick={()=> onSeeDetails(property)}>
            <HiInformationCircle className={style.icon}/>
          </button> */}
          </td>
          </tr>
          </tbody>
        </table>
        
        <label
          className='d-flex flex-column gap-2 pb-2 h-100'
          htmlFor={property._id}
        >
          {/* <input
            onChange={onChange}
            name='propertyId'
            id={property._id}
            type='radio'
            value={property._id}
            checked={selectedId === property._id}
            className='d-none'
          /> */}
           <Card.Title
            title={shortAddress}
            className={style.title}
          >
            <button style={{border: 'none', background: 'none', color: '#979797'}} /*onClick={()=>onSeeDetails(property)}*/>{`${shortAddress}${unit ? ` Unit ${unit}` : ''}`}</button>
          </Card.Title>
          <Card.SubTitle
            title={address}
            className={style.subtitle}
          >
            {city}, {state} {zipCode}
          </Card.SubTitle>
          <Card.Image
            className={style.propImage}
            src={images?.[0] ?? propertyDummyImage}
            alt='property'
          />
         
          
        </label>
      </Card>
    </div>
  );
}

export default PropertyCard;
