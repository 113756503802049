import React, { useMemo, useState } from "react";
import Sidebar from "../../components/SideBar";
import { Stepper, MultiSelect, createTheme } from "@mantine/core";
import { AppButton } from "../../designSystem/AppButton";
import { Card } from "../../designSystem/Card";
import { Modal, ModalFooter } from "react-bootstrap";
import style from './style.module.scss';
import stepper from './stepper.module.scss'
import multiselect from './multiselect.module.scss'
import './react-datepicker.scss';
import { RiErrorWarningFill } from "react-icons/ri";
import { HiInformationCircle } from "react-icons/hi";
import { RiPencilLine } from "react-icons/ri";
import { MdClose } from "react-icons/md";
import { useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import DatePicker from 'react-datepicker';
import { useNavigate } from "react-router-dom";
import axios from "axios";
import UseHttp from "../../hooks/UseHttp";
import { Auth } from "aws-amplify";

const Installation = () => {
  const [active, setActive] = useState(0);
  const [phoneError, setPhoneError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [formData, setFormData] = useState({property: '', fullName: '', email: '', phoneNumber: '', company: '', date: '', time: '', message: '', cost: 50})
  const [selectedDate, setSelectedDate] = useState(new Date())
  const [showDatePicker, setShowDatePicker] = useState(false)
  const [isTime, setIsTime] = useState(true)

  const nextStep = () =>{

    setActive((current) => (current < 2 ? current + 1 : current));
    if(active === 1 && formData.date === ''){
      setFormData((prev) => ({...prev, date: 'No preferred date'}))
    }
    if(active === 1 && formData.time === ''){
      setFormData((prev) => ({...prev, time: 'No preferred time'}))
      setIsTime(false)
    }
  } 
  const prevStep = () => setActive((current) => (current > 0 ? current - 1 : current));
  const toStep1 = () => setActive((current) => (current === 2 ? current - 2: current));

  const { isLoading, error, requestData: createOrder } = UseHttp()
  
  const navigate = useNavigate();

  const { id } = useParams()
  const kits = id.split(',')

  const {
    data: kit,
    isError
  } = useQuery({
    queryKey: [kits],
    queryFn: async (id) => {
      try{
        const token = await Auth.currentSession();
        const res = await axios.get(`${process.env.REACT_APP_NODE_API_URL}/api/kits/${id.queryKey[0][0]}`, {
        headers: {
          Authorization: `Bearer ${token.getIdToken().getJwtToken()}`,
          "Content-Type": "application/json",
        },
      })
        return res.data
      } catch {
        // console.log(error)
        // throw new Error(error)
      }
    }
  })

  //for now, eventually a random order ID will be generated through a new order on the database
  const uniqueId = () => {
    const randomID = Math.floor(Math.random() * 89999999 + 100000);
    return randomID;
  };
 
  const handleCost = () => {
    if (kits.length === 1) return;
    let newCost = kits.length * 50;
    setFormData((prevData) => ({...prevData, cost: newCost}))
  }

  const handleDateData = (date) => {
    setFormData((prevData) => ({...prevData, date: date}))
  }

  const handleServiceData = (time) => {
    setFormData((prevData) => ({...prevData, time: time}))
  }

  const handleChange = (e) =>{
    const { name, value } = e.target;

    setFormData((prevData) => ({...prevData, [name]: value }))
    // setShowDatePicker(false)
  }

  const handleVerify = (e) =>{
    e.preventDefault();
    handleCost()
    let isValid = true;
    if(formData.email !== ''){
        if (!validateEmail(formData.email)) {
            setEmailError('Incomplete or Invalid email address');
            isValid = false;
        } else {
            setEmailError('');
        }
    }

    if(formData.phoneNumber !== '') {
        if (!validatePhone(formData.phoneNumber)) {
            setPhoneError('Incomplete or Invalid phone number');
            isValid = false;
        } else {
            setPhoneError('');
        }
    }
    if(isValid){
      setActive((current) => (current < 2 ? current + 1 : current));
    }

}

  const handleNextDisabled = () =>{
    if ( formData.fullName === '' || formData.email === '' || formData.phoneNumber === '' || formData.property === '') return true;
    return false;
  }

      const validateEmail = (email) => {
          const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
          return regex.test(email);
      };

      const validatePhone = (phone) => {
          return /^\d{10}$/.test(phone);
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
    handleDateData(date.toLocaleDateString('en-US', options))
    setShowDatePicker(false);
  };

  const handleRemoveDate = () => {
    setFormData((prevData) => ({...prevData, date: ''}))
  }
  const handleServiceChange = (time) => {
      setIsTime(true)
      handleServiceData(time)
  }

  const options = {
    year: "numeric",
    month: "long",
    day: "numeric",
  };

  const handleFinalSubmit = async () => {
    const data = {
        notes: formData.message,
        details:[
          {
             name: 'Installation',
             serviceType: "installation",
             amount: kits.length,
             notes: formData.message,
             price: formData.cost,
             priceId: "price_1P9rCEFIjHkIJIZyIxdrw4Z2",
             individualPrice: 50,
         },
        ],
        paymentMethod: 'credit card',
        acceptTerms: true,
        finalPrice: formData.cost,
        subscriptionPrice: formData.cost,
        discountCode: '',
    };

    try {
        const token = await Auth.currentSession();
        const response = await createOrder({
            method: "POST",
            url: `${process.env.REACT_APP_NODE_API_URL}/api/order`,
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token.getIdToken().getJwtToken()}`,
            },
            body: data,
        });
            const uniqueIdNum = uniqueId();
            const emailResponse = await fetch(`${process.env.REACT_APP_NODE_API_URL}/api/installationRemoval/sendInstallOrder`, {
                method: 'POST',
                body: JSON.stringify({
                    email: formData.email,
                    fullName: formData.fullName,
                    propertyAddress: formData.property,
                    time: formData.time,
                    date: formData.date,
                    message: formData.message,
                    cost: formData.cost,
                    accountEmail: kit?.user.email,
                    phoneNumber: formData.phoneNumber,
                    orderID: uniqueIdNum,
                }),
                headers: {
                    "Content-Type": "application/json",
                },
            });

            if (!emailResponse.ok) {
                throw new Error('Failed to send email notification.');
            }

            navigate("/order/success");
    } catch (error) {
        console.error('An error occurred:', error);
    }
};


    return(
        <Sidebar>
            <section>
                <header className="d-flex justify-content-between align-items-center" style={{backgroundColor: '#EAEAEB', height: '126px'}}>
                <h4 className="fs-3 ms-5 fw-bold pt-0 d-flex">
                   {` Installation > `} {" "}<p className={style.kitName}>{kits.length > 1 ? 'Multiple Kits' : `${kit?.name}`}</p>
                </h4>
                </header>

                <Stepper classNames={stepper} active={active} onStepClick={setActive}>
                    {/* STEP 1 */}
                    <Stepper.Step label="Contact Information" >
                      <Card className={style.card}>
                          <>
                          <header className={style.formHeader}>
                            <p className="ms-4 pt-0">
                                CONTACT INFORMATION
                            </p>
                          </header>
                          <div className={style.inputContainer}>
                              <label data-tip="Enter the name of the person requesting the installation" className={style.inputLabel}> Property Address* </label>
                              <input
                              className={style.input}
                              onChange={handleChange}
                              value={formData.property}
                              name="property"
                              type="text"
                              placeholder="Enter Property Address"
                              />
                          </div>
                          <div className={style.inputContainer}>
                              <label data-tip="Enter the name of the person requesting the installation" className={style.labelNameEmail}> Full Name*  <HiInformationCircle className={style.icon}/></label>
                              <input
                              className={style.input}
                              onChange={handleChange}
                              value={formData.fullName}
                              name="fullName"
                              type="text"
                              placeholder="Enter Full Name"
                              />
                          </div>
                          <div className={style.inputContainer}>
                              {!phoneError ? <>
                                  <label data-tip="Provide the best phone number for contact" className={style.labelNumComp}> Contact Phone Number* <HiInformationCircle className={style.icon}/></label>
                                  <input
                                  className={style.input}
                                  onChange={handleChange}
                                  value={formData.phoneNumber}
                                  name="phoneNumber"
                                  type="number"
                                  pattern="\d{10}"
                                  placeholder="1234567890"
                                  />
                              </> :
                                  <>
                                      <label data-tip="Provide the best phone number for contact" className={style.labelNumComp}>Contact Phone Number* <HiInformationCircle className={style.icon}/></label>
                                      <input
                                      className={style.input}
                                      onChange={handleChange}
                                      value={formData.phoneNumber}
                                      name="phoneNumber"
                                      type="number"
                                      pattern="\d{10}"
                                      placeholder="1234567890"
                                      />
                                      <div className={style.error}><RiErrorWarningFill size={15} color='red' style={{marginRight: '5px'}}/> {phoneError}</div>
                                  </>
                              }
                                  
                          </div> 
                          <div className={style.inputContainer}>
                              {!emailError ? 
                              <>
                                  <label data-tip="Enter your email address for further communication" className={style.labelNameEmail}> Email Address* <HiInformationCircle className={style.icon}/></label>
                                  <input
                                  className={style.input}
                                  onChange={handleChange}
                                  value={formData.email}
                                  name="email"
                                  type="email"
                                  placeholder="example@email.com"
                                  />
                              </> :
                              <>
                                  <label data-tip="Enter your email address for further communication" className={style.labelNameEmail}> Email Address* <HiInformationCircle className={style.icon}/></label>
                                      <input
                                      className={style.input}
                                      onChange={handleChange}
                                      value={formData.email}
                                      name="email"
                                      type="email"
                                      placeholder="example@email.com"
                                      />
                                  <div className={style.error}><RiErrorWarningFill size={15} color='red' style={{marginRight: '5px'}}/> {emailError}</div>
                              </>
                              }
                          </div>
                          <div className={style.inputContainer}>
                              <label data-tip="Provide the company name, if applicable" className={style.labelNumComp}> Company Name (Optional) <HiInformationCircle className={style.icon}/></label>
                              <input
                              className={style.input}
                              onChange={handleChange}
                              value={formData.company}
                              name="company"
                              type="text"
                              placeholder="Company Name"
                              />
                          </div>

                          </>
                      </Card>
                    </Stepper.Step>
                    {/* STEP 2 */}
                    <Stepper.Step label="Appointment Information">
                    <Card className={style.card}>
                          <>
                          <header className={style.formHeader}>
                            <p className="ms-4 pt-0">
                                APPOINMENT INFORMATION
                            </p>
                          </header>
                          <div className={style.inputContainer}>
                              <label data-tip="Enter a preferred date, if applicable" className={style.labelNumComp}> Preferred Service Date (Optional) <HiInformationCircle className={style.icon}/></label>
                              {formData.date !== '' ? <p className={style.closeIcon}><MdClose onClick={handleRemoveDate} size={14}/></p> : <></>}
                              <input
                              className={style.input}
                              value={formData.date}
                              name="date"
                              onClick={() => {
                                setShowDatePicker(!showDatePicker)
                              }}
                              placeholder="Select"
                              />
                              
                              {showDatePicker && (
                                <Modal
                                centered
                                show={showDatePicker}
                                className={style.modal}
                                onHide={() => setShowDatePicker(false)}
                                >
                                <Modal.Header>
                                    <h1>Select A Day</h1>
                                </Modal.Header>
                                  <Modal.Body>
                                    <div className={style.datePickerWrapper}>
                                      <DatePicker
                                        onChange={handleDateChange}
                                        inline
                                        selected={selectedDate}
                                        name='date'
                                        timeFormat='p'
                                        timeIntervals={15}
                                        dateFormat='Pp'
                                        minDate={new Date()}
                                      />
                                    </div>
                                  </Modal.Body>
                                  <ModalFooter className={style.buttons}>
                                    <AppButton
                                      as="button"
                                      onClick={() => setShowDatePicker(false)}
                                      hierarchy="secondary"
                                    >
                                      Cancel
                                    </AppButton>
                                  </ModalFooter>
                                </Modal>
                                )}
                          </div>
                          <div className={style.inputContainer}>
                               <label data-tip="Indicate the timeframe(e.g. morning, afternoon) that works best for installation" className={style.labelTime}> Preferred Service Time (Optional) <HiInformationCircle className={style.icon}/></label>
                               <MultiSelect
                                    placeholder="Select"
                                    data={[' Early Morning (6:00AM - 10:00AM)', ' Late Morning (10:00AM - 12:00AM)', ' Early Afternoon (12:00PM - 3:00PM)', ' Late Afternoon (3:00PM - 6:00PM)']}
                                    onChange={handleServiceChange}
                                    required
                                    classNames={multiselect}
                                    value={isTime ? [...formData.time] : []}
                                />
                          </div>
                             
                           
                          <div className={style.inputContainer}>
                              <label data-tip="Provide any extra information or special instructions regarding the installation" className={style.labelAdd}> Additional Notes (Optional) <HiInformationCircle className={style.icon}/></label>
                              <textarea
                              className={style.input}
                              onChange={handleChange}
                              value={formData.message}
                              name="message"
                              placeholder="Enter text here..."
                              />
                          </div>

                          </>
                      </Card>
                    </Stepper.Step>
                    <Stepper.Completed>
                    <Card className={style.card}>
                          <>
                          <header className={style.formHeader}>
                            <p className="ms-4 pt-0">
                                CONTACT INFORMATION
                            </p>
                            <button className={style.button3} onClick={() => toStep1()}><RiPencilLine size={18}/></button>
                          </header>
                          
                            <table>
                                <tbody>
                                    <tr className={style.bottomRow}>
                                        <td className={style.leftTd}> Property Address</td>
                                        <td className={style.rightTd}>{formData.property}</td>
                                    </tr>
                                    <tr className={style.bottomRow}>
                                        <td className={style.leftTd}>Full Name</td>
                                        <td className={style.rightTd}>{formData.fullName}</td>
                                    </tr>
                                    <tr className={style.bottomRow}>
                                        <td className={style.leftTd}>Contact Phone Number</td>
                                        <td className={style.rightTd}>{formData.phoneNumber}</td>
                                    </tr>
                                    <tr className={style.bottomRow}>
                                        <td className={style.leftTd}>Email Address</td>
                                        <td className={style.rightTd}>{formData.email}</td>
                                    </tr>
                                    <tr className={style.bottomRow}>
                                        <td className={style.leftTd}>Company Name (Optional)</td>
                                        <td className={style.rightTd}>{formData.company !== '' ? formData.company : 'NA'}</td>
                                    </tr>
                                </tbody>
                            </table>
                        
                          
                          </>
                      </Card>

                      <Card className={style.card}>
                          <>
                          <header className={style.formHeader}>
                            <p className="ms-4 pt-0">
                                APPOINTMENT INFORMATION
                            </p>
                            <button className={style.button3} onClick={() => prevStep()}><RiPencilLine size={18}/></button>
                          </header>
                          <table>
                                <tbody>
                                    <tr className={style.bottomRow}>
                                        <td className={style.leftTd}>Preferred Service Date (Optional)</td>
                                        <td className={style.rightTd}>{formData.date !== '' ? formData.date : 'No preferred date'}</td>
                                    </tr>
                                    <tr className={style.bottomRow}>
                                        <td className={style.leftTd}>Preferred Service Time (Optional)</td>
                                        <td className={style.rightTd}>{formData.time !== '' && formData.time.length > 0 ? `${formData.time}` : 'No preferred time'}</td>
                                    </tr>
                                    <tr className={style.bottomRow}>
                                        <td className={style.leftTd}>Additional Notes (Optional)</td>
                                        <td className={style.rightTd} style={{maxWidth:'50px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>{formData.message !== '' ? formData.message : 'NA'}</td>
                                    </tr>
                                   
                                </tbody>
                            </table>
                          
                          </>
                      </Card>
                      <Card className={style.orderCard}>
                          <>
                          <header className={style.headerRow}>
                            <p>
                                ORDER DETAIL
                            </p>
                            
                          </header>
                          <table>
                                <tbody>
                                    <tr className={style.bottomRow}>
                                        <td className={style.leftTd}>Installation</td>
                                        <td className={style.midTd}>{kits.length > 1 ? `${kits.length} Items` : `${kits.length} Item`}</td>
                                        <td className={style.rightTd}>{`$${formData.cost}.00`}</td>
                                    </tr>
                                  
                                    <tr className={style.bottomRow}>
                                        <td className={style.leftTdTot}>Total</td>
                                        <td></td>
                                        <td className={style.rightTdTot}>{`$${formData.cost}.00`}</td>
                                    </tr>
                                   
                                </tbody>
                            </table>
                          
                          </>
                      </Card>
                      
                    </Stepper.Completed>
                </Stepper>

                <div className="d-flex justify-content-between mx-5">
                <AppButton
                  className={style.button1}
                  as='button'
                  onClick={()=> prevStep()}
                  disabled={active === 0}
                  hierarchy='secondary'
                  type='button'
                >
                  {'< Back'}
                </AppButton>

                { active !== 2 ? 
                <>
                
                  <AppButton
                    disabled={handleNextDisabled()}
                    className={style.button2}
                    as='button'
                    type='button'
                    onClick={active === 0 ? (e)=>handleVerify(e) : ()=>nextStep()}
                  >
                      {'Save'}
                  </AppButton>
                </> :

                <>
                  <AppButton
                      // disabled={handleNextDisable()}
                      className={style.button2}
                      as='button'
                      type='button'
                      onClick={handleFinalSubmit}
                    >
                        {'Send Information'}
                  </AppButton>
                </>
              }
            </div>

            </section>
        </Sidebar>
    )
}

export default Installation;