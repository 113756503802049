import React, { forwardRef } from 'react';

import { useField } from "formik";
import {
    Col,
    Form,
    Row,
} from 'react-bootstrap';

const InputText = forwardRef(({oneLine = true ,label, labelSize = 2, inputSize = 10, type="text", pattern, ...props }, ref) => {
    const [field] = useField(props);
    return (
        //return one line input
        oneLine ?
        <Form.Group className="mb-20">
            <Row className="align-items-center">
                <Col lg={labelSize}>
                    <Form.Label>{label}</Form.Label>
                </Col>
                <Col lg={inputSize}>
                    <Form.Control
                        type={type}
                        pattern={pattern}
                        {...field}
                        {...props}
                        ref={ref}
                    />
                </Col>
            </Row>
        </Form.Group> 
        :
        <>
        <Col lg={labelSize}>
                    <Form.Label>{label}</Form.Label>
                </Col>
                <Col lg={inputSize}>
                    <Form.Control
                        type="text"
                        {...field}
                        {...props}
                        ref={ref}
                    />
                </Col>
                </>

    )
})

export default InputText;