import { Auth } from 'aws-amplify';
import axios from 'axios';

export const getContacts = async ({ page = 1, order = "desc", limit = 7, selectedProperties, startDate, endDate, search }) => {
  const baseUrl = `${process.env.REACT_APP_NODE_API_URL}/api/contact`;

  const queryParams = new URLSearchParams();

  queryParams.append('page', page);
  queryParams.append('pageLimit', limit);
  queryParams.append('sort', order);
  if (selectedProperties) queryParams.append('propertyIds', selectedProperties)
  if (startDate) queryParams.append('startDate', startDate)
  if (endDate) queryParams.append('endDate', endDate)
  if (search) queryParams.append('search', search)


  const fullUrl = `${baseUrl}?${queryParams.toString()}`;
  try {
    const auth = await Auth.currentSession();
    const res = await axios.get(fullUrl, {
      headers: {
        Authorization: `Bearer ${auth.getIdToken().getJwtToken()}`,
      },
    });
    const properties = await res.data;
    return properties;
  } catch (error) {
    console.log("ERROR GETTING PROPERTIES: ", error)
  }
};