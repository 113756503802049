import React from 'react';
import '../Properties.scss';
// import 'react-datepicker/dist/react-datepicker.css';

import { Auth } from 'aws-amplify';
import axios from 'axios';
import {  Form } from 'react-bootstrap';
import { useParams } from 'react-router-dom';

import { useProperty } from '../../../context/PropertyContext';
import { useGeneral } from '../../../context/GeneralContext';
import { useEffect } from 'react';
import LeadList from '../../../components/LeadList';
import LeadSidebar from '../../../components/LeadSidebar';
import useViewport from '../../../hooks/useViewport';
import MobileLeadList from '../../../components/Mobile-LeadList';

const Leads = ({property}) => {
    const params = useParams();
    const { setAlertModal } = useGeneral();
    const { setLeads, setLeadsCount, leadFilters } = useProperty();
    const {width: screenWidth} = useViewport()

    const getLead = () => {
        const propertyId = params.id
        let url = `${process.env.REACT_APP_NODE_API_URL}/api/booking/property/${propertyId}`

        Auth.currentSession().then((token) => {
            axios
                .get(url, { headers: { Authorization: `Bearer ${token.getIdToken().getJwtToken()}` } })
                .then((response) => {
                  
                    if (response.status === 200) {
                        setLeads(response.data.reverse())
                        setLeadsCount({all: response.data.length})
                    }
                })
                .catch((error) => {
                    console.log(error);
                    setAlertModal({ show: true, title: 'Error', content: 'Error Getting leads' });
                })
        })
    }

    useEffect(() => {
        getLead();
    }, [leadFilters])

    return (
        <div className="content-div my-3">
            <Form>
                <div className="d-flex flex-wrap flex-xl-nowrap">
                    {screenWidth < 755 ? (
                        <MobileLeadList/>
                    ) : (
                        <LeadList />
                    )}
                </div>
            </Form >
            <LeadSidebar />
        </div >
    )
}

export default Leads;
