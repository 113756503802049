import React from "react";
import DragAndDropImg from "../DragAndDropImg";
import { AppButton } from "../../../../designSystem/AppButton";
import { useMutation } from "@tanstack/react-query";
import { analyzeId } from "../../../../api/id-analyzer";
import toast, { Toaster } from "react-hot-toast";
import { validationWarning } from "../../../../helper/alerts/id-analyzer";
import { Warning } from "../../../../types/id-analyzer";
import { MdOutlineFileUpload } from "react-icons/md";
type fakeEvent = {
  target: {
    name: string;
    value: string | boolean | number;
  };
};

type IdVerificationProps = {
  document: string;
  documentBack: string;
  requireFace: boolean;
  face?: string;
  onChange: ({ target }: fakeEvent) => void;
  profile: "security_low" | "security_medium" | "security_high" | "security_none";
};

const IdVerification = ({
  document,
  documentBack,
  requireFace,
  face,
  profile,
  onChange,

}: IdVerificationProps) => {
  const areFieldsCompleted: boolean = !!document && (requireFace && profile === 'security_low' ? !!face : true);

  const { mutateAsync: verifyDocument, isPending } = useMutation({
    mutationFn: async () => {
      if (!areFieldsCompleted) return;
      return await analyzeId({ document, face, profile, requireFace });
    },

    onSuccess: ({ decision, transactionId, warning: warnings }) => {
      if (decision === "accept") {
        toast.success("Approved");
        onChange({
          target: {
            name: "verified",
            value: true,
          },
        });
      } else {
        onChange({
          target: {
            name: "verified",
            value: false,
          },
        });

        warnings.forEach((warning: Warning) => {
          validationWarning(warning);
        });
      }
    },

    onError: (data) => {
      console.log("Error", data);
    },
  });

  const handleVerify = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    const verifyPromise = verifyDocument();

    toast.promise(
      verifyPromise,
      {
        loading: "Verifying...",
        success: "Verification completed",
        error: "Error verifying ID",
      },
      {
        success: {
          duration: 2000,
          icon: "👍",
        },
        error: {
          duration: 5000,
          icon: "🚫",
        },
      }
    );
  };

  const handleChangeImg = (e: fakeEvent) => {
    onChange(e);
    onChange({
      target: {
        name: "verified",
        value: false,
      },
    });
  };

  return (
    <>
      <div className={`col-12 ${requireFace ? " col-sm-6" : "col-sm-6"}`}>
        <DragAndDropImg
          onChange={handleChangeImg}
          img={document}
          name={"document"}
          placeholder="Front of document"
        />
      </div>
      {/* <div className={`col-12 ${requireFace ? " col-sm-4" : "col-sm-6"}`}>
        <DragAndDropImg
          onChange={handleChangeImg}
          img={documentBack}
          name={"documentBack"}
          placeholder="Back of document"
        />
      </div> */}

      {requireFace && (
        <div className="col-12 col-sm-6">
          <DragAndDropImg
            onChange={handleChangeImg}
            img={face}
            name={"face"}
            placeholder="Face image"
          />
        </div>
      )}

      <div className="col-12 pt-2 text-end">
        <AppButton
          as="button"
          className="px-4 text-center"
          onClick={handleVerify}
          disabled={!areFieldsCompleted || isPending}
        >
          <MdOutlineFileUpload size={20} /> Verify
        </AppButton>
      </div>
      <Toaster />
    </>
  );
};

export default IdVerification;
