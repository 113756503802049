import { useState } from "react";
import moment from "moment";
import AssignProperty from "../pages/Kits/components/AssignProperty";
import { RiPencilLine } from "react-icons/ri";
import { design } from "../constants";
import { Link } from "react-router-dom";

function MobileKitsTable({
  kits,
  setSelectedKits,
  selectedKits,
  setModal,
  closeModal,
}) {
  // const navigate = useNavigate();
  const [selectAll, setSelectAll] = useState(false);

  const [page, setPage] = useState(0);
  const kitsPerPage = 11;

  const startIndex = page * kitsPerPage;
  const endIndex = startIndex + kitsPerPage;

  const handleSelectKit = (checked, kit) => {
    setSelectAll(false);

    setSelectedKits((prev) => {
      const newSelection = [...prev];
      if (checked) {
        newSelection.push(kit._id);
      } else {
        const index = prev.indexOf(kit._id);
        if (index > -1) newSelection.splice(index, 1);
      }
      return newSelection;
    });
  };

  const showAssignProperty = (kit) => {
    setModal({
      show: true,
      content: <AssignProperty kit={kit} closeModal={closeModal} />,
      title: "",
    });
  };

  const totalPages = Math.ceil(kits.length / kitsPerPage);

  const currentPageKits = kits.slice(startIndex, endIndex);
  const pageNumbers = Array.from({ length: totalPages }, (_, i) => i + 1);

  return (
    <div className="d-flex flex-column p-3" style={{ fontFamily: "Poppins" }}>
      {currentPageKits.map((kit, kitIndex) => (
        <div className="border-bottom py-3 ps-2" key={kitIndex}>
          <div className="d-flex justify-content-between">
            <div className="col-2 col-xxl-3 border-bottom-0 d-flex gap-3 text-nowrap">
              <input
                className="form-check-input"
                type="checkbox"
                checked={selectAll || selectedKits.includes(kit._id)}
                id="flexCheckDefault"
                onChange={(e) => handleSelectKit(e.target.checked, kit)}
              />{" "}
              {kit.name}
            </div>

            <Link
              className="btn btn-primary bg-delet border-0 px-4"
              to={`/kits/history/${kit._id}`}
            >
              Kit History
            </Link>
          </div>

          <div>
            <span>Activated on: </span>
            <span>
              {kit.assignedDate
                ? moment(kit.assignedDate).format("MMMM Do, YYYY")
                : "Not active"}
            </span>
          </div>

          {kit.property ? (
            <button
              className="border-0 bg-white d-flex align-items-center justify-content-between w-100 text-start"
              onClick={() => showAssignProperty(kit)}
            >
              <span>{kit.property.address}</span>
              <RiPencilLine
                color={design.deleteMainColor}
                size={25}
                style={{ marginRight: "30px" }}
              />
            </button>
          ) : (
            <button
              className="border-0 bg-white d-flex gap-1 align-items-center text-delet-blue"
              onClick={() => showAssignProperty(kit)}
            >
              <div className="sign-icon">
                <span className="">+</span>
              </div>
              Assign property
            </button>
          )}
        </div>
      ))}

      <ul className="pagination mt-4 mb-0 mx-auto pb-3">
        <li className="page-item">
          <button
            className="page-link"
            onClick={() => setPage(page - 1)}
            disabled={page === 0}
          >
            <span aria-hidden="true">&laquo;</span>
          </button>
        </li>
        {pageNumbers.map((pageNumber, index) => (
          <li className="page-item" key={index}>
            <button
              className={`page-link ${pageNumber - 1 === page && "fw-bold"}`}
              onClick={() => setPage(pageNumber - 1)}
            >
              {pageNumber}
            </button>
          </li>
        ))}
        <li className="page-item">
          <button
            className="page-link"
            onClick={() => setPage(page + 1)}
            disabled={page === totalPages - 1}
          >
            <span aria-hidden="true">&raquo;</span>
          </button>
        </li>
      </ul>
    </div>
  );
}

export default MobileKitsTable;
