import React, { useCallback, useEffect, useState } from "react";
import { FaRegCreditCard } from "react-icons/fa6";
import { FaRegCalendarCheck } from "react-icons/fa";
import { FaHouseSignal } from "react-icons/fa6";
import moment from "moment";
import {
  ActionIcon,
  Badge,
  Button,
  Center,
  Collapse,
  Flex,
  Group,
  Loader,
  Paper,
  Stack,
  Table,
  Text,
  Title,
} from "@mantine/core";
import {
  MdExpandLess,
  MdExpandMore,
  MdKeyboardDoubleArrowDown,
  MdKeyboardDoubleArrowUp,
} from "react-icons/md";
import UseHttp from "../../../hooks/UseHttp";
import { Auth } from "aws-amplify";
import { useGeneral } from "../../../context/GeneralContext";
import useViewport from "../../../hooks/useViewport";
import MobileAccountBilling from "../../../components/Mobile-AccountBilling";

const Billing = () => {
  const [data, setData] = useState([]);
  const [nextPayment] = useState("");
  const [openedRows, setOpenedRows] = useState([]);

  const { isLoading, error, requestData } = UseHttp();
  const { setAlertModal } = useGeneral();
  const { width: screenWidth } = useViewport();

  const getBillingHistory = useCallback(async () => {
    Auth.currentSession().then((token) => {
      const requestConfig = {
        url: `${process.env.REACT_APP_NODE_API_URL}/api/invoices`,
        headers: {
          Authorization: `Bearer ${token.getIdToken().getJwtToken()}`,
        },
      };
      requestData(requestConfig, (response) => {
        const sortedResponse = response.sort((a, b) => {
          return new Date(b.paymentDate) - new Date(a.paymentDate);
        });
        setData(sortedResponse);
      });
    });
  }, [requestData]);

  useEffect(() => {
    getBillingHistory();
  }, [getBillingHistory]);

  useEffect(() => {
    if (error) {
      console.log(error);
      setAlertModal({
        show: true,
        title: error.title,
        content: error.content,
      });
    }
  }, [error, setAlertModal]);

  const toggleRow = (index) => {
    const updatedRows = [...openedRows];
    updatedRows[index] = !updatedRows[index];
    setOpenedRows(updatedRows);
  };

  const expandAllRows = () => {
    const updatedRows = new Array(data.length).fill(true);
    setOpenedRows(updatedRows);
  };

  const collapseAllRows = () => {
    setOpenedRows(new Array(data.length).fill(false));
  };

  const handleDownload = (e, pdfPath) => {
    e.preventDefault();
    const link = document.createElement("a");
    link.href = pdfPath;
    link.download = "invoice.pdf";
    document.body.appendChild(link);

    link.click();

    document.body.removeChild(link);
  };

  const rows = data.map((element, index) => {
    return (
      <Table.Tbody key={element.id}>
        <Table.Tr>
          <Table.Td>
            {
              <ActionIcon
                c="black"
                onClick={() => toggleRow(index)}
                variant="transparent"
                aria-label="collapse"
              >
                {openedRows[index] ? <MdExpandLess /> : <MdExpandMore />}
              </ActionIcon>
            }
          </Table.Td>
          <Table.Td>
            {moment(element.paymentDate).format("MMM Do, YYYY")}
          </Table.Td>
          <Table.Td>{moment(element.period).format("MMM YYYY")}</Table.Td>

          <Table.Td>$ {(element.amount / 100).toFixed(2)}</Table.Td>
          <Table.Td> {element.paymentMethod}</Table.Td>

          <Table.Td>
            {element.paid ? (
              <Badge variant="light" color="green">
                Paid
              </Badge>
            ) : (
              <Badge color="red" variant="light">
                Not paid
              </Badge>
            )}
          </Table.Td>
          <Table.Td>
            <Button
              onClick={(e) => {
                console.log(element);
                handleDownload(e, element.pdf);
              }}
              size="xs"
              variant="transparent"
              p={0}
            >
              Download
            </Button>
          </Table.Td>
        </Table.Tr>
        <Table.Tr display={!openedRows[index] && "none"}>
          <Table.Td colSpan={7} bg={"rgba(239, 240, 243, 1)"}>
            <Collapse in={openedRows[index]} duration={400}>
              <Stack>
                <Group pl={95}>
                  <Text size="sm">Kit 20:</Text>
                  <Text size="sm">Address, Unit, ZIPCODE</Text>
                </Group>
                <Group pl={95}>
                  <Text size="sm">Kit 20:</Text>
                  <Text size="sm">Address, Unit, ZIPCODE</Text>
                </Group>
              </Stack>
            </Collapse>
          </Table.Td>
        </Table.Tr>
      </Table.Tbody>
    );
  });

  return (
    <>
      {isLoading ? (
        <Center h={100}>
          <Loader />
        </Center>
      ) : (
        <div className="px-2 bg-body-tertiary">
          <Flex justify={"space-evenly"} wrap={screenWidth > 424 ? "nowrap" : "wrap"} gap={4} py={50}>
            <Paper shadow="sm" withBorder p="sm" radius="md">
              <Group justify="space-between">
                <Text size="sm" className="text-nowrap">
                  Current plan
                </Text>
                <FaHouseSignal />
              </Group>
              <Group justify="flex-end" mt={"md"}>
                {/* <Anchor
                  component={Link}
                  href="/kits"
                  underline="hover"
                  size="xs"
                >
                  Modify plan
                </Anchor> */}
              </Group>
            </Paper>
            <Paper shadow="sm" withBorder p="sm" radius="md">
              <Group justify="space-between">
                <Text size="sm" className="text-nowrap">
                  Next payment
                </Text>
                <FaRegCalendarCheck />
              </Group>
              <Group mt={"md"} justify="space-between">
                <Text c={"dimmed"} size="md">
                  {/* {nextPayment?.amount ? <>$ {(nextPayment.amount / 100).toFixed(2)}</> : null} */}
                </Text>
                <Text c={"dimmed"} size="md">
                  {nextPayment?.date
                    ? moment.unix(nextPayment.date).format("MMMM Do")
                    : null}
                </Text>
              </Group>
            </Paper>
            <Paper shadow="sm" withBorder p="sm" radius="md">
              <Group justify="space-between">
                <Text size="sm" className="text-nowrap">
                  Payment method
                </Text>
                <FaRegCreditCard />
              </Group>
              <Group justify="flex-end" mt={"md"} align="flex-end">
                {/* <Anchor href="" target="_blank" underline="hover" size="xs">
                  Edit payment method
                </Anchor> */}
              </Group>
            </Paper>
          </Flex>

          <Title order={4}>Billing history</Title>
          {screenWidth > 755 ? (
            <Table.ScrollContainer h={"60vh"}>
              <Table
                striped
                highlightOnHover
                withRowBorders={false}
                verticalSpacing="lg"
              >
                <Table.Thead>
                  <Table.Tr>
                    <Table.Th>
                      <ActionIcon
                        variant="transparent"
                        aria-label="expand-all"
                        color="black"
                        onClick={
                          openedRows.every((row) => row)
                            ? collapseAllRows
                            : expandAllRows
                        }
                      >
                        {openedRows.every((row) => row) ? (
                          <MdKeyboardDoubleArrowUp />
                        ) : (
                          <MdKeyboardDoubleArrowDown />
                        )}
                      </ActionIcon>
                    </Table.Th>
                    <Table.Th>Payment Date</Table.Th>
                    <Table.Th>Period</Table.Th>
                    <Table.Th>Amount</Table.Th>
                    <Table.Th>Payment Method</Table.Th>
                    <Table.Th>Status</Table.Th>
                    <Table.Th>Invoice</Table.Th>
                  </Table.Tr>
                </Table.Thead>
                {data?.length ? (
                  rows
                ) : (
                  <Table.Tbody>
                    <Table.Tr>
                      <Table.Td colSpan={7}>
                        <Center m={50}>
                          <Text>There are no records to display</Text>
                        </Center>
                      </Table.Td>
                    </Table.Tr>
                  </Table.Tbody>
                )}
              </Table>
            </Table.ScrollContainer>
          ) : (
            <MobileAccountBilling toggleRow={toggleRow} openedRows={openedRows} />
          )}
        </div>
      )}
    </>
  );
};

export default Billing;
