import React from "react";
import { useQuery } from "@tanstack/react-query";
import { useField, useFormikContext } from "formik";
import Select from "react-select";
import { getLocksList } from "../../../../api/lock";

const AugustIdSelect = ({ name, label, ...props }) => {
  const { setFieldValue } = useFormikContext();
  const [field, meta] = useField(name);

  const {
    data: locksList,
    isLoading,
    isError,
  } = useQuery({
    queryKey: ["august-locks"],
    queryFn: getLocksList,
  });

  const handleChange = (selectedOption) => {
    setFieldValue("lock.id", selectedOption.id);
  };

  const getValue = () => {
    const lock = locksList?.find((lock) => lock.id === field.value);
    return lock ? { value: lock.id, label: lock.name } : null;
  };

  const formatOptionLabel = ({ value, name }, { context }) => {
    if (context === 'value') {
      return value;
    } else {
      return name;
    }
  };

  return (
    <>
      <Select
        {...props}
        id={name}
        name={name}
        options={locksList}
        getOptionLabel={(lock) => lock.name}
        getOptionValue={(lock) => lock.id}
        value={getValue()}
        onChange={handleChange}
        onBlur={() => field.onBlur({ target: { name } })}
        className="h-100 text"
        isDisabled={isLoading}
        formatOptionLabel={formatOptionLabel}
      />
      {meta.touched && meta.error ? (
        <div className="error">{meta.error}</div>
      ) : null}
    </>
  );
};

export default AugustIdSelect;
