import React from 'react';

import { useField } from "formik";
import {
  Form,
} from 'react-bootstrap';

const InputSelect = ({ labelSize = 2, inputSize = 10, ...props }) => {
  const [field] = useField(props);
  return (
    <Form.Select {...field} {...props} />
  )
}

export default InputSelect;