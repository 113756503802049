import PropertyCard from "../PropertyCard/PropertyCard";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import style from "./style.module.scss";
import { useState } from "react";
import { DrawerModalContainer } from "../../../../../designSystem/DrawerModalContainer";
import { PropertyDetail } from "../../PropertyDetail";

import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

function Step1({ currentStep, properties, onChange, selectedId, checked }) {
  const [detailProperty, setDetailProperty] = useState(null);
  const onOpen = (property) => {
    setDetailProperty(property);
  };
  const onClose = (e) => {
    e?.stopPropagation();
    setDetailProperty(null);
  };

  return (
    <div className={` ${currentStep !== 1 ? "d-none" : ""} ${style.container}`}>
      <span className={style.companyName}>{properties.company?.name}</span>
      <Swiper
        modules={[Pagination, Navigation]}
        slidesPerView={1}
        centerInsufficientSlides={true}
        className={style.swiperContainer}
        navigation={true}
        pagination={{
          clickable: true, 
        }}
        breakpoints={{
          640: {
            slidesPerView: 1,
          },
          768: {
            slidesPerView: 1,
            
          },
        }}
        
      >
        {properties?.length ? (
          properties.map((property) => (
            <SwiperSlide className={style.swiperSlide}>
              <div >
                <PropertyCard
                  key={property.address}
                  onSeeDetails={onOpen}
                  selectedId={selectedId}
                  onChange={onChange}
                  property={property}
                  checked={checked}
                  type = 'checkbox'
                />
              </div>
            </SwiperSlide>
          ))
        ) : (
          <SwiperSlide className={style.swiperSlide}>
            <div >
            <PropertyCard
              onSeeDetails={onOpen}
              selectedId={selectedId}
              onChange={onChange}
              checked
              property={properties}
              type="checkbox"
            />
            </div>
          </SwiperSlide>
        )}
      </Swiper>

      <DrawerModalContainer isOpen={!!detailProperty} onClose={onClose}>
        {!!detailProperty ? <PropertyDetail property={detailProperty} onClose={onClose} /> : null}
      </DrawerModalContainer>
    </div>
  );
}

export default Step1;
