import React from "react";
import { propertyStatus } from "../../../static/Data";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { patchProperty } from "../../../api/properties";
import Select from "react-select";
import StyledSelect from "../../../components/Status";

const getBackgroundColor = (status) => {
  switch (status) {
    case "active":
      return "#CBFFCD";
    case "inactive":
      return "#EAEAEB";
    case "rented":
    case "sold":
      return "#D8F3F3";
    case "lease agreement sent":
    case "application received":
      return "#FFF8CC";
    default:
      return "#9293994d";
  }
};

const PropertyStatusSelect = ({ propertyId, status, queryKey }) => {
  const queryClient = useQueryClient();
  const { mutateAsync: handleChangeStatus, isPending } = useMutation({
    mutationFn: ({ propertyId, updatedFields }) =>
      patchProperty(propertyId, updatedFields),
    onSuccess: (updatedProperty) => {
      queryClient.setQueryData(queryKey, (prev) => {
        const newObjRes = { ...prev };
        const newPropertiesArray = [...newObjRes.properties];
        const index = newPropertiesArray.findIndex(
          (property) => property._id === updatedProperty._id
        );
        newPropertiesArray[index] = {
          ...newPropertiesArray[index],
          ...updatedProperty,
        };
        newObjRes.properties = newPropertiesArray;
        return newObjRes;
      });
    },
  });
  return (
    <StyledSelect
      status={status}
      values={[]}
      onChange={(e) =>
        handleChangeStatus({
          propertyId: propertyId,
          updatedFields: { status: e[0].status },
        })
      }
      placeholder={isPending ? '...' : status}
      options={propertyStatus}
      size="sm"
      className="status-td"
      style={{ pointerEvents: isPending ? "none" : "auto" }}
      disabled={isPending}
    />
  );
};

export default PropertyStatusSelect;
