import { useEffect, useState, useRef } from "react";

import axios from "axios";
import { Button, Card, Col, Row } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { Formik, Form } from "formik";
import { Auth } from "aws-amplify";

import Sidebar from "../components/AdminSideBar";
import InputText from "../../../components/InputText";
import InputSelect from "../../../components/InputSelect";
import { useQuery } from "@tanstack/react-query";
import { getUsersList } from "../../../api/users";

const AccountForm = () => {
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const tokenAugustRef = useRef();

  const [showAlert, setShowAlert] = useState(false);
  const [alertTitle, setAlertTitle] = useState("Alert");
  const [alertContent, setAlertContent] = useState("");

  const [organizations, setOrganizations] = useState([]);
  const [selectVendor, setSelectVendor] = useState("switchbot");

  const [initialValues, setInitialValues] = useState({});

  const [augustCodeSended, setAugustCodeSended] = useState(false);
  const [augustTokenData, setAugustTokenData] = useState({
    email: "",
    token: "",
  });

  const {
    data: users,
    isLoading,
    isError,
  } = useQuery({
    queryKey: ["users-admin-list"],
    queryFn: getUsersList,
  });

  useEffect(() => {
    if (location.pathname.includes("create")) {
      setInitialValues({
        name: "",
        description: "",
        category: "all",
        vendor: selectVendor,
        notes: "",
      });
    } else if (location.pathname.includes("edit")) {
      const getAccountDetails = async () => {
        try {
          const url = `${process.env.REACT_APP_NODE_API_URL}/api/account/${params.id}`;
          const token = await Auth.currentSession();

          const response = await axios.get(url, {
            headers: {
              Authorization: `Bearer ${token.getIdToken().getJwtToken()}`,
            },
          });

          if (response.status === 200) {
            setInitialValues(response.data);
            setSelectVendor(response.data.vendor);
          } else {
            setShowAlert(true);
            setAlertTitle("Error");
            setAlertContent("Error getting account");
          }
        } catch (error) {
          console.log(error);
          if (error?.response?.status === 401) {
            Auth.signOut()
              .then(() => {
                navigate("/admin/login");
              })
              .catch((error) => {
                console.log("Error signing out: ", error);
              });
          } else {
            setShowAlert(true);
            setAlertTitle("Error");
            setAlertContent("Error getting account");
          }
        }
      };
      getAccountDetails();
    }
    const getOrganization = async () => {
      try {
        const url = `${process.env.REACT_APP_NODE_API_URL}/api/users/orgs`;
        const token = await Auth.currentSession();

        const response = await axios.get(url, {
          headers: {
            Authorization: `Bearer ${token.getIdToken().getJwtToken()}`,
          },
        });

        if (response.status === 200) {
          setOrganizations(response.data);
        } else {
          setShowAlert(true);
          setAlertTitle("Error");
          setAlertContent("Error getting organizations");
        }
      } catch (error) {
        console.log(error);
        if (error?.response?.status === 401) {
          Auth.signOut()
            .then(() => {
              navigate("/admin/login");
            })
            .catch((error) => {
              console.log("Error signing out: ", error);
            });
        } else {
          setShowAlert(true);
          setAlertTitle("Error");
          setAlertContent("Error getting organizations");
        }
      }
    };
    getOrganization();
  }, [navigate, params.id, location.pathname]);

  const onSubmit = async (form) => {
    try {
      let methodForm = "";
      let url = "";
      if (location.pathname.includes("create")) {
        methodForm = `post`;
        url = `${process.env.REACT_APP_NODE_API_URL}/api/account`;
      } else if (location.pathname.includes("edit")) {
        methodForm = "put";
        url = `${process.env.REACT_APP_NODE_API_URL}/api/account/${params.id}`;
      }

      form.vendor = selectVendor;
      const token = await Auth.currentSession();

      const response = await axios({
        method: methodForm,
        url: url,
        data: form,
        headers: {
          Authorization: `Bearer ${token.getIdToken().getJwtToken()}`,
        },
      });

      if (response.status === 200) {
        setShowAlert(true);
        setAlertTitle("Saved");
        setAlertContent(response.data);
      }
    } catch (error) {
      console.log(error);
      if (error?.response?.status === 401) {
        Auth.signOut()
          .then(() => {
            navigate("/admin/login");
          })
          .catch((error) => {
            console.log("Error signing out: ", error);
          });
      } else {
        setShowAlert(true);
        setAlertTitle("Alert");
        setAlertContent("Error on save account");
      }
    }
  };

  return (
    <>
      <Sidebar>
        <div className="page-main-head d-flex justify-content-between">
          <h1 className="h4">
            {location.pathname.includes("edit") ? "Edit" : "Create"} Account
          </h1>
        </div>
        <Row>
          <Formik
            enableReinitialize={true}
            initialValues={initialValues}
            onSubmit={onSubmit}
          >
            <Form>
              <Col xxl={12} xl={12} lg={12}>
                <Card>
                  <Card.Header as="h5">Account Details</Card.Header>
                  <Card.Body className="d-flex flex-column gap-2">
                    <InputSelect
                      label="Vendor"
                      name="vendor"
                      onChange={(event) => setSelectVendor(event.target.value)}
                      value={selectVendor}
                    >
                      <option value="switchbot">Switchbot</option>
                      <option value="august">August</option>
                    </InputSelect>

                    <InputText label="Name" name="name" />

                    <InputText label="Description" name="description" />

                    <InputText label="Notes" name="notes" />

                    <InputSelect label="User" name="user" disabled={isLoading}>
                      <option value="">Select user</option>
                      {users?.map((user, index) => (
                        <option id={user._id} value={user._id} key={user._id}>
                          {user.firstName} {user.lastName}|{" "}
                          {user.company ? user.company.name : ""}
                        </option>
                      ))}
                    </InputSelect>
                  </Card.Body>
                </Card>
              </Col>
              {selectVendor === "switchbot" ? (
                <Col xxl={12} xl={12} lg={12}>
                  <Card>
                    <Card.Header as="h5">Switchbot info</Card.Header>
                    <Card.Body>
                      <InputText label="Token" name="token" />
                      <InputText label="Secret" name="secret" />
                    </Card.Body>
                    <div className="col-lg-12">
                      <div className="text-center">
                        <Button
                          className="btn btn-primary waves-effect waves-light m-3"
                          type="submit"
                        >
                          Save Account
                        </Button>
                      </div>
                    </div>
                  </Card>
                </Col>
              ) : null}
              {selectVendor === "august" ? (
                <Col xxl={12} xl={12} lg={12}>
                  <Card>
                    <Card.Header as="h5">August info</Card.Header>
                    <Card.Body>
                      <Row className="align-items-center mb-20">
                        <InputText label="Last name" name="lastName" />
                      </Row>
                    </Card.Body>
                    <div className="col-lg-12">
                      <div className="text-center">
                        <Button
                          className="btn btn-primary waves-effect waves-light m-3"
                          type="submit"
                        >
                          Save Account
                        </Button>
                      </div>
                    </div>
                  </Card>
                </Col>
              ) : null}
            </Form>
          </Formik>
        </Row>
      </Sidebar>
      {showAlert ? (
        <Modal show={showAlert}>
          <Modal.Header>
            <Modal.Title>{alertTitle}</Modal.Title>
          </Modal.Header>
          <Modal.Body>{alertContent}</Modal.Body>
          <Modal.Footer>
            {alertTitle === "Saved" ? (
              <Button
                variant="secondary"
                onClick={() => {
                  navigate("/admin/account/list");
                  setShowAlert(false);
                }}
              >
                Okay
              </Button>
            ) : (
              <Button variant="secondary" onClick={() => setShowAlert(false)}>
                Close
              </Button>
            )}
          </Modal.Footer>
        </Modal>
      ) : null}
    </>
  );
};
export default AccountForm;
