import "./Settings.scss";

import React, { useEffect, useState, useRef, useCallback } from "react";

import axios from "axios";
import moment from "moment";
import { Button, Card, Col, Modal, Row, Tab } from "react-bootstrap";

import { Formik, Form, FieldArray, getIn } from "formik";
import { ColorRing } from "react-loader-spinner";
import { useNavigate, useLocation } from "react-router-dom";
import { Auth } from "aws-amplify";

import Sidebar from "../../components/SideBar";
import InputText from "../../components/InputText";
import InputSelect from "../../components/InputSelect";
import InputSwitch from "../../components/InputSwitch";
import InputTextarea from "../../components/InputTextarea";
import Tabs from "../../components/Tabs";
import styled from "styled-components";
import Billing from "./Tabs/Billing";
import Users from "./Tabs/Users";
import UnstyledButton from "../../components/UnstyledButton";
// import { FaRegCopy } from "react-icons/fa6";
import { format, getTimezoneOffset, toZonedTime } from "date-fns-tz";
import { RiErrorWarningFill } from "react-icons/ri";

const calcDatesOptions = (firstValue, step, limit = 1440) => {
  let options = [];
  for (let i = firstValue; i < limit; i += step) {
    options.push({
      valueDate: i,
      labelDate: moment().startOf("day").add(i, "minutes").format("hh:mm a"),
    });
  }
  return options;
};

const RowContainer = styled(Row)`
  .tab-content {
    padding: 0;
  }
`;
const Account = () => {
  // Alert
  const [showAlert, setShowAlert] = useState(false);
  const [alertTitle, setAlertTitle] = useState("Alert");
  const [alertContent, setAlertContent] = useState("");

  const [settingData, setSettingData] = useState(false);
  const [timezone, setTimezone] = useState(0);
  const [currentTime, setCurrentTime] = useState(null);
  const [timeArea, setTimeArea] = useState(null);
  const [Loader, setLoader] = useState(false);

  const [currentRole, setCurrentRole] = useState("");
  const navigate = useNavigate();

  const getSettings = useCallback(async () => {
    try {
      const url = `${process.env.REACT_APP_NODE_API_URL}/api/users/`;
      const token = await Auth.currentSession();

      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token.getIdToken().getJwtToken()}`,
        },
      });
      if (response.status === 200) {
        setTimezone(response.data.settings.bookings.timezone);
        setTimeArea(response.data.settings.bookings.timezoneLocation);
        setCurrentTime(
          toZonedTime(
            new Date(),
            response.data.settings.bookings.timezoneLocation
          )
        );
        setSettingData({ ...response.data.settings });
        setCurrentRole(response.data.role);
        setLoader(true);
      } else {
        setShowAlert(true);
        setAlertTitle("Alert");
        setAlertContent("Error Getting Settings");
      }
    } catch (error) {
      if (error?.response?.status === 401) {
        Auth.signOut()
          .then(() => {
            navigate("/");
          })
          .catch((error) => {
            console.log("error signing out: ", error);
          });
      } else {
        setShowAlert(true);
        setAlertTitle("Alert");
        setAlertContent("Error Getting Settings");
      }
    }
  }, [navigate]);

  useEffect(() => {
    if (currentRole === "agent") {
      navigate("/");
    }
    getSettings();
    // getStripeSub();
  }, [currentRole, getSettings, navigate]);

  const [phoneError, setPhoneError] = useState("");
  const phoneInputRef = useRef(null);

  const validatePhone = (phone) => {
    return /^\d{10}$/.test(phone);
  };

  const handleVerify = (phone) => {
    if (phone !== "") {
      if (!validatePhone(phone)) {
        setPhoneError("Incomplete or Invalid phone number");
        return false;
      } else {
        setPhoneError("");
        return true;
      }
    }

    return true;
  };

  const handlePhoneFocus = () => {
    setPhoneError("");
  };

  const onSubmit = (data) => {
    delete data.bookings["timezone"];

    if (!handleVerify(data.customerSupportPhone)) {
      phoneInputRef.current.focus();
      return;
    }

    data.bookings.timezone = timezone;
    data.bookings.timezoneLocation = timeArea;

    Auth.currentSession()
      .then((token) => {
        axios
          .patch(
            `${process.env.REACT_APP_NODE_API_URL}/api/users/settings`,
            data,
            {
              headers: {
                Authorization: `Bearer ${token.getIdToken().getJwtToken()}`,
              },
            }
          )
          .then((response) => {
            if (response.status === 200) {
              setTimezone(response.data.bookings.timezone);
              setPhoneError("");
              setShowAlert(true);
              setAlertTitle("Saved");
              setAlertContent("Settings Updated Successfully");
            } else {
              setShowAlert(true);
              setAlertTitle("Alert");
              setAlertContent("Error Updating Settings");
            }
          })
          .catch((error) => {
            setShowAlert(true);
            setAlertTitle("Alert");
            setAlertContent("Error Updating Settings");
          });
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          Auth.signOut()
            .then(() => {
              navigate("/");
            })
            .catch((error) => {
              console.log("error signing out: ", error);
            });
        } else {
          setShowAlert(true);
          setAlertTitle("Alert");
          setAlertContent("Error Updating Settings");
        }
      });
  };

  const [activeTab, setActiveTab] = useState("");

  const location = useLocation();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const query = queryParams.get("activeTab");

    setActiveTab(query ?? "settings");
  }, [location.search]);

  const handleTabChange = (eventKey) => {
    // Update the URL with the active tab query parameter
    window.history.pushState({}, "", `/account?activeTab=${eventKey}`);
    setActiveTab(eventKey);
  };

  const handleTimeChange = (e) => {
    e.preventDefault();
    const newTimezone =
      getTimezoneOffset(e.target.value, new Date()) / 60 / 60 / 1000;
    const newTime = toZonedTime(new Date(), e.target.value);
    setTimezone(newTimezone);
    setCurrentTime(newTime);
    setTimeArea(e.target.value);
  };

  return (
    <Sidebar>
      <div className="page-main-head d-flex align-items-center">
        <Row className="align-items-center">
          <Col xl={8}>
            <div className="d-flex align-items-center flex-wrap flex-md-nowrap">
              <h2 className="color-black">Account</h2>
            </div>
          </Col>
        </Row>
      </div>
      {Loader === false ? (
        <>
          <div style={{ textAlign: "center" }}>
            <ColorRing
              visible={true}
              height="100"
              width="100"
              ariaLabel="blocks-loading"
              wrapperClass="blocks-wrapper"
              colors={["#1F60AE", "#5C79FF", "#455DC8", "#889DFF", "#5271FF"]}
            />
          </div>
        </>
      ) : (
        <RowContainer className="mx-0" style={{}}>
          <Tabs
            defaultActiveKey={activeTab}
            onSelect={handleTabChange}
            style={{ paddingLeft: "32px", backgroundColor: "#EAEAEB" }}
          >
            <Tab eventKey="settings" title="Settings">
              <div className="px-3 py-3">
                <Card>
                  <Card.Header as="h5">General information</Card.Header>
                  <Card.Body>
                    <Formik initialValues={settingData} onSubmit={onSubmit}>
                      {({ values }) => {
                        return (
                          <Form>
                            <InputText
                              label="Customer Support Phone"
                              name="customerSupportPhone"
                              labelSize={6}
                              inputSize={6}
                              type="number"
                              pattern="\d{10}"
                              ref={phoneInputRef}
                              onClick={handlePhoneFocus}
                            />
                            {phoneError ? (
                              <div className="error">
                                <RiErrorWarningFill
                                  size={15}
                                  color="red"
                                  style={{ marginRight: "5px" }}
                                />{" "}
                                {phoneError}
                              </div>
                            ) : (
                              <></>
                            )}
                            <hr />
                            <h5>Select Timezone</h5>
                            <div className="d-flex gap-4">
                              <div className="col-6">
                                <InputSelect
                                  label="Location"
                                  name="bookings.timezoneLocation"
                                  labelSize={6}
                                  inputSize={6}
                                  onChange={handleTimeChange}
                                  value={timeArea ? timeArea : "Pacific Time"}
                                >
                                  <option value="US/Hawaii">
                                    Hawaiian Time
                                  </option>
                                  <option value="US/Alaska">
                                    Alaskan Time
                                  </option>
                                  <option value="America/Los_Angeles">
                                    Pacific Time
                                  </option>
                                  <option value="America/Denver">
                                    Mountain Time
                                  </option>
                                  <option value="America/Chicago">
                                    Central Time
                                  </option>
                                  <option value="America/New_York">
                                    Eastern Time
                                  </option>
                                </InputSelect>
                              </div>
                            </div>
                            <hr />
                            <div className="d-flex justify-content-between">
                              <h5>Timezone</h5>
                              <p className="pe-4">
                                <strong>Current Time:</strong>{" "}
                                {currentTime
                                  ? format(currentTime, "hh:mm aa")
                                  : "12:00 AM"}
                              </p>
                            </div>
                            <Row className="align-items-center">
                              <Col lg={6}>
                                <label>GMT {timezone}</label>
                              </Col>
                            </Row>
                            <hr />
                            <h5>Availability</h5>

                            {Object.keys(values.bookings.dow).map((day) => {
                              return (
                                <div className="row gap-3" key={day}>
                                  <InputSwitch
                                    label={day}
                                    name={`bookings.dow.${day}.available`}
                                  />

                                  <div className="col-12 d-flex flex-column gap-3">
                                    {!values.bookings.dow[day].available ? (
                                      <span className="fw-semibold text-secondary">
                                        Unavailable
                                      </span>
                                    ) : (
                                      <FieldArray
                                        name={`bookings.dow.${day}.availability`}
                                      >
                                        {({ form, remove, push }) => {
                                          const { values } = form;
                                          return values.bookings.dow[
                                            day
                                          ].availability.map(
                                            (
                                              { startTime, stopTime },
                                              index
                                            ) => {
                                              const nextStartTimePath = `bookings.dow.${day}.availability.${
                                                index + 1
                                              }.startTime`;
                                              const prevStopTimePath = `bookings.dow.${day}.availability.${
                                                index - 1
                                              }.stopTime`;

                                              const nextStartTime = getIn(
                                                values,
                                                nextStartTimePath
                                              );

                                              const prevStopTime =
                                                index > 0
                                                  ? getIn(
                                                      values,
                                                      prevStopTimePath
                                                    )
                                                  : null;

                                              return (
                                                <div
                                                  className="row align-items-center"
                                                  key={day + index}
                                                >
                                                  <div className="col">
                                                    <InputSelect
                                                      name={`bookings.dow.${day}.availability.${index}.startTime`}
                                                      labelSize={6}
                                                      inputSize={6}
                                                      value={startTime}
                                                    >
                                                      {calcDatesOptions(
                                                        prevStopTime
                                                          ? parseInt(
                                                              prevStopTime
                                                            ) + 15
                                                          : 0,
                                                        15,
                                                        parseInt(stopTime)
                                                      ).map((option, index) => (
                                                        <option
                                                          key={index}
                                                          value={
                                                            option.valueDate
                                                          }
                                                        >
                                                          {option.labelDate}
                                                        </option>
                                                      ))}
                                                    </InputSelect>
                                                  </div>
                                                  <span className="col-1 fw-bold fs-4 text-center">
                                                    -
                                                  </span>
                                                  <div className="col">
                                                    <InputSelect
                                                      name={`bookings.dow.${day}.availability.${index}.stopTime`}
                                                      labelSize={6}
                                                      inputSize={6}
                                                    >
                                                      {calcDatesOptions(
                                                        parseInt(startTime) +
                                                          15,
                                                        15,
                                                        nextStartTime
                                                          ? parseInt(
                                                              nextStartTime
                                                            )
                                                          : undefined
                                                      ).map((option, index) => (
                                                        <option
                                                          key={index}
                                                          value={
                                                            option.valueDate
                                                          }
                                                        >
                                                          {option.labelDate}
                                                        </option>
                                                      ))}
                                                    </InputSelect>
                                                  </div>
                                                  <UnstyledButton
                                                    type="button"
                                                    className="col-1 fw-bold fs-4"
                                                    onClick={() =>
                                                      push({
                                                        startTime:
                                                          parseInt(stopTime) +
                                                          15,
                                                        stopTime:
                                                          parseInt(stopTime) +
                                                          30,
                                                      })
                                                    }
                                                  >
                                                    +
                                                  </UnstyledButton>

                                                  <UnstyledButton
                                                    className="col-1 fw-bold"
                                                    onClick={() =>
                                                      remove(index)
                                                    }
                                                    disabled={
                                                      values.bookings.dow[day]
                                                        .availability.length ===
                                                      1
                                                    }
                                                  >
                                                    X
                                                  </UnstyledButton>
                                                </div>
                                              );
                                            }
                                          );
                                        }}
                                      </FieldArray>
                                    )}
                                  </div>
                                  <hr />
                                </div>
                              );
                            })}

                            <div className="d-flex flex-column gap-3">
                              <h5>Notifications</h5>

                              <InputSwitch
                                label="Appointment confirmation"
                                name="notifications.confirmation.status"
                              />
                              <InputTextarea
                                label=""
                                name="notifications.confirmation.text"
                                labelSize={0}
                                inputSize={12}
                                id="confirmation"
                              />

                              <InputSwitch
                                label="Reminder before booking:"
                                name="notifications.reminder.status"
                              />
                              <InputSelect
                                label="Time"
                                name="notifications.reminder.time"
                                labelSize={1}
                                inputSize={3}
                              >
                                <option value={3600000}>1 hour</option>
                                <option value={7200000}>2 hour</option>
                                <option value={10800000}>3 hour</option>
                                <option value={14400000}>4 hour</option>
                                <option value={18000000}>5 hour</option>
                                <option value={21600000}>6 hour</option>
                              </InputSelect>
                              <InputTextarea
                                label=""
                                name="notifications.reminder.text"
                                labelSize={0}
                                inputSize={12}
                                id="reminder"
                              />
                            </div>
                            <Button type="submit">Save Settings</Button>
                          </Form>
                        );
                      }}
                    </Formik>
                  </Card.Body>
                </Card>
              </div>
            </Tab>
            <Tab eventKey="billing" title="Billing">
              {activeTab === "billing" && <Billing />}
            </Tab>
            <Tab eventKey="users" title="Users">
              {activeTab === "users" && <Users />}
            </Tab>
          </Tabs>
        </RowContainer>
      )}
      {showAlert ? (
        <Modal show={showAlert}>
          <Modal.Header>
            <Modal.Title>{alertTitle}</Modal.Title>
          </Modal.Header>
          <Modal.Body>{alertContent}</Modal.Body>
          <Modal.Footer>
            {alertTitle === "Saved" ? (
              <Button
                variant="secondary"
                onClick={() => {
                  // navigate('/')
                  setShowAlert(false);
                }}
              >
                Okay
              </Button>
            ) : (
              <Button variant="secondary" onClick={() => setShowAlert(false)}>
                Close
              </Button>
            )}
          </Modal.Footer>
        </Modal>
      ) : (
        ""
      )}
    </Sidebar>
  );
};

export default Account;
