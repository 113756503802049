import React from "react";
import { Form, Col } from "react-bootstrap";
import LoaderRing from "../../../../components/LoaderRing";
import { Link } from "react-router-dom";

export default function Step3({
  isverification,
  verificationURL,
  onFileChange,
  file,
  currentStep,
  onSubmit,
  isLoading,
  screenWidth,
  onReschedule,
  meetingId,
  meetingLoader,
  meetingData,
}) {
  return (
    <>
      {screenWidth > 1200 ? (
        <div>
          <span className="step-title">{"Step 3"}</span>
          <h4>Identity verifications</h4>
        </div>
      ) : (
        <h5 className="text-capitalize">Step 3: Pick Your Date</h5>
      )}
      <div className={`${currentStep === 3 ? "" : "overlay-div"}`}>
        <div className="steps-container pt-3" id="scroll">
          {meetingLoader ? (
            <LoaderRing height="100" width="100" />
          ) : (
            <>
              <Form
                onSubmit={meetingId ? onReschedule : onSubmit}
                encType="multipart/form-data"
                method="POST"
              >
                {meetingId ? (
                  <Col
                    xl={6}
                    lg={12}
                    className="bg-white w-100 h-100 py-4 px-2"
                    style={{ borderRadius: "28px" }}
                  >
                    <div className="name-panel mb-30 offcanvas-panel">
                      <h5 className="mb-20">
                        {meetingData?.contact?.firstName || ""}{" "}
                        {meetingData?.contact?.lastName || ""}
                      </h5>
                      <div className="d-block">
                        <Link
                          to="tel:202-555-0153"
                          className="table-phone phonenumber-w-ico link-w-ico color-black"
                        >
                          {meetingData?.contact?.phone || ""}
                        </Link>
                      </div>
                      <div className="d-block mb-2">
                        <Link
                          to={`mailto:${meetingData?.contact?.email || ""}`}
                          className="table-mail mail-w-ico link-w-ico"
                        >
                          {meetingData?.contact?.email || ""}
                        </Link>
                      </div>
                    </div>
                  </Col>
                ) : (
                  <>
                    <div className="px-3">
                      <div className="input-wrapper">
                        <Form.Group className="mb-3">
                          <Form.Control
                            type="text"
                            name="firstName"
                            className="form-control"
                            placeholder="First name"
                          />
                        </Form.Group>
                      </div>
                      <div className="input-wrapper">
                        <Form.Group className="mb-3">
                          <Form.Control
                            className="form-control"
                            type="text"
                            name="lastName"
                            placeholder="Last name"
                          />
                        </Form.Group>
                      </div>
                      <div className="input-wrapper">
                        <Form.Group className="mb-3">
                          <Form.Control
                            className="form-control"
                            type="text"
                            maxLength="11"
                            minLength="10"
                            name="phone"
                            placeholder="Phone number"
                          />
                        </Form.Group>
                      </div>
                      <div className="input-wrapper">
                        <Form.Group className="mb-3">
                          <Form.Control
                            className="form-control"
                            type="text"
                            name="email"
                            placeholder="Email address"
                          />
                        </Form.Group>
                      </div>
                      {!meetingId && !isverification ? (
                        <Form.Group className="formFile-control">
                          <label className="form-label" htmlFor="formFile">
                            <div className="img-drop-area">
                              <img src={file} required="" alt="" />
                            </div>
                            <div className="pt-3">
                              <span className="btn text-light w-75 bg-black rounded-3">
                                Upload ID
                              </span>
                            </div>
                          </label>
                          <Form.Control
                            name="image"
                            type="file"
                            id="formFile"
                            onChange={onFileChange}
                            className="form-control"
                          />
                        </Form.Group>
                      ) : (
                        <div>
                          <iframe
                            src={verificationURL}
                            allow="camera https://docupass.app;microphone https://docupass.app"
                            className="w-100"
                            style={{
                              border: 0,
                              backgroundColor: "transparent",
                              height: "19rem",
                            }}
                            title="myFrame"
                          ></iframe>
                        </div>
                      )}
                    </div>
                  </>
                )}
                <div className="">
                  <button
                    type="submit"
                    className="btn btn-lg btn-block "
                    disabled={isLoading}
                  >
                    {isLoading ? (
                      <div className="spinner-border" role="status">
                        <span className="sr-only"></span>
                      </div>
                    ) : (
                      <span>
                        {" "}
                        {meetingId
                          ? "Confirm reschedule"
                          : "Schedule My Tour >>"}
                      </span>
                    )}
                  </button>
                </div>
              </Form>
            </>
          )}
        </div>
      </div>
    </>
  );
}
