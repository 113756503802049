import React, { useState } from "react";
import "./Mobile-Table.scss";
import { Link } from "react-router-dom";
import { Share } from "react-feather";
import getImageUrl from "../../../../helper/checkImageFormat";
import styles from "./styles.module.scss";
import FormModal from "./../../../../components/Modals/FormModal";
import { FaRegCopy } from "react-icons/fa6";
import CustomDropdown from "./../../../../components/Dropdown";
import { MoreVertical } from "react-feather";
import { propertyListMenuItems } from "./../../../../static/Data";

const MobileTable = ({ list, handleMenuItemClick, handleFormModal }) => {
  const [showModal, setShowModal] = useState(false);
  const [isVerified, setIsVerified] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [emailError, setEmailError] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [formData, setFormData] = useState({
    location: "",
    fullName: "",
    email: "",
    phoneNumber: "",
  });

  const handleModalClose = () => {
    setShowModal(false);
    setIsVerified(false);
    setIsSubmitted(false);
    setFormData({ fullName: "", email: "", phoneNumber: "" });
    setPhoneError("");
    setEmailError("");
  };

  return (
    <>
      {list.map((element, index) => {
        return (
          <div className={styles.container}>
            <div className={styles.innerContainer}>
              <div className={styles.actionIconsContainer}>
                <span>
                  <Share
                    className={styles.moreIcon}
                    height={"15"}
                    width={"15"}
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      handleFormModal(element);
                    }}
                  />
                </span>
                <span>
                  <FaRegCopy
                    className={styles.moreIcon}
                    height={"15"}
                    width={"15"}
                    onClick={() =>
                      handleMenuItemClick(
                        "Copy prospect booking link",
                        element._id
                      )
                    }
                  />
                </span>
              </div>
              <Link to={`/property/${element._id}`}>
                <img
                  src={element.images?.[0] ?? getImageUrl(element.primaryImage)}
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null; // prevents looping
                    currentTarget.src = require("../../../../assests/images/1-placeholder.png");
                  }}
                  alt=""
                />
              </Link>
              <div className={styles.details}>
                {/* <p className={styles.classification}>{element.classification}</p> */}
                <div className={styles.innerDetails}>
                  <div className="d-flex flex-column align-items-start justify-content-center">
                    <h3>{element.address || "No address"}</h3>
                    <p>{element.city}</p>
                  </div>
                  <div className="more-actions">
                    <CustomDropdown
                      icon={<MoreVertical color="#979797" />}
                      menuItems={propertyListMenuItems}
                      onMenuItemClick={(action) =>
                        handleMenuItemClick(action, element._id)
                      }
                      style={{ pointerEvents: "auto" }}
                    />
                  </div>
                </div>
                <div className={styles.propertyMetadata}>
                  <span>
                    <strong>Price: </strong>
                    <p>{element.price}</p>
                  </span>
                  <span>
                    <strong>Leads: </strong>
                    <p>{element.leadsCount}</p>
                  </span>
                  <span>
                    <strong>Listed On: </strong>
                    <p>
                      {element.listedTs &&
                        new Date(element.listedTs).toLocaleDateString()}
                    </p>
                  </span>
                </div>
              </div>
            </div>
            <hr />
          </div>
        );
      })}
      <FormModal
        show={showModal}
        onHide={() => handleModalClose()}
        isVerified={isVerified}
        isSubmitted={isSubmitted}
        setIsVerified={setIsVerified}
        setIsSubmitted={setIsSubmitted}
        formData={formData}
        setFormData={setFormData}
        emailError={emailError}
        setEmailError={setEmailError}
        phoneError={phoneError}
        setPhoneError={setPhoneError}
      />
    </>
  );
};

export default MobileTable;
