// Card.js
import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import propertyDummyImage from "../../../../assests/images/6-thumb.png";


const Card = ({ images, address, keyCount, bedCount, bathCount, price }) => {

  const CustomPrevArrow = (props) => {
    const { onClick } = props;
    return <div className="slick-arrow slick-prev" onClick={onClick}></div>;
  };

  const CustomNextArrow = (props) => {
    const { onClick } = props;
    return <div className="slick-arrow slick-next" onClick={onClick}></div>;
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
  };

  return (
    <>
      <div className="card-body">
        {images && images.length > 0 ? (
          images.length === 1 ? (
            <img
              src={images[0]}
              alt="property"
              className="img-fluid"
              onError={({ currentTarget }) => {
                currentTarget.onerror = null;
                currentTarget.src = propertyDummyImage;
              }}
            />
          ) : (
            <Slider {...settings}>
              {images.map((imageSrc, index) => (
                <div key={index}>
                  <img
                    src={imageSrc}
                    alt={`property ${index}`}
                    className="img-fluid"
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null;
                      currentTarget.src = propertyDummyImage;
                    }}
                  />
                </div>
              ))}
            </Slider>
          )
        ) : (
          <img
            src={propertyDummyImage}
            alt="property"
            className="img-fluid"
            onError={({ currentTarget }) => {
              currentTarget.onerror = null;
              currentTarget.src = propertyDummyImage;
            }}
          />
        )}
        <p
          className="fw-bold pt-1"
          style={{
            fontSize: "10px",
            fontWeight: "bold",
          }}
        >
          {address}
        </p>
      </div>
      <div
        className="d-flex justify-content-center"
        style={{ paddingBottom: "15px", gap: "12px" }}
      >
        <div className="d-flex align-items-center" style={{ gap: "15px" }}>
          {console.log(keyCount)}
          {keyCount && (
            <div className="d-flex align-items-center">
              <svg
                width="17"
                height="17"
                viewBox="0 0 17 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <mask
                  id="mask0_519_2491"
                  maskUnits="userSpaceOnUse"
                  x="0"
                  y="0"
                  width="17"
                  height="17"
                >
                  <path
                    d="M-0.000905555 8.48347L8.48438 -0.00181111L16.9697 8.48347L8.48438 16.9688L-0.000905555 8.48347Z"
                    fill="white"
                  />
                </mask>
                <g mask="url(#mask0_519_2491)">
                  <path
                    d="M8.48438 2.03681C6.43057 2.03681 4.76562 3.70176 4.76562 5.75556C4.76562 7.43346 5.87696 8.8516 7.40363 9.31459L7.40363 15.3919L8.48438 16.4727L9.56512 15.3919V14.758L8.8917 14.0938L9.56512 13.4111L9.56514 12.5624L8.94891 11.9461L9.56512 11.3171L9.56512 9.31459C11.0918 8.8516 12.2031 7.43346 12.2031 5.75556C12.2031 3.70175 10.5382 2.03681 8.48438 2.03681Z"
                    stroke="#4067F9"
                    strokeMiterlimit="10"
                  />
                  <path
                    d="M9.5164 4.61472C9.5164 5.18469 9.05435 5.64674 8.48438 5.64674C7.9144 5.64674 7.45235 5.18469 7.45235 4.61472C7.45235 4.04474 7.9144 3.58269 8.48438 3.58269C9.05435 3.58269 9.5164 4.04474 9.5164 4.61472Z"
                    stroke="#4067F9"
                    strokeMiterlimit="10"
                  />
                </g>
              </svg>
              <p className="item-quentity">{keyCount} Keys</p>
            </div>
          )}
          {bedCount && (
            <div className="d-flex align-items-center" style={{ gap: "5px" }}>
              <svg
                width="17"
                height="15"
                viewBox="0 0 17 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M14.9337 7.87764H1.74588C1.59158 7.8776 1.44362 7.81949 1.33452 7.71606C1.22542 7.61264 1.1641 7.47238 1.16406 7.32612V2.17852C1.16464 1.64235 1.38958 1.12829 1.78953 0.749161C2.18949 0.37003 2.73177 0.156795 3.29739 0.15625H13.3822C13.9478 0.156795 14.4901 0.37003 14.8901 0.749161C15.29 1.12829 15.5149 1.64235 15.5155 2.17852V7.32612C15.5155 7.47238 15.4542 7.61264 15.3451 7.71606C15.236 7.81949 15.088 7.8776 14.9337 7.87764ZM2.32769 6.77459H14.3519V2.17852C14.3517 1.9348 14.2494 1.70112 14.0676 1.52879C13.8858 1.35645 13.6393 1.25953 13.3822 1.25931H3.29739C3.04028 1.25953 2.79378 1.35645 2.61197 1.52879C2.43017 1.70112 2.32793 1.9348 2.32769 2.17852V6.77459Z"
                  fill="#4067F9"
                />
                <path
                  d="M15.709 14.4948H14.1575C13.9004 14.4946 13.6539 14.3977 13.4721 14.2254C13.2903 14.053 13.1881 13.8193 13.1878 13.5756V13.0241H3.4909V13.5756C3.49066 13.8193 3.38842 14.053 3.20662 14.2254C3.02482 14.3977 2.77831 14.4946 2.5212 14.4948H0.969693C0.712588 14.4946 0.466081 14.3977 0.28428 14.2254C0.102479 14.053 0.000238262 13.8193 0 13.5756V8.79571C0.000575023 8.25954 0.22552 7.74548 0.625472 7.36635C1.02542 6.98722 1.56771 6.77398 2.13333 6.77344H14.5454C15.111 6.77398 15.6533 6.98722 16.0533 7.36635C16.4532 7.74548 16.6782 8.25954 16.6787 8.79571V13.5756C16.6785 13.8193 16.5762 14.053 16.3944 14.2254C16.2126 14.3977 15.9661 14.4946 15.709 14.4948ZM14.3515 13.3918H15.5151V8.79571C15.5149 8.55199 15.4126 8.31831 15.2308 8.14597C15.049 7.97364 14.8025 7.87672 14.5454 7.87649H2.13333C1.87622 7.87672 1.62971 7.97364 1.44791 8.14597C1.26611 8.31831 1.16387 8.55199 1.16363 8.79571V13.3918H2.32726V12.8402C2.3275 12.5965 2.42974 12.3629 2.61154 12.1905C2.79334 12.0182 3.03985 11.9213 3.29696 11.921H13.3818C13.6389 11.9213 13.8854 12.0182 14.0672 12.1905C14.249 12.3629 14.3512 12.5965 14.3515 12.8402V13.3918Z"
                  fill="#4067F9"
                />
                <path
                  d="M13.7696 10.4507H0.581816C0.427509 10.4507 0.279522 10.3926 0.17041 10.2892C0.0612983 10.1857 0 10.0455 0 9.89918C0 9.75291 0.0612983 9.61263 0.17041 9.5092C0.279522 9.40576 0.427509 9.34766 0.581816 9.34766H13.7696C13.924 9.34766 14.0719 9.40576 14.1811 9.5092C14.2902 9.61263 14.3515 9.75291 14.3515 9.89918C14.3515 10.0455 14.2902 10.1857 14.1811 10.2892C14.0719 10.3926 13.924 10.4507 13.7696 10.4507Z"
                  fill="#4067F9"
                />
              </svg>
              <p className="item-quentity">{bedCount} BEDS</p>
            </div>
          )}
          {bathCount && (
            <div className="d-flex align-items-center" style={{ gap: "5px" }}>
              <svg
                width="15"
                height="17"
                viewBox="0 0 15 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M13.8269 10.7175V12.2765C13.8269 13.2587 13.4897 14.2003 12.8888 14.8949C12.2879 15.5894 11.4732 15.9792 10.6234 15.9792H3.87925C3.02949 15.9792 2.2148 15.5894 1.61389 14.8949C1.01299 14.2003 0.675781 13.2587 0.675781 12.2765V10.7175C0.675781 10.3947 0.902385 10.1328 1.18159 10.1328H13.3211C13.6003 10.1328 13.8269 10.3947 13.8269 10.7175ZM12.8152 11.3021H1.6874V12.2765C1.6874 12.9484 1.91805 13.5931 2.32945 14.0678C2.74017 14.5433 3.29791 14.8099 3.87925 14.8099H10.6234C11.2047 14.8099 11.7625 14.5433 12.1732 14.0678C12.5846 13.5931 12.8152 12.9484 12.8152 12.2765V11.3021Z"
                  fill="#4067F9"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M14.4999 9.54775V9.93751C14.4999 10.2992 14.3758 10.6461 14.1539 10.9018C13.9327 11.1582 13.6326 11.3017 13.3197 11.3017H1.18023C0.867297 11.3017 0.567183 11.1582 0.345975 10.9018C0.124092 10.6461 0 10.2992 0 9.93751V9.54775C0 9.18605 0.124092 8.83917 0.345975 8.58349C0.567183 8.32702 0.867297 8.18359 1.18023 8.18359H13.3197C13.6326 8.18359 13.9327 8.32702 14.1539 8.58349C14.3758 8.83917 14.4999 9.18605 14.4999 9.54775ZM13.4883 9.54775C13.4883 9.4963 13.4708 9.44641 13.4391 9.40978C13.4074 9.37314 13.3642 9.35287 13.3197 9.35287H1.18023C1.13571 9.35287 1.09255 9.37314 1.06085 9.40978C1.02916 9.44641 1.01162 9.4963 1.01162 9.54775V9.93751C1.01162 9.98896 1.02916 10.0388 1.06085 10.0755C1.09255 10.1121 1.13571 10.1324 1.18023 10.1324H13.3197C13.3642 10.1324 13.4074 10.1121 13.4391 10.0755C13.4708 10.0388 13.4883 9.98896 13.4883 9.93751V9.54775Z"
                  fill="#4067F9"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M11.613 15.8101C11.4154 15.5825 11.4154 15.2114 11.613 14.9838C11.81 14.7554 12.131 14.7554 12.3279 14.9838L13.0023 15.7633C13.1999 15.9909 13.1999 16.362 13.0023 16.5896C12.8054 16.818 12.4844 16.818 12.2875 16.5896L11.613 15.8101Z"
                  fill="#4067F9"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M2.17027 14.9838C2.3672 14.7554 2.68822 14.7554 2.88515 14.9838C3.08276 15.2114 3.08276 15.5825 2.88515 15.8101L2.21074 16.5896C2.01381 16.818 1.69279 16.818 1.49586 16.5896C1.29826 16.362 1.29826 15.9909 1.49586 15.7633L2.17027 14.9838Z"
                  fill="#4067F9"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M4.04795 0H4.0493C5.35227 0 6.4084 1.22073 6.4084 2.72676V2.92086C6.4084 3.46964 5.90259 3.50783 5.90259 3.50783C5.76029 3.50783 5.63148 3.44002 5.53976 3.3301C5.46153 3.24592 5.40083 3.12119 5.39678 2.93878C5.39678 2.93333 5.39678 2.92086 5.39678 2.92086V2.72676C5.39678 1.86695 4.79318 1.16928 4.0493 1.16928H4.04795C3.30272 1.16928 2.69912 1.86695 2.69912 2.72831V8.76958C2.69912 9.0923 2.47252 9.35422 2.19331 9.35422C1.9141 9.35422 1.6875 9.0923 1.6875 8.76958V2.72831C1.6875 1.22151 2.74431 0 4.04795 0Z"
                  fill="#4067F9"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M5.90152 2.33984C6.39316 2.33984 6.86525 2.5659 7.21325 2.96736C7.56058 3.36959 7.75616 3.91525 7.75616 4.48352V5.26304C7.75616 5.58576 7.52955 5.84768 7.25035 5.84768H4.55269C4.27348 5.84768 4.04688 5.58576 4.04688 5.26304V4.48352C4.04688 3.91525 4.24246 3.36959 4.58978 2.96736C4.93778 2.5659 5.40987 2.33984 5.90152 2.33984ZM5.90152 3.50912C5.67761 3.50912 5.46382 3.61202 5.30533 3.79443C5.14752 3.97761 5.0585 4.22472 5.0585 4.48352V4.6784H6.74453V4.48352C6.74453 4.22472 6.65551 3.97761 6.4977 3.79443C6.33921 3.61202 6.12542 3.50912 5.90152 3.50912Z"
                  fill="#4067F9"
                />
              </svg>
              <p className="item-quentity">{bathCount} BATHS</p>
            </div>
          )}
        </div>
        <p className="price">{price}</p>
      </div>
    </>
  );
};

export default Card;
