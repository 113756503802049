import { ReactSVG } from 'react-svg';
import propertyDummyImage from '../../../../assests/images/6-thumb.png';
import style from './style.module.scss';
import PrevArrow from '../../../../assests/images/PrevChevron.svg';
import NextArrow from '../../../../assests/images/NextChevron.svg';
import Slider from 'react-slick';
import { Image } from '../../../../designSystem/Card/Image';
import { Card } from '../../../../designSystem/Card';
import { format } from 'date-fns';
import { MdOutlineBed, MdOutlineBathtub, MdAir, MdBalcony, MdOutlineWaterDrop, MdOutlineRoofing, MdOutlinePets } from "react-icons/md";
import { TfiRulerAlt2 } from "react-icons/tfi";
import { BiSolidWasher } from "react-icons/bi";
import { VscFlame } from "react-icons/vsc";
import { IoMdClose } from "react-icons/io";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

type Props = {
  property: {
    shortAddress: string;
    address: string;
    city: string;
    unit: string;
    state: string;
    zipCode: string;
    images: string[];
    bathrooms: number;
    bedrooms: number;
    sqft: number;
    amenitiesAndFeatures: {
      [key: string]: string[];
    };
    pets: {
      allowed?: boolean;
      cats?: {
        weight?: string | number;
        maxAllowed?: number;
        allowed?: boolean;
      };
      dogs?: {
        weight?: string | number;
        maxAllowed?: number;
        allowed?: boolean;
      };
      deposit?: number;
      monthlyPetRent?: number;
      notes?: string;
    };
    utilities: {
      [key: string]: 'tenant' | 'included';
    };
    parking: {
      spacesNumber: number;
      type: string;
    };
    dateAvailableTs: EpochTimeStamp;
    leaseTermOptions: string[];
    rentalAplicationForm: {
      enable?: boolean;
      url: string;
      fee: number;
    };
  },
  onClose: ()=> {};
};

const camelCaseToWords = (camelCase: string): string => {
  if (!camelCase) return '';
  const words: string[] = [];
  let currentWord = '';
  for (let i = 0; i < camelCase.length; i++) {
    if (camelCase[i] === camelCase[i].toUpperCase()) {
      words.push(currentWord);
      currentWord = camelCase[i].toLowerCase();
      continue;
    }
    currentWord += camelCase[i];
  }
  words.push(currentWord);
  return words.join(' ');
};

export const PropertyDetail = ({ property, onClose }: Props) => {
  const amenitiesEntries =
    typeof property.amenitiesAndFeatures === 'object'
      ? Object.entries(property?.amenitiesAndFeatures)
      : [];
  const includedUtilities =
    typeof property.utilities === 'object'
      ? Object.entries(property?.utilities).reduce<string[]>(
          (acc, [key, value]) => {
            if (value === 'included') {
              acc.push(key);
            }
            return acc;
          },
          []
        )
      : [];
  const setSVGAttributes = (svg: SVGElement) => {
    svg.setAttribute('viewbox', '0 0 12 24');
    svg.setAttribute('width', '12');
    svg.setAttribute('height', '24');
    const path = svg.querySelector('path');

    if (path) {
      path.setAttribute('fill', '#979797');
      path.setAttribute('stroke', '#979797');
    }
  };
  const config = {
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: true,
    infinite: true,
    adaptiveHeight: true,
    variableHeight: false,
    
    // prevArrow: (
    //   <ReactSVG
    //     beforeInjection={setSVGAttributes}
    //     src={PrevArrow}
    //   />
    // ),
    // nextArrow: (
    //   <ReactSVG
    //     beforeInjection={setSVGAttributes}
    //     src={NextArrow}
    //   />
    // ),
  };
  const availablePetsString = (pets: Props['property']['pets']) => {
    if (!pets?.allowed) {
      return 'No pets allowed';
    } else {
      if (pets.cats?.allowed && pets.dogs?.allowed) {
        return 'All pets allowed';
      } else if (pets.cats?.allowed) {
        return 'Only cats allowed';
      } else {
        return 'Only dogs allowed';
      }
    }
  };
  
  return (
    <article className={style.container}>
      <div className={style.closeContainer}>

       <div className={style.close}><button style={{border: 'none', background:'none'}} onClick={()=> onClose()}><IoMdClose size={20}/></button></div>
      </div>
      <div className={style.swiperContainer}>
        <Swiper
        modules={[Pagination, Navigation]}
        slidesPerView={1}
        centerInsufficientSlides={true}
        className="w-100 h-100 rounded-4"
        navigation={false}
        pagination={{
          clickable: true, 
          // currentClass:'swiper-pagination-current', 
          // bulletActiveClass:'swiper-pagination-bullet-active',
          // bulletElement: 'span',
          // clickableClass: 'swiper-pagination-clickable',
          // type: "bullets"
        }}
        breakpoints={{
          640: {
            slidesPerView: 1,
          },
          768: {
            slidesPerView: 1,
            
          },
        }}
        >
        {/* {property?.images.length ? ( */}
        
          {property?.images.length ? property?.images.map((src) => (
          <SwiperSlide>
            <Card.Image className="w-100 h-100 rounded-5" src={src}
          alt='property'/>
          </SwiperSlide> 
          )) :

          (
            <SwiperSlide>
            <Card.Image className="w-100 h-100 rounded-5" src={propertyDummyImage}
          alt='property'/>
          </SwiperSlide> 
          )
        }
        
      {/* // ) : (
      //   <Image
      //     src={propertyDummyImage}
      //     alt=''
      //   />
      )} */}
          
        </Swiper>
      </div>
        
          <div className={style.type}>
              APARTMENT
          </div>
       
        <h2 className={style.shortAddress}>{`${property?.shortAddress}${property?.unit ? ` Unit ${property?.unit}` : ''}`}</h2>
        <h3 className={style.address}>{property?.city}, {property?.state} {property?.zipCode}</h3>
      
      {/* {property?.images.length ? (
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: 'minmax(0, 1fr)',
            paddingInline: '1rem',
          }}
        > */}
        <div className={style.details}>
          <MdOutlineBed style={{marginRight: '5px'}} size={20}/> {property?.bedrooms} {property?.bedrooms > 1 ? 'Beds' : 'Bed'} <p className={style.line}></p>  
          <MdOutlineBathtub style={{marginRight: '5px'}} size={18}/> {property?.bathrooms} {property?.bathrooms > 1 ? 'Baths' : 'Bath'} <p className={style.line}></p>  
          <TfiRulerAlt2 size={18}/> {property?.sqft} SQFT
        </div>

        
        
        
            {/* <Slider {...config}>
              {property?.images.map((src) => (
                <Image
                  key={src}
                  src={src}
                  alt=''
                />
              ))}
            </Slider> */}
          {/* </div>
        </div>
      ) : (
        <Image
          src={propertyDummyImage}
          alt=''
        />
      )} */}
        {amenitiesEntries.some((amenity) => !!amenity[1].length) ? (
          <div>
            <h3 className={style.h3}>Features & Amenities</h3>
            {amenitiesEntries.map(([key, values]) => {
              return (
                <div key={key}>
                  <h4 className={style.h4}>
                    {camelCaseToWords(key).toUpperCase()}
                  </h4>
                  <ul className={style.description}>
                    {values.map((value) => {
                      return <li key={value}>{value}</li>;
                    })}
                  </ul>
                </div>
              );
            })}
          </div>
        ) : null}

    <div className={style.leaseOptions}>
        <table className={style.table}>
          <thead>
            <tr className={style.rows}>
              <th className={style.leftTd}>
                LEASE OPTIONS
              </th>
            </tr>
          </thead>
                <tbody>
                    {/* <tr className={style.rows}>
                        <td className={style.leftTd}><CiLocationOn size={18}/> Location</td>
                        <td className={style.rightTd}>{formData.location}</td>
                    </tr> */}
                    <tr className={style.rows}>
                        <td className={style.leftTd}>Lease Terms:</td>
                        
                    </tr>
                    <tr className={style.rows}>
                        <td className={style.leftTd}>Available: </td>
                    </tr>
                    <tr className={style.bottomRow}>
                        <td className={style.leftTd}>Application Fee: </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div className={style.floorPlan}>
          <table className={style.table}>
          <thead>
            <tr className={style.rows}>
              <th className={style.tableHeader}>
                FLOOR PLAN HIGHLIGHTS
              </th>
            </tr>
          </thead>
                <tbody>
                    {/* <tr className={style.rows}>
                        <td className={style.leftTd}><CiLocationOn size={18}/> Location</td>
                        <td className={style.rightTd}>{formData.location}</td>
                    </tr> */}
                    <tr className={style.rows}>
                        <td className={style.leftTd}><MdAir size={18}/> Air Conditioning</td>
                        
                    </tr>
                    <tr className={style.rows}>
                        <td className={style.leftTd}><MdBalcony size={18}/> Balcony/Patio</td>
                    </tr>
                    <tr className={style.rows}>
                        <td className={style.leftTd}><BiSolidWasher size={18}/> Washer/Dryer (In Unit) </td>
                    </tr>
                </tbody>
            </table>
          </div>
          <div className={style.buildingFeatures}>
          <table className={style.table}>
          <thead className={style.tableHeader}>
            <tr className={style.rows}>
              <th className={style.tableHeader}>
                BUILDING FEATURES
              </th>
            </tr>
          </thead>
                <tbody>
                    {/* <tr className={style.rows}>
                        <td className={style.leftTd}><CiLocationOn size={18}/> Location</td>
                        <td className={style.rightTd}>{formData.location}</td>
                    </tr> */}
                    <tr className={style.rows}>
                        <td className={style.leftTd}>Contact Us</td> 
                    </tr>
                </tbody>
            </table>
            {/* <h3 className={style.h3}>Parking</h3>
            <p className={`${style.description} ${style.parking}`}>
              {`${camelCaseToWords(property?.parking?.type)} ${
                property?.parking?.spacesNumber
                  ? `, ${property?.parking?.spacesNumber} spaces`
                  : 'Contact Us'
              }`}
            </p> */}
          </div>
          
          
          <div className={style.utilities}>
          <table className={style.table}>
          <thead className={style.tableHeader}>
            <tr className={style.rows}>
              <th className={style.tableHeader}>
                UTILITIES INCLUDED
              </th>
            </tr>
          </thead>
                <tbody>
                    {/* <tr className={style.rows}>
                        <td className={style.leftTd}><CiLocationOn size={18}/> Location</td>
                        <td className={style.rightTd}>{formData.location}</td>
                    </tr> */}
                    <tr className={style.rows}>
                        <td className={style.leftTd}><MdOutlineWaterDrop size={18}/> Water</td>
                        
                    </tr>
                    <tr className={style.rows}>
                        <td className={style.leftTd}><VscFlame size={18}/> Gas</td>
                    </tr>
                    
                </tbody>
            </table>
            {/* <h3 className={style.h3}>Utilities Included</h3>
            <p className={style.description}>
              {includedUtilities.length
                ? includedUtilities.join(', ')
                : 'No included utilities'}
            </p> */}
          </div>
        
        
        <div className={style.petPolicies}>
          <table className={style.table}>
          <thead className={style.tableHeader}>
            <tr className={style.rows}>
              <th className={style.tableHeader}>
                PET POLICIES
              </th>
            </tr>
          </thead>
                <tbody>
                    {/* <tr className={style.rows}>
                        <td className={style.leftTd}><CiLocationOn size={18}/> Location</td>
                        <td className={style.rightTd}>{formData.location}</td>
                    </tr> */}
                    <tr className={style.rows}>
                        <td className={style.leftTd}>{availablePetsString(property?.pets)}</td> 
                    </tr>
                </tbody>
            </table>
          </div>

           
          
      {property?.leaseTermOptions?.length ||
      property?.dateAvailableTs ||
      property?.rentalAplicationForm?.enable ? (
        <section className={style.leaseOptions}>
          <h3 className={style.h3}>Lease Options</h3>

          <p className={style.desciption}>
            Lease Terms{'(s)'}: {property?.leaseTermOptions[0]}
          </p>
          <p className={style.desciption}>
            Available:{' '}
            {format(new Date(property?.dateAvailableTs), 'MM/dd/yyyy')}
          </p>
          {property?.rentalAplicationForm?.enable ? (
            <p className={style.desciption}>
              Application Fee: ${property?.rentalAplicationForm.fee}
            </p>
          ) : null}
        </section>
      ) : null}
    </article>
  );
};
