import React, { useEffect } from "react";
import moment from "moment";
import styled from "styled-components";
import { Auth } from "aws-amplify";
import axios from "axios";
import copy from "copy-to-clipboard";
import ReactGA from "react-ga4";
import Status from "../../../components/Status";
import CopyLink from "./CopyLink";
import ButtonUnderlineText from "../../../components/ButtonUnderlineText";
import { useGeneral } from "../../../context/GeneralContext";
import { useProperty } from "../../../context/PropertyContext";
import { propertyStatus /*propertyType*/ } from "../../../static/Data";
// import { encodeURL } from "../../../utils/utils";
import { useState } from "react";
import DescriptionModal from "./DescriptionModal";
import removeUndefinedWord from "../../../helper/updateString";
import { types } from "../../../helper/properties";
import { useMutation } from "@tanstack/react-query";
import { patchProperty } from "../../../api/properties";
import FormModal from "../../../components/Modals/FormModal";
import { Link } from "react-router-dom";
import { FiUpload, FiCopy } from "react-icons/fi";
import { ImPencil } from "react-icons/im";
import { IoIosCheckmarkCircle } from "react-icons/io";
import "../Properties.scss"
import { AppButton } from "../../../designSystem/AppButton";
import { Share } from "react-feather";
import { MdOutlineBathtub, MdOutlineBed, MdOutlineEdit } from "react-icons/md";
import { TfiRulerAlt2 } from "react-icons/tfi";


const DescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 3px;
`;

const Description = styled.span`
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  max-height: 87px;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const TopLevelDetails = () => {
  const [descriptionStatus, setDescriptionStatus] = useState(false);
  const [isCopied, setIsCopied] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [isVerified, setIsVerified] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [emailError, setEmailError] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const { setAlertModal } = useGeneral();
  const { property, setProperty } = useProperty();
  const [formData, setFormData] = useState({
    location: "",
    fullName: "",
    email: "",
    phoneNumber: "",
  });
  const [pType, setPType] = useState(property.classification);
  const [screenWidth, setScreenWidth] = useState(1800);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);



  const reportShareLink = (category, label) => {
    ReactGA.event({
      action: "share_link",
      category: category,
      label: label,
    });
  };

const removeCopyBanner = () => {
    setIsCopied(false)
}
const shareProperty = () => {
    if (!property._id) return
    Auth.currentAuthenticatedUser().then((userInfo) => {
      const customId = userInfo.attributes["custom:_id"];
      const link = `${process.env.REACT_APP_URL}/book/property/${property._id}/${customId}`;
      copy(link);
      reportShareLink("property/list", property.address);
      setIsCopied(true)
      setTimeout(removeCopyBanner, 1500)
    
    });
  };

  const changePropertyStatus = (selectedStatus) => {
    setProperty({ ...property, status: selectedStatus });

    Auth.currentSession().then((token) => {
      axios
        .put(
          `${process.env.REACT_APP_NODE_API_URL}/api/property/${property._id}`,
          { status: selectedStatus },
          {
            headers: {
              Authorization: `Bearer ${token.getIdToken().getJwtToken()}`,
            },
          }
        )
        .then((response) => {
          if (response.status === 200) {
            setAlertModal({
              show: true,
              title: "Updated",
              content: "Property status updated",
            });
          } else {
            setAlertModal({
              show: true,
              title: "Error",
              content: "Error updating status",
            });
          }
        })
        .catch((error) => {
          console.log(error);
          setAlertModal({
            show: true,
            title: "Error",
            content: "Error updating status",
          });
        });
    });
  };

  const { mutateAsync: updateType, isPending: updatingType } = useMutation({
    mutationFn: (updatedFields) => patchProperty(property._id, updatedFields),
  });

  const handleChangeType = async (e) => {
    const { value: newType } = e.target;
    const prevType = pType;
    setPType(newType);
    try {
      await updateType({ classification: newType });
    } catch (error) {
      console.log("ERROR UPDATING TYPE: ", error);
      setPType(prevType);
    }
  };

  const handleFormModal = () => {
    setShowModal(true);
  };

  const handleModalClose = () => {
    setShowModal(false);
    setIsVerified(false);
    setIsSubmitted(false);
    setFormData({ fullName: "", email: "", phoneNumber: "" });
    setPhoneError("");
    setEmailError("");
  };

  return (
    <>
      <div className="d-flex flex-column px-2 gap-3">
        {/* <div className="d-flex flex-column align-items-start gap-1 align-self-stretch"> */}
          {/* <div className="d-flex flex-column align-items-start gap-2 align-self-stretch"> */}
              {screenWidth < 755 ? (
                <>
                <div className="d-flex justify-content-between align-items-start align-self-stretch">
                <Status
                  status={property.status}
                  values={[]}
                  onChange={(e) => changePropertyStatus(e[0].status)}
                  placeholder={property.status}
                  options={propertyStatus}
                  size="sm"
                  className="status-td rounded-1"
                />
                <div className="d-flex flex-row gap-3 align-items-center justify-content-center">
                  <AppButton
                    as="primary"
                    className="icon-button rounded-5 py-3 my-0"
                    onClick={shareProperty}
                  >
                    <FiCopy />
                  </AppButton>
                  <button
                    className="rounded-5 text-decoration-none border-none text-white bg-delet fw-bold px-2 py-2  d-flex align-items-center gap-1"
                    style={{ border: "none" }}
                  >
                    <Share
                      className="more-icon"
                      variantType="white"
                      height={"15"}
                      width={"15"}
                      onClick={handleFormModal}
                    />
                  </button>
                  <Link
                    to={"/property/edit/" + property._id}
                    className="rounded-5 text-decoration-none text-white bg-delet fw-bold px-2 py-2  d-flex align-items-center"
                  >
                    <MdOutlineEdit />
                  </Link>
                </div>
              </div>
              </>
              ) : (
                <>
                  <div className="d-flex flex-column gap-3">
        <div className="d-flex flex-column align-items-start gap-1 align-self-stretch border-bottom">
          <div className="d-flex align-items-start justify-content-between gap-2 align-self-stretch"> 
            <div >
              <h3 className="main-address-head">
                {removeUndefinedWord(property.shortAddress)}
                {property.unit && `, Unit ${property.unit}`}
              </h3>
              <h3 className="main-address-head">
                {property.city && property.city}
                {property.state && `, ${property.state}`}
                {property.zipCode && `, ${property.zipCode}`}
              </h3>
            </div>
            <div className="d-flex justify-content-between align-items-start align-self-stretch gap-3">
              <Status
                status={property?.status}
                values={[]}
                onChange={(e) => changePropertyStatus(e[0].status)}
                placeholder={property?.status}
                options={propertyStatus}
                size="sm"
                className="status-td rounded-1"
              />
              <Link
                to={"/property/edit/" + property._id}
                className="rounded-circle text-decoration-none text-white bg-delet d-flex justify-content-center align-items-center mt-1"
                style={{height: '27px', width: '27px'}}
              >
                <ImPencil size={12}/> 
              </Link>
            </div>
          </div>

          <div className="d-flex flex-wrap align-items-start gap-3 text-capitalize mb-3">
            <AppButton
              as="button"
              onClick={handleFormModal}
              className="icon-button"
            >
              <FiUpload />
            </AppButton>
            <AppButton
              as="primary"
              className="icon-button"
              onClick={shareProperty}
            >
              <FiCopy />
            </AppButton>

         
          </div>
        </div>

        <div className="d-flex flex-column align-items-start gap-2 ">
          <div className="d-flex flex-wrap align-items-start justify-content-between align-self-stretch border-bottom pb-3 px-3">
            <div className="d-flex flex-column gap-2">
              <span className="tags-prop">Rent</span>
              <span className="tags-body">
                {property.price ? `$${property.price}` : "N/A"}
              </span>
            </div>
            <div className="d-flex flex-column gap-2">
              <span className="tags-prop">Deposit</span>
              <span className="tags-body">
                ${property.deposit ? property.deposit : "N/A"}
              </span>
            </div>
            <div className="d-flex flex-column gap-2">
              <span className="tags-prop">Beds</span>
              <span className="tags-body">
                {typeof property.bedrooms === "number"
                  ? `${property.bedrooms}`
                  : "N/A"}
              </span>
            </div>
            <div className="d-flex flex-column gap-2">
              <span className="tags-prop">Baths</span>
              <span className="tags-body">
                {property.bathrooms ? `${property.bathrooms}` : "N/A"}
              </span>
            </div>
            <div className="d-flex flex-column gap-2">
              <span className="tags-prop">SQFT</span>
              <span className="tags-body">
                {property.sqft ? `${property.sqft}` : "N/A"}
              </span>
            </div>
            <div className="d-flex flex-column gap-2">
              <span className="tags-prop">Available</span>
              <span className="tags-body">
                {property.dateAvailableTs
                  ? moment(property.dateAvailableTs).format("MM/DD/YY")
                  : "-"}
              </span>
            </div>
            
          </div>
          <DescriptionContainer>
              {property.propertyDescription &&
              property.propertyDescription.length > 0 ? (
                <Description className="desc-body">
                  {property.propertyDescription}
                </Description>
              ) : null}

              {property.propertyDescription &&
              property.propertyDescription.length > 0 ? (
                <div>
                  <ButtonUnderlineText
                    variantType="primary"
                    onClick={() => setDescriptionStatus(true)}
                  >
                    See full description
                  </ButtonUnderlineText>
                </div>
              ) : (<>
              </>)}
            </DescriptionContainer>
        </div>
      </div>
    
      </>
          )}
          {/* {screenWidth < 755 ? (
            <> </>
          ) : (
            <div className="d-flex flex-wrap align-items-start gap-3 text-capitalize mb-3">
            <AppButton
              as="button"
              onClick={handleFormModal}
              className="icon-button"
            >
              <FiUpload />
            </AppButton>
            <AppButton
              as="primary"
              className="icon-button"
              onClick={shareProperty}
            >
              <FiCopy />
            </AppButton>

         
          </div>
          )} */}
            {/* </div> */}

        {screenWidth < 755 ? (
          <>
          <div className="d-flex flex-column align-items-start gap-2 " style={{width:'100%'}}>
            <div className="d-flex flex-row mb-4 mt-4  align-items-start   gap-4">
              {/* <div className="d-flex flex-column gap-2">
                <span className="tags">Rent</span>
                <span className="body-xs-b">
                  {property.price ? `$${property.price}` : "N/A"}
                </span>
              </div> */}
              <div
                className="d-flex p-1 px-3 flex-row gap-2 rounded-3"
                style={{
                  border: "1px solid #4C4D52",
                  
                }}
              >
                <span className="tags-mobile" style={{color:'#4C4D52'}}>
                  {property.deposit ? property.deposit : "N/A"}
                </span>
                <span className="tags-mobile" style={{color:'#4C4D52'}}>Deposit</span>
              </div>
              <div
                className="d-flex p-1 px-3 flex-row gap-2 rounded-3"
                style={{
                  border: "1px solid #4C4D52",
                  color: "#4C4D52",
                }}
              >
                <span className="tags-mobile" style={{color:'#4C4D52'}} >Available</span>
                <span className="tags-mobile" style={{color:'#4C4D52'}}>
                  {property.dateAvailableTs
                    ? moment(property.dateAvailableTs).format("MM/DD/YY")
                    : "-"}
                </span>
              </div>
            </div>
            <div className="d-flex align-items-center justify-content-between  pb-3 " style={{width:'100%'}}>
              {/* <div className="d-flex flex-column gap-2">
                <span className="tags">Rent</span>
                <span className="body-xs-b">
                  {property.price ? `$${property.price}` : "N/A"}
                </span>
              </div>
              <div className="d-flex flex-column gap-2">
                <span className="tags-prop">Deposit</span>
                <span className="tags-body">
                <span className="tags-prop">Deposit</span>
                <span className="tags-body">
                  ${property.deposit ? property.deposit : "N/A"}
                </span>
              </div> */}
              <div
                className="d-flex flex-row gap-2 "
                style={{
                  borderRight: "1px solid rgba(181,177,187,1)",
                  paddingRight: "20px",
                }}
              >
                <MdOutlineBed />
                <span className="tags-mobile">
                  {typeof property.bedrooms === "number"
                    ? `${property.bedrooms}`
                    : "N/A"}
                </span>
                <span className="tags-mobile">BEDS</span>
              </div>
              <div
                className="d-flex flex-row gap-2 pr-20"
                style={{
                  borderRight: "1px solid rgba(181,177,187,1)",
                  paddingRight: "20px",
                }}
              >
                <MdOutlineBathtub />
                <span className="tags-mobile">
                  {property.bathrooms ? `${property.bathrooms}` : "N/A"}
                </span>
                <span className="tags-mobile">BATHS</span>
              </div>
              <div className="d-flex flex-row gap-2">
                <TfiRulerAlt2 />
                <span className="tags-mobile">
                  {property.sqft ? `${property.sqft}` : "N/A"}
                </span>
                <span className="tags-mobile">SQFT</span>
              </div>
              {/* <div className="d-flex flex-column gap-2">
                <span className="tags-prop">Available</span>
                <span className="tags-body">
                  {property.dateAvailableTs
                    ? moment(property.dateAvailableTs).format("MM/DD/YY")
                    : "-"}
                </span>
              </div> */}
            </div>

           
          </div>
          </>
        ) : (
          <></>
        )
      }
          {/* </div> */}
      <DescriptionModal
        show={descriptionStatus}
        description={property.propertyDescription}
        onHide={() => setDescriptionStatus(false)}
      />
      <FormModal
        show={showModal}
        onHide={() => handleModalClose()}
        isVerified={isVerified}
        isSubmitted={isSubmitted}
        setIsVerified={setIsVerified}
        setIsSubmitted={setIsSubmitted}
        formData={formData}
        setFormData={setFormData}
        emailError={emailError}
        setEmailError={setEmailError}
        phoneError={phoneError}
        setPhoneError={setPhoneError}
      />
        {/* </div> */}
        {isCopied ? 
                <div className='copied-prop'>
                    <p> <IoIosCheckmarkCircle style={{color: '#00BA08'}} size={14}/> Property Link Copied!</p> 
                </div>
                : <></>}
      </div>
    </>
  );
};

export default TopLevelDetails;
    