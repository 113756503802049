import React from "react";
import { IoIosInformationCircle } from "react-icons/io";
import { AppButton } from "../designSystem/AppButton";
import { useNavigate } from 'react-router-dom';

const Alert = ({ show = false, title = '', content = '', setShow, navigateTo }) => {
  const navigate = useNavigate()
  return (
    show && <div className="modal-outside">
      <div className="modal-inside">
        <IoIosInformationCircle size={50} color='#5271ff'/>
        <p className="modal-title">{title}</p>
        <p className="expired-content">
          {content}
        </p>
        {/* <AppButton onClick={toggleModal}>Close</AppButton> */}
        <div className="d-flex gap-3 w-100">
          <AppButton
            as="button"
            className="remove-btn"
            onClick={navigateTo ? () => navigate(navigateTo) : setShow}
          >
            {navigateTo ? 'Okay' : 'Close'}
          </AppButton>
        </div>
      </div>
    </div>
  );
};

export default Alert

