import Sidebar from "../../components/SideBar";
import Orders from "./tabs/Orders";
import { Link, useNavigate, useParams } from "react-router-dom";
import KitsTab from "./tabs/KitsTab";
import { CloseButton, rem, TextInput } from "@mantine/core";
import DatePicker from "react-datepicker";
import { IoMdSearch } from "react-icons/io";
import { useState } from "react";
import useViewport from "../../hooks/useViewport";
import { RiEqualizerLine } from "react-icons/ri";
import { setHours, setMinutes, setSeconds } from "date-fns";

const initState = {
  filterBy: {
    search: "",
    dateRange: {
      from: null,
      to: null,
    },
  },
};

const Kits = () => {
  const [filterBy, setFilterBy] = useState(initState.filterBy);
  const [isOpen, setIsOpen] = useState(false);

  const { tab } = useParams();
  const navigate = useNavigate();
  const { width: screenWidth } = useViewport();

  const handleChange = (dates) => {
    const [start, end] = dates;
    const endOfDay = end
      ? setHours(setMinutes(setSeconds(end, 59), 59), 23)
      : end;
    setFilterBy((prev) => ({
      ...prev,
      dateRange: {
        from: start,
        to: endOfDay,
      },
    }));
    if (end) setIsOpen(false);
  };

  return (
    <Sidebar>
      <section className="min-h-100vh d-flex flex-column gap-2">
        <header
          className={`d-flex flex-column justify-content-center border-bottom gap-3 ${
            screenWidth > 755 ? "px-5" : "px-4"
          }`}
          style={{ backgroundColor: "#EAEAEB", height: "126px" }}
        >
          <div className="d-flex justify-content-between align-items-center">
            <h4 className="fs-3 fw-bold pt-0">
              {tab === "orders" ? "Orders" : "Kits"}
            </h4>

            <Link
              className="bg-delet text-white px-4 py-1 fw-bold border-0 rounded-3 text-decoration-none text-nowrap d-none"
              to="/order"
            >
              Order New Kit
            </Link>
          </div>

          {screenWidth < 755 && tab !== "orders" && (
            <div className="position-relative">
              <TextInput
                placeholder="Search..."
                style={{ borderRadius: "20px" }}
                name="search"
                leftSection={
                  <IoMdSearch
                    style={{ width: rem(16), height: rem(16) }}
                    stroke={1.5}
                  />
                }
                value={filterBy.search}
                onChange={(e) =>
                  setFilterBy((prev) => ({ ...prev, search: e.target.value }))
                }
                rightSection={
                  <CloseButton
                    icon={<RiEqualizerLine />}
                    onClick={() => setIsOpen((prev) => !prev)}
                  />
                }
              />

              {isOpen && (
                <div className="position-absolute z-3" style={{ right: 0 }}>
                  <DatePicker
                    onChange={handleChange}
                    startDate={filterBy.dateRange.from}
                    endDate={filterBy.dateRange.to}
                    selectsRange
                    inline
                    maxDate={new Date()}
                    className="z-3 shadow-none"
                  />
                </div>
              )}
            </div>
          )}
        </header>

        <ul className="nav nav-underline ms-3">
          <li className="nav-item">
            <button
              style={{
                color: !tab || tab === "kits" ? "#5271ff" : "grey",
              }}
              className={`nav-link ${
                (!tab || tab === "kits") && "active text-delet-blue"
              }`}
              type="button"
              onClick={() => navigate("/kits/kits")}
            >
              KITS
            </button>
          </li>

          <li className="nav-item">
            <button
              style={{
                color: tab === "orders" ? "#5271ff" : "grey",
              }}
              className={`nav-link ${
                tab === "orders" && "active text-delet-blue"
              }`}
              onClick={() => navigate("/kits/orders")}
            >
              ORDERS
            </button>
          </li>
        </ul>

        {tab === "orders" ? (
          <Orders />
        ) : (
          <KitsTab
            filterBy={filterBy}
            setFilterBy={setFilterBy}
            handleChange={handleChange}
          />
        )}
      </section>
    </Sidebar>
  );
};
export default Kits;
