import { Auth } from "aws-amplify";
import axios from "axios";

export function getKitHistory(id, options = {}) {
  let { startIndex = 0, limit = 10, all = false } = options;

  if (!Number.isInteger(startIndex)) {
    startIndex = 0;
  }

  if (!Number.isInteger(limit)) {
    limit = 10;
  }

  return Auth.currentSession().then((token) => {
    return axios
      .get(`${process.env.REACT_APP_NODE_API_URL}/api/kits/history/${id}?startIndex=${startIndex}&limit=${limit}&all=${all}`, {
        headers: {
          Authorization: `Bearer ${token.getIdToken().getJwtToken()}`,
        },
      })
      .then((response) => {
        if (response.status === 200 && response?.data) {
          return response?.data;
        }
      })
      .catch((error) => {
        console.log(error);
      });
  });
}

export function getAdminKitHistory(id, options = {}) {
  let { startIndex = 0, limit = 10, all = false } = options;

  if (!Number.isInteger(startIndex)) {
    startIndex = 0;
  }

  if (!Number.isInteger(limit)) {
    limit = 10;
  }

  return Auth.currentSession().then((token) => {
    return axios
      .get(`${process.env.REACT_APP_NODE_API_URL}/api/kits/admin/history/${id}?startIndex=${startIndex}&limit=${limit}&all=${all}`, {
        headers: {
          Authorization: `Bearer ${token.getIdToken().getJwtToken()}`,
        },
      })
      .then((response) => {
        if (response.status === 200 && response?.data) {
          return response?.data;
        }
      })
      .catch((error) => {
        console.log(error);
      });
  });
}